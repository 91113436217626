import { useRouter } from "next/router";
import React, { use, useEffect, useRef, useState } from "react";
import { Select, Input } from "xt-design";
import styles from "./index.module.scss";
import styled from "@emotion/styled";
import { useClickAway } from "ahooks";
import { get } from "lodash-es";
import { EmptyOutlinedIcon } from "components/SvgComponents";
import { IfElse } from "components";
import { useTranslation } from "hooks";
import { sensor } from "../..";
import { useCommonState } from "context";
import { DialingCode2LabelName, trackError } from "utils";
import { useQuery } from "@tanstack/react-query";
import { getDialingCode } from "apis/user";
import { SitesEnum, Site2AreaCodeOptionsMap } from "constants/regions";

const { Option } = Select;

interface IProps {
  onChange?: (value: { area?: string; phone?: string }) => void;
  value?: string;
}

// 因为 antd 4.22+ 自定义 dropdownRender 的 popup 会有问题，所以这里使用 class 去判断点击事件
const CLASS_WHITE_LIST = ["atom-select-selection-item", "atom-select-selector"];
const PhoneSelect: React.FC<IProps> = (props) => {
  const { onChange = () => {} } = props;
  const { isGlobal, isLanding, isHK } = useCommonState();
  const { query } = useRouter();
  const { site } = query as { site: SitesEnum };
  const [keyword, setKeyword] = React.useState<string>("");
  const [code, setCode] = React.useState<string>("");
  const ref = useRef<HTMLDivElement>(null);
  const ref1 = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  // data不可能为null
  const { data } = useQuery(["dialing-code"], () => getDialingCode(), {
    onSettled: (res, error) => {
      if (error) trackError(error);
      else if (!(Array.isArray(res) && res.length > 0)) {
        trackError(new Error("phone is error, please check the backend log and arms log"));
      }
    },
    enabled: isGlobal || isHK || isLanding,
  });
  const phoneAreaOptions = DialingCode2LabelName(data) || Site2AreaCodeOptionsMap[site];
  const PHONE_LIST = phoneAreaOptions.map((item) => ({
    ...item,
    value: "+" + item.value,
  }));
  const [_value, setValue] = useState<{
    area?: string;
    phone?: string;
  }>({
    area: "",
  });

  const [open, setOpen] = React.useState<boolean>(false);

  useClickAway(() => {
    setOpen(false);
  }, [ref, ref1]);

  const onRegionChange = (value: { region: string; code: string }) => {
    if (!value?.code) return;
    setCode(value.code);
    setValue({
      area: value.code,
      phone: "",
    });
    setOpen(false);
  };

  const onValuesChange = (e: ISafeAny) => {
    setValue({
      ..._value,
      phone: e.target.value,
    });
  };

  useEffect(() => {
    onChange({
      ..._value,
      area: _value.area?.replace("+", ""), // 去除区号中的 +
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value]);
  const filteredList = PHONE_LIST.filter((item) => item.region?.toLowerCase().includes(keyword.toLowerCase()) || item.value.includes(keyword));
  const selectBefore = (
    <div
      ref={ref1}
      onClick={(e) => {
        if (CLASS_WHITE_LIST.includes(get(e, "target.classList.value", ""))) {
          setOpen(!open);
        }
      }}
    >
      <Select
        {...props}
        onChange={() => sensor("Contact Us-Form-Phone Number-Code", "blur")}
        placeholder={t("please-select")}
        open={open}
        fieldNames={{ label: "code" }}
        dropdownClassName={styles["phone-select"]}
        value={code as ISafeAny}
        style={{ width: "100%" }}
        dropdownStyle={{ minWidth: "var(--xt-drop-down-width)" }}
        dropdownRender={() => {
          return (
            <Popover ref={ref}>
              <IfElse if={PHONE_LIST.length > 11}>
                <SearchInput
                  placeholder={t("support-contact-us--header-form-search-placeholder")}
                  allowClear
                  size="small"
                  onChange={(e) => setKeyword(e.target.value)}
                ></SearchInput>
              </IfElse>
              <Container>
                <IfElse if={!!filteredList?.length} else={<Empty />}>
                  {filteredList.map((item, index) => {
                    return (
                      <LI
                        key={index}
                        onClick={() => {
                          onRegionChange({
                            region: item.region || item.label,
                            code: item.value,
                          });
                        }}
                      >
                        <Highlight keyword={keyword} text={item.region || item.label}></Highlight>
                        <Highlight keyword={keyword} text={item.label}></Highlight>
                      </LI>
                    );
                  })}
                </IfElse>
              </Container>
            </Popover>
          );
        }}
      >
        {PHONE_LIST.map((item, index) => {
          return (
            <Option key={index} value={item.value}>
              {item.label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
  return (
    <Input
      size="large"
      onBlur={() => sensor("Contact Us-Form-Phone Number-Number", "blur")}
      placeholder="Please enter"
      onChange={onValuesChange}
      value={_value.phone}
      className={styles.input}
      addonBefore={selectBefore}
    />
  );
};

const Container = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding: 0 8px;
  margin: 0 -8px;
`;

// 因为这是一个 popup 为避免样式污染，使用 css-in-js 方式
const LI = styled.div`
  height: var(--xt-line-height-46);
  padding: var(--xt-padding-8) var(--xt-padding-16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--xt-border-radius-8);
  cursor: pointer;

  &:hover {
    background-color: var(--xt-bg-brand-light-color-1);
  }

  @media screen and (max-width: 900px) {
    height: var(--xt-line-height-60);
    padding: var(--xt-padding-12) var(--xt-padding-16);
    font-size: var(--xt-font-size-24);
    line-height: var(--xt-line-height-36);
  }
`;

const Popover = styled.div`
  padding: var(--xt-padding-8) var(--xt-padding-8) 0 var(--xt-padding-8);
  .empty {
    text-align: center;
    margin: var(--xt-margin-20) 0;
    font-size: var(--xt-font-size-80);
  }
  mark {
    padding: 0;
    background: transparent;
    color: var(--xt-text-brand-color);
  }

  @media screen and (max-width: 900px) {
    padding: var(--xt-padding-8);
  }
`;

const SearchInput = styled(Input)`
  margin-bottom: var(--xt-margin-8);
  height: var(--xt-line-height-40);
  padding: 0 var(--xt-padding-16);
  &:hover,
  &:focus {
    border-color: #413f57 !important;
  }
  &:placeholder {
    font-size: var(--xt-font-size-14);
    color: #696680;
  }

  @media screen and (max-width: 900px) {
    height: var(--xt-line-height-60);
    padding: var(--xt-padding-8) var(--xt-padding-16);
    input {
      font-size: var(--xt-font-size-24);
      line-height: var(--xt-line-height-36);
    }
  }
`;

interface IHighlightProps {
  text: string;
  keyword: string;
}

const Highlight = (props: IHighlightProps) => {
  const { text, keyword } = props;
  const highlightSearch = (text: string, keyword: string) => {
    if (!keyword.trim()) {
      return text;
    }
    try {
      const regex = new RegExp(`(${keyword})`, "gi");
      const parts = text.split(regex);

      return parts.map((part, index) => (regex.test(part) ? <mark key={index}>{part}</mark> : part));
    } catch {
      return text;
    }
  };
  return <div>{highlightSearch(text, keyword)}</div>;
};

const Empty = () => {
  return (
    <div className="empty">
      <EmptyOutlinedIcon></EmptyOutlinedIcon>
    </div>
  );
};

export default PhoneSelect;
