export const logo = "//cdn-global.xtransfer.com/static/images/icon_logo.png";

// 国际化Logo
export const INTERNATIONAL_LOGO = "//static.xtransfer.com/boss/static/InternationalizationLogo_ff603d2f86dd5492.svg";
export const INTERNATIONAL_LOGO_FOOTER_MOBILE = "//static.xtransfer.com/boss/static/footerh5_3cad08f046ea174f.png";
export const INTERNATIONAL_LOGO_FOOTER_PC = "//static.xtransfer.com/boss/static/footerpc_542e8b087f0442cc.png";

// 地区logo
export const global_png = "https://static.xtransfer.com/boss/static/global_b4a32135a5cbad1b.png";
export const cn_png = "https://static.xtransfer.com/boss/static/cn_36d6d6e68b15194f.png";
export const hk_png = "https://static.xtransfer.com/boss/static/hk_11390d448871ebfd.png";
export const jp_png = "https://static.xtransfer.com/boss/static/jp_b424ec6b755102b9.png";
export const us_png = "https://static.xtransfer.com/boss/static/us_f94934b32166a949.png";
export const uk_png = "https://static.xtransfer.com/boss/static/uk_3ed0f009a0fd40bd.png";
export const sg_png = "https://static.xtransfer.com/boss/static/Singapore_0c4b22b5add6b8e4.png";

// 语言选项
export const zhPng = "https://cdn-global.xtransfer.com/china_1900270e270dde35.png";
export const enPng = "https://cdn-global.xtransfer.com/us_4c360506f2b29d98.png";
export const hkPng = "https://cdn-global.xtransfer.com/hk_d1f597d4273c1304.png";
export const ukPng = "https://cdn-global.xtransfer.com/uk-flag_45dccb6a2d06ccd6.png";

export const expandIcon = "https://cdn-global.xtransfer.com/expand-Icon_4d1547403748e0c3.png";
export const VIDEO_PLAY_ICON = "https://cdn-global.xtransfer.com/play_6910de9f77208a21.png";

//帮助中心
export const NO_DATA = "https://static.xtransfer.com/boss/static/no_data_258dbeaa878904c9.png";
export const CUSTOMER_SERVICE = "https://static.xtransfer.com/boss/static/customer_service_f9e5ecaad284a914.png";
export const HELP_CENTER = "https://static.xtransfer.com/boss/static/help_center_89887431d2112588.png";

//帮助中心h5
export const RIGHT_ARROW_H5 = "https://static.xtransfer.com/boss/static/right-arrow-h5_88cce13e6d28f53d.png";
export const CLEAR_ICON = "https://static.xtransfer.com/boss/static/clear_211c09657f9bf2fd.png";
export const SEARCH_ICON = "https://static.xtransfer.com/boss/static/search_5a45dd0136c1437e.png";
export const CUSTOMER_SERVICE_ICON = "https://static.xtransfer.com/boss/static/service-icon_7c8230d6af889d87.png";
export const CLOSE_MODAL_ICON = "https://static.xtransfer.com/boss/static/close-modal-icon_5fe8d14f08a49121.png";
export const MAIL_ICON = "https://static.xtransfer.com/boss/static/mail-icon_e7e33209f4085a87.png";
export const PHONE_ICON = "https://static.xtransfer.com/boss/static/phone-icon_b1d387fdc7d26a56.png";
export const EMPTY_ICON = "https://static.xtransfer.com/boss/static/empty-content_40691ae0f22deb58.png";

//  客户转介绍邀请
export const REFERRAL_PC_1 = "https://static.xtransfer.com/boss/static/referral_pc_1_830070a4e43b559f.jpg";
export const REFERRAL_PC_2 = "https://static.xtransfer.com/boss/static/referral_pc_2_70cb90e40a534bdc.jpg";
export const REFERRAL_PC_3 = "https://static.xtransfer.com/boss/static/referral_2_657ee396a455943f.png";
export const REFERRAL_PC_4 = "https://static.xtransfer.com/boss/static/referral_3_d6ae0309ac4e5b52.png";
export const REFERRAL_PC_5 = "https://static.xtransfer.com/boss/static/referral_pc_5_c9b0654404cdc55d.png";
export const REFERRAL_PC_6 = "https://static.xtransfer.com/boss/static/referral_6_05c8b212d06eb77e.png";
export const REFERRAL_PC_7 = "https://static.xtransfer.com/boss/static/referral_7_96a5b8a6e474467c.png";
export const REFERRAL_PC_8 = "https://static.xtransfer.com/boss/static/referral_pc_9_f5ee5b49dca66bbf.png";
export const REFERRAL_PC_9 = "https://static.xtransfer.com/boss/static/referral_pc_8_f8bd1e61a5a386b8.png";
export const REFERRAL_PC_10 = "https://static.xtransfer.com/boss/static/referral_pc_10_f578ec739312c14b.png";

export const REFERRAL_H5_1 = "https://static.xtransfer.com/boss/static/referral_h5_1_50a7e3b459b59ef1.jpg";
export const REFERRAL_H5_2 = "https://static.xtransfer.com/boss/static/referral_h5_2_5b238396316eb888.png";
export const REFERRAL_H5_3 = "https://static.xtransfer.com/boss/static/referral_h5_3_22779beef3b7e1c2.png";
export const REFERRAL_H5_4 = "https://static.xtransfer.com/boss/static/referral_h5_4_56876261a88f9b9e.png";
export const REFERRAL_H5_5 = "https://static.xtransfer.com/boss/static/referral_h5_5_ec7275388c393e94.png";
export const REFERRAL_H5_6 = "https://static.xtransfer.com/boss/static/referral_h5_7_afd8c3620b9a69ba.png";
export const REFERRAL_H5_7 = "https://static.xtransfer.com/boss/static/referral_h5_8_cc7cb0e2627b7ff6.png";
export const REFERRAL_H5_8 = "https://static.xtransfer.com/boss/static/referral_area_h5_1_acaa3342580f06bd.png";
export const REFERRAL_H5_9 = "https://static.xtransfer.com/boss/static/referral_area_h5_2_b2bf542b61d26c6c.png";
export const REFERRAL_H5_10 = "https://static.xtransfer.com/boss/static/referral_h5_10_613dbddbb9257205.png";

export const CONTACT_US_BANNER = "https://static.xtransfer.com/boss/static/banner_f1c4ff5b9a098806.png";

export const KAKAOTALK = "https://static.xtransfer.com/boss/static/kakaotalk_8ccfa93a6ea79544.png";
export const LINE = "https://static.xtransfer.com/boss/static/line_94d5caff1f51907c.png";
export const IMO = "https://static.xtransfer.com/boss/static/lmo_15621262959a6fb9.png";
export const MESSANGER = "https://static.xtransfer.com/boss/static/messenger_250a8b1194c56c1a.png";
export const SNAPCHAT = "https://static.xtransfer.com/boss/static/snapchat_c401854c12c36360.png";
export const TELEGRAM = "https://static.xtransfer.com/boss/static/telegram_2de49d401dcf7f77.png";
export const VIBER = "https://static.xtransfer.com/boss/static/viber_f422b579c3a4f90d.png";
export const WHATSAPP = "https://static.xtransfer.com/boss/static/whatsapp_89a3ec24356951ae.png";
export const ZALO = "https://static.xtransfer.com/boss/static/zalo_31ce99ec5315388c.png";
export const REMINDICON = "https://static.xtransfer.com/boss/static/remind-icon_8ad2909895af171b.png";
export const WECHATICON = "https://static.xtransfer.com/boss/static/wechat_b00a7f43ce3438d6.png";

export const TELEGRAMCONTACT = "https://static.xtransfer.com/boss/static/telegram_c13c43379094e361.png";
export const WHATSAPPCONTACT = "https://static.xtransfer.com/boss/static/whatsapp_887cb0a69fa4c258.png";
export const CONTACTICON = "https://static.xtransfer.com/boss/static/contacticon_5ffd8f63f443bdf1.png";

export const PHONEICON = "https://static.xtransfer.com/boss/static/24gl-phoneLoudspeaker1_f8621d554cbedd94.png";
export const TIMEICON = "https://static.xtransfer.com/boss/static/shijian-xianxing1_44037a9e97799057.png";

export const XT_CARD_LOGO = "https://static.xtransfer.com/boss/static/xt-card-logo_47f84874805baeb0.png";
