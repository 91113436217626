import { LangItemType, LanguageMenuType } from "types/language.d";
import { LanguageEnum } from "constants/enum";
import * as process from "process";

export const getHrefWithSearch = (href: string) => {
  let asHref = href;
  let search = window.location.search?.split("?")[1];
  if (search) {
    asHref = /\?/.test(href) ? `${href}&${search}` : `${href}?${search}`;
  }
  return asHref;
};

/**
 * 语言切换
 * 是中国站，则直接href
 * 是英国站或者美国站的
 */
export const handleLangChange = (key: string, item: LangItemType) => {
  // 排除非叶子节点
  if (!item.host || !item.href) return;
  const { host, href, locale } = item;

  let asHref = getHrefWithSearch(href);
  // const isLanding = getIsLanding(location.pathname)
  const curHost = location.host;

  // host不相同，直接使用href切换
  if (host !== curHost) {
    window.location.href = asHref;
    return;
  }

  return locale || LanguageEnum["zh-HK"];
};

export const getCusLang = (siteMap: LanguageMenuType, host?: string, locale?: string) => {
  if (!host || !locale) return { label: "默认语言", key: "cn-default" } as LangItemType;

  for (let [, value] of Object.entries(siteMap)) {
    if ("host" in value) {
      if (value.host === host && value.locale === locale) return value;
    }

    if (value?.children && value?.children.length) {
      let _leaf = value.children.find((i) => host === i.host && locale === i.locale);
      if (_leaf) {
        return _leaf;
      }
    }
  }
  return { label: "默认语言", key: "cn-default" } as LangItemType;
};

/**
 * 根据当前的数据获取在菜单栏展示的字符串
 */
export const getHeaderLangLabel = (item: LangItemType, locations: { isUK?: boolean; isGlobal?: boolean; isUS?: boolean }) => {
  const { locale, host } = item;

  if (locale && host) {
    let location = "",
      lang = "";

    if (locations.isUS) location = "USA";
    else if (locations.isUK) location = "UK";
    else if (locations.isGlobal) location = "中國香港";
    else location = "中国";

    if (locale === LanguageEnum.en) lang = "EN";
    else if (locale === LanguageEnum["zh-HK"]) lang = "繁体";
    else lang = "简体";

    return `${location}(${lang})`;
  }

  // 一般不会走到
  return "中國香港(繁體)";
};

/**
 * 根据当前的数据获取在菜单栏展示的字符串
 */
export const getHeaderLangLabelNew = (
  item: LangItemType,
  locations: { isUK?: boolean; isGlobal?: boolean; isUS?: boolean; isHK?: boolean; isSG?: boolean }
) => {
  const { locale, host } = item;

  if (locale && host) {
    let location = "";

    if (locations.isUS) location = "United States";
    else if (locations.isUK) location = "United Kingdom";
    else if (locations.isGlobal) location = "Global  (English)";
    else if (locations.isHK) location = "中國香港";
    else if (locations.isSG) location = "Singapore";
    return location;
  }

  // 一般不会走到
  return "中國香港(繁體)";
};

/**
 * 获取不同语言在路径中用于拼接的字符串
 */
export const getPathnameWithLocale = (pathname: string, locale: string | undefined) => {
  // 默认语言则无需拼接 或者 低代码地址，不携带语言
  if (locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE || pathname.startsWith("/t")) return pathname;
  const _locale = locale || "";

  // 否则返回语言拼接
  return `${_locale ? `/${_locale}` : ""}${pathname}`;
};

/**
 * 根据输入的url获取host
 */
export const extractHost = (url: string) => {
  // 正则表达式匹配URL中的主机部分
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/\?]+)/i;
  // 使用match方法提取匹配的主机名
  const match = url.match(regex);
  return match ? match[1] : null;
};

/**
 * 是否是尼日利亚
 */
export function isUserFromNigeria(acceptLanguage?: string) {
  if (!acceptLanguage) return false;
  const languages = acceptLanguage.split(",");
  return languages.some((lang) => {
    // 临时添加测试
    return lang.startsWith("en-NG") || lang.startsWith("ha") || lang.startsWith("yo") || lang.startsWith("ig");
  });
}

export type XLanguageHeaderType = "CN" | "TC" | "EN";

/**
 * 多语言转换为xt-language
 */
export function locale2XLanguage(locale?: string): XLanguageHeaderType {
  if (locale === LanguageEnum["zh-HK"]) return "TC";
  if (locale === LanguageEnum.zh) return "CN";
  return "EN";
}
