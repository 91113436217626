import { ReactNode } from 'react'
import classnames from 'classnames'
import styles from './index.module.scss'

interface IconSecProps {
  icon: ReactNode
  title: string | ReactNode
  desc: string | ReactNode
  className?: string
}

const IconSec = ({ icon, title, desc, className }: IconSecProps) => (
  <div className={classnames(styles.wrapper, className)}>
    {icon}
    <div className={styles.content}>
      <h4 className={classnames('xt-h4', styles.title)}>{title}</h4>
      <p className={styles.desc}>{desc}</p>
    </div>
  </div>
)

export default IconSec
