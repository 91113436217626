import { sensorTrack } from "utils/track/sensorUtils";
import { RefObject, useEffect, useRef } from "react";

interface Options extends IntersectionObserverInit {
  once?: boolean;
  module_name: string;
}

const useExposure = (ref: RefObject<HTMLElement>, options: Options) => {
  const hasTracked = useRef(false);
  const { module_name, once = true, root = null, rootMargin = "0px", threshold = 0.5 } = options;

  useEffect(() => {
    if (ref.current && typeof IntersectionObserver === "function") {
      const handler = (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          sensorTrack("international_site_module_show", { module_name });

          if (once && !hasTracked.current) {
            hasTracked.current = true;
            observer.disconnect();
          }
        }
      };

      const observer = new IntersectionObserver(handler, { root, rootMargin, threshold });

      observer.observe(ref.current);

      return observer.disconnect;
    }
  }, [ref, module_name, once, root, rootMargin, threshold]);
};

export default useExposure;
