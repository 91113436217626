import { useQuery } from '@tanstack/react-query'
import { getTotalRewardAmount } from 'apis/activity'

const useTotalRewardAmount = (activityId: string) => {
  return useQuery(['getTotalRewardAmount', activityId], async () => {
    const data = await getTotalRewardAmount({ activityId })
    const list = data?.groupRewardVOList || []

    let amount = 0

    if (list.length) {
      for (const { rewardAmount = 0 } of list) {
        amount += rewardAmount
      }
    }

    return {
      amount,
      currency: list[0].currency
    }
  })
}

export default useTotalRewardAmount
