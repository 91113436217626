/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import classnames from "classnames";
import { useTranslation } from "hooks";
import { useForm } from "react-hook-form";
import { Button, Checkbox, Message, Select } from "xt-design";
import { useMutation, useQuery } from "@tanstack/react-query";
import styles from "./index.module.scss";
import { NextPageWithLayout } from "types/reactExtra";
import { contactSubmit, contactType } from "apis/internation";
import { baseErrorMap, MutationError } from "utils/api";
import { LocationCountryMap, LocationType, MediaType, MediaTypeOptions, QRGroup } from "constants/area";
import { SitesEnum, site2AreaCodeMap, DEFAULT_REG, areaCode2RegMap, site2AreaCode } from "constants/regions";
import { formatImgSrcByStation, sensorTrack, trackError, WhatsappDialingCodeLabel, setXtLng } from "utils";
import { useCommonState } from "context";
import { CONTACT_US_BANNER, CONTACTICON, REMINDICON, TELEGRAMCONTACT, WHATSAPPCONTACT } from "constants/images";
import HKAutoComplete from "components/HKAutoComplete";
import { getDialingCode } from "apis/user";
import { REGISTER } from "constants/router";
import LocationSelect from "components/ContactUsComponents/Contact/ContactUsFormV2/components/LocationSelect";
import CusIcon from "components/CusIcon";

Message.config({
  maxCount: 1,
});

enum BtnTraceEnum {
  BTN1 = "联络我们-提交",
  BTN2 = "联络我们-同意协议",
  BTN3 = "联络我们-姓名",
  BTN4 = "联络我们-手机号码",
  BTN5 = "联络我们-工作邮箱",
  BTN6 = "联络我们-公司名称",
  BTN7 = "联络我们-媒体类型",
  BTN8 = "联络我们-whatsapp区号",
  BTN9 = "联络我们-媒体账号",
}

interface FieldValues {
  phoneArea: string;
  name: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  company: string;
  confirm: boolean;
  number: string;
}

const ContactUsForm: NextPageWithLayout = () => {
  const { locale, query, asPath } = useRouter();
  const { site } = query as { site: SitesEnum };
  const { isGlobal, isUK, isUS, isLanding, isHKLanding, isHKLandingConsult, currentSite } = useCommonState();
  const { t } = useTranslation();
  const showLocation = query?.location === "true";

  const [submitted, setSubmitted] = useState(false);
  const [areaCode, setAreaCode] = useState<string>();
  const [place, setPlace] = useState<{ type?: string; area?: string; number?: string }>();
  const [extraParam, setExtraParam] = useState<string>("");
  const [phoneArea, setPhoneArea] = useState(isLanding ? undefined : site2AreaCodeMap[currentSite]);
  const [mediaType, setMediaType] = useState<MediaType>();
  const phoneRef = React.useRef<{ area: string; phone: string } | null>(null);
  const GTMFromStart = useRef<boolean>(false);

  const { data } = useQuery(["dialing-code"], () => getDialingCode(), {
    onSettled: (res, error) => {
      if (error) trackError(error);
      else if (!(Array.isArray(res) && res.length > 0)) {
        trackError(new Error("区号数据为空，请检查后端日志和arms日志"));
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FieldValues>();

  const hasTrackRef = useRef<{ [key in BtnTraceEnum]?: boolean | undefined }>({
    [`${BtnTraceEnum.BTN3}`]: false,
    [`${BtnTraceEnum.BTN4}`]: false,
    [`${BtnTraceEnum.BTN5}`]: false,
    [`${BtnTraceEnum.BTN6}`]: false,
  });

  const handleBeforeClick = (trackName: BtnTraceEnum, value?: string[]) => {
    sensorTrack("hongkongsite_click", {
      hk_official_url: window.location.href,
      hk_official_button_name: trackName,
      // 点击的时候没有填写的字段，使用中横线拼接
      hk_official_not_enter_fields: value?.join("---") || "",
      // 未拼接的
      hk_official_error_fields: value || [],
    });
  };

  const handleFormInputChange = (trackName: BtnTraceEnum) => {
    if (hasTrackRef.current[`${trackName}`] === true) return; // 只曝光一次
    hasTrackRef.current[`${trackName}`] = true;
    sensorTrack("hongkongsite_click", { hk_official_url: window.location.href, hk_official_button_name: trackName });
    window?.fbq?.("track", "AddPaymentInfo", { content_category: trackName });
  };

  useEffect(() => {
    reset();
  }, [locale, reset]);

  const contactMutation = useMutation(contactSubmit, {
    onSuccess: async () => {
      window?.fbq?.("track", "Lead");
      //@ts-ignore GTM代码打点
      window?.dataLayer?.push({
        event: "adinquiry",
        lead_id: btoa(`${phoneRef.current?.area || ""}${phoneRef.current?.phone || ""}`),
      });
      window?.gtag_report_conversion?.();
      window?.gtag_report_conversion?.({ isYouTube: true });
      if (isHKLanding) {
        goRegister();
      } else {
        setSubmitted(true);
      }
    },
    onError: ({ data, errMsg }: MutationError) => {
      Message.error(
        baseErrorMap[data?.error as keyof typeof baseErrorMap] ||
          baseErrorMap[data?.message as keyof typeof baseErrorMap] ||
          data?.message ||
          data?.error ||
          errMsg
      );
    },
  });

  const goRegister = () => {
    let search = window.location.search;
    // 移除语言参数
    const regex = /&?lng=[^&]*(&|$)/;
    if (search) {
      search = `${search}&${extraParam}`;
    } else {
      search = `?${extraParam}`;
    }
    if (locale === "zh") {
      // 登录注册页会根据此设置来决定显示哪种语言
      setXtLng("zh-HK");
    }
    location.href = `${REGISTER}${search?.replace(regex, "$1")}`;
  };

  const handlePhoneAreaChange = (value: string) => {
    setPhoneArea(value);
    doGTMForm();
  };

  const handleMediaTypeChange = (value: MediaType) => {
    handleFormInputChange(BtnTraceEnum.BTN7);
    setMediaType(value);
    doGTMForm();
  };

  const handleAreaCodeChange = (value: string) => {
    setAreaCode(value);
    doGTMForm();
  };

  const handleLocationChange = (value: { type?: string; area?: string; number?: string }) => {
    setPlace(value);
    doGTMForm();
  };

  const onSubmit = (data: FieldValues) => {
    handleBeforeClick(BtnTraceEnum.BTN1);
    if (!phoneArea) {
      Message.warn(t("please-select"));
      return;
    }
    const utmSource = (query?.utm_source as string) || (locale === "zh" ? "hh_landing" : "") || (isGlobal || isLanding ? "global" : undefined);
    const params = {
      contactName: locale === "zh-HK" || locale === "zh" ? data.name : `${data.firstName} ${data.lastName}`,
      mobileAreaCode: phoneArea,
      officePhone: data.phone,
      email: data.email,
      companyName: data.company,
      site: site2AreaCode[site] ?? site,
      utmSource,
      extraInfo: {
        utm_medium: query?.utm_medium as string,
        utm_campaign: query?.utm_campaign as string,
        utm_content: query?.utm_content as string,
        utm_term: query?.utm_term as string,
        businessSource: query?.businessSource as string,
      },
    };
    phoneRef.current = {
      area: phoneArea,
      phone: data.phone,
    };
    const contactTypeList = [];
    if (mediaType && data.number) {
      const contact: contactType = {
        type: mediaType,
        number: data.number,
      };
      if (mediaType === MediaType.WhatsApp) {
        contact.subNumber = areaCode;
      }
      contactTypeList.push(contact);
      //@ts-ignore
      params.contactTypeList = contactTypeList;
    }

    //处理地址参数
    if (isLanding) {
      if (!place?.type) {
        params.utmSource = params.utmSource + `_Unknow`;
      }

      if (place?.type === LocationType.Other && !place?.area) {
        Message.warn(`${t("please-select")}${t("support-contact-us--header-form-location")}`);
        return;
      }

      if (place?.type && place?.type !== LocationType.Other) {
        params.utmSource = params.utmSource + `_${place.type}`;
      }

      if (place?.type === LocationType.Other && place?.area) {
        // @ts-ignore
        params.utmSource = params.utmSource + `_${LocationCountryMap[`${place?.area}`] || "ga"}`;
      }
    }

    const searchParam = `mobileAreaCode=${encodeURIComponent(phoneArea)}&officePhone=${encodeURIComponent(data.phone)}&email=${encodeURIComponent(
      data.email
    )}&utm_source=${encodeURIComponent(utmSource as string)}`;
    setExtraParam(searchParam);

    setTimeout(() => {
      contactMutation.mutate(params);
    }, 500);
  };

  const doGTMForm = () => {
    if (!GTMFromStart.current) {
      GTMFromStart.current = true;
      // @ts-ignore
      window?.dataLayer?.push({
        event: "adinquiry_form_start",
      });
    }
  };

  const desc = t("landing2-topBanner-ul") as unknown as string[];

  return (
    <section className={classnames("xt-row", styles.root, isHKLandingConsult ? styles.landing2 : null)} id="landing2-contact">
      <div className={styles.left}>
        <h2 className={classnames("xt-h2", styles.title)}>{isHKLandingConsult ? t("landing2-contactUs-title") : t("contactUs-title")}</h2>
        {!isHKLandingConsult && <p className={styles.desc}>{t("contactUs-desc")}</p>}
        {isHKLandingConsult && (
          <ul className={styles.subTitle}>
            {Array.isArray(desc) &&
              desc.map((i, index) => (
                <li key={i} className={styles.item}>
                  <div className={styles.icon}>
                    <CusIcon type={"xtduigou"} style={{ fontSize: 16, color: "var(--text-black-999)" }} />
                  </div>
                  <span className={styles.text}>{i}</span>
                </li>
              ))}
          </ul>
        )}
        <img
          src={formatImgSrcByStation(CONTACT_US_BANNER, { isUS, isUK })}
          alt={"contact-banner"}
          className={styles.img}
          style={{ width: "20rem", height: "16rem" }}
        />
      </div>
      {!submitted ? (
        <div className={styles.right}>
          <form
            onSubmit={handleSubmit(onSubmit, (errors) => {
              handleBeforeClick(
                BtnTraceEnum.BTN1,
                Object.entries(errors)
                  // @ts-ignore value定义缺失
                  .map(([key, value]) => `${key}__${value.ref?.value || ""}`)
              );
              if (errors.confirm) {
                Message.warn(t("contactUs-confirm-message"));
              }
            })}
            className={isHKLandingConsult ? styles.ladning2Form : ""}
          >
            {locale === "zh-HK" || locale === "zh" ? (
              <section className={styles.formItem}>
                <div className={styles.prefix}>
                  <label className={styles.label}>{t("contactUs-name")}</label>
                </div>
                <div className={styles.content}>
                  <input
                    placeholder={t("please-input")}
                    className={classnames(styles.input, { [styles.errorInput]: !!errors.name })}
                    {...register("name", {
                      required: locale === "zh-HK",
                      onChange: () => {
                        handleFormInputChange(BtnTraceEnum.BTN3);
                      },
                    })}
                    onFocus={doGTMForm}
                  />
                </div>
              </section>
            ) : (
              <section className={`${styles.formItem} ${styles.nameWrapper}`}>
                <section className={styles.formItem}>
                  <div className={styles.prefix}>
                    <label className={styles.label}>First name</label>
                  </div>
                  <div className={styles.content}>
                    <input
                      placeholder={t("please-input")}
                      className={classnames(
                        styles.input,
                        {
                          [styles.errorInput]: !!errors.firstName,
                        },
                        styles.shortInput
                      )}
                      {...register("firstName", { required: locale === "en" })}
                      onFocus={doGTMForm}
                    />
                  </div>
                </section>
                <section className={styles.formItem}>
                  <div className={styles.prefix}>
                    <label className={styles.label}>Last name</label>
                  </div>
                  <div className={styles.content}>
                    <input
                      placeholder={t("please-input")}
                      className={classnames(
                        styles.input,
                        styles.formFont,
                        {
                          [styles.errorInput]: !!errors.lastName,
                        },
                        styles.shortInput
                      )}
                      {...register("lastName", { required: locale === "en" })}
                      onFocus={doGTMForm}
                    />
                  </div>
                </section>
              </section>
            )}
            <section className={styles.formItem}>
              <div className={styles.prefix}>
                <label className={styles.label}>{t("contactUs-phone")}</label>
              </div>
              <div className={classnames(styles.content, styles.mobileContent)}>
                <HKAutoComplete onChange={handlePhoneAreaChange} value={phoneArea} />
                <input
                  placeholder={t("please-input")}
                  className={classnames(styles.input, styles.formFont, styles.phone, {
                    [styles.errorInput]: !!errors.phone,
                  })}
                  type="tel"
                  {...register("phone", {
                    required: true,
                    onChange: () => {
                      handleFormInputChange(BtnTraceEnum.BTN4);
                    },
                    pattern: {
                      // 如果找不到匹配的规则，则只允许输入数字
                      value: areaCode2RegMap[phoneArea || ""] || DEFAULT_REG,
                      message: t("contactUs-validate-phone-failed"),
                    },
                  })}
                  onFocus={doGTMForm}
                />
              </div>
            </section>
            {!!errors.phone?.message && <p className={styles.error}>{errors.phone?.message}</p>}
            <section className={styles.formItem}>
              <div className={styles.prefix}>
                <p className={styles.label}>{t("contactUs-email")}</p>
              </div>
              <div className={styles.content}>
                <input
                  placeholder={t("please-input")}
                  className={classnames(styles.input, { [styles.errorInput]: !!errors.email })}
                  {...register("email", {
                    required: true,
                    onChange: () => {
                      handleFormInputChange(BtnTraceEnum.BTN5);
                    },
                    pattern: {
                      value: /^\w[-_\.\w]*@([a-zA-Z0-9][-a-zA-Z0-9]*\.)+[A-Za-z]{2,14}$/,
                      message: t("contactUs-validate-failed"),
                    },
                  })}
                  onFocus={doGTMForm}
                />
              </div>
            </section>
            {!!errors.email?.message && <p className={styles.error}>{errors.email?.message}</p>}
            <section className={styles.formItem}>
              <div className={styles.prefix}>
                <label className={styles.label}>{t("contactUs-company")}</label>
              </div>
              <div className={styles.content}>
                <input
                  placeholder={t("please-input")}
                  className={classnames(styles.input, { [styles.errorInput]: !!errors.company })}
                  {...register("company", {
                    required: true,
                    onChange: () => {
                      handleFormInputChange(BtnTraceEnum.BTN6);
                    },
                  })}
                  onFocus={doGTMForm}
                />
              </div>
            </section>
            {locale === "en" && !isHKLandingConsult && (
              <section className={styles.formItem}>
                <div className={styles.prefix}>
                  <label className={styles.label}>{t("community-account")}</label>
                </div>
                <div className={classnames(styles.content, styles.mobileCommunityContent)}>
                  <Select
                    size="large"
                    value={mediaType}
                    onChange={handleMediaTypeChange}
                    className={styles.mediaType}
                    placeholder={t("please-select")}
                  >
                    {MediaTypeOptions.map((item) => {
                      return (
                        <Select.Option key={item.value}>
                          <img
                            src={formatImgSrcByStation(item.icon, { isUS, isUK })}
                            alt={"contact-banner"}
                            style={{ width: "var(--rem-24)", height: "var(--rem-24)" }}
                          />
                          <span style={{ marginLeft: "var(--rem-8)" }}>{item.label}</span>
                        </Select.Option>
                      );
                    })}
                  </Select>
                  {mediaType === MediaType.WhatsApp && (
                    <Select
                      size="large"
                      value={areaCode}
                      placeholder={t("please-select")}
                      onChange={handleAreaCodeChange}
                      options={WhatsappDialingCodeLabel(data || [], locale)}
                      className={classnames(styles.mediaType, styles.areaCodeSelect)}
                    />
                  )}
                  <input
                    placeholder={t("input-im")}
                    className={classnames(styles.input, styles.formFont, styles.phone)}
                    {...register("number", {
                      onChange: () => {
                        handleFormInputChange(BtnTraceEnum.BTN9);
                      },
                    })}
                    onFocus={doGTMForm}
                  />
                </div>
              </section>
            )}
            {locale === "en" && !isHKLandingConsult && (
              <section className={styles.tipItem}>
                <img
                  src={formatImgSrcByStation(REMINDICON, { isUS, isUK })}
                  alt={"tip"}
                  style={{ width: "var(--rem-24)", height: "var(--rem-24)" }}
                />
                <div className={styles.tipText}>We currently only serve businesses of cross-border trade in goods.</div>
              </section>
            )}
            {showLocation && isLanding && (
              <section className={styles.formItem}>
                <div className={styles.prefix}>
                  <label className={styles.label}>{t("support-contact-us--header-form-location")}</label>
                </div>
                <div className={classnames(styles.content, styles.locationInput, styles.mobileCommunityContent)}>
                  <LocationSelect onChange={handleLocationChange} />
                </div>
              </section>
            )}
            <section className={`${styles.formItem} ${styles.confirmFormItem}`}>
              <div className={styles.content}>
                <label className={styles.confirmRow}>
                  <Checkbox
                    {...register("confirm", {
                      required: true,
                      onChange: (e) => {
                        if (e.target.checked) handleBeforeClick(BtnTraceEnum.BTN2);
                        doGTMForm();
                        setValue("confirm", e.target.checked);
                      },
                    })}
                  />
                  <p>{t("contactUs-confirm")}</p>
                </label>
              </div>
            </section>
            <Button
              className={styles.submit}
              type={"primary"}
              htmlType="submit"
              style={{ border: "none", boxShadow: "none" }}
              loading={contactMutation.isLoading}
            >
              {t("contactUs-submit")}
            </Button>
          </form>
        </div>
      ) : (
        <div className={classnames(styles.right, styles.submitted, isHKLandingConsult ? styles.landing2Submitted : null)}>
          <img src={CONTACTICON} alt="" className={styles.contactIcon} />
          <p className={styles.thankYou}>{t("contactUs-thank-you")}</p>
          <p className={styles.feedback}>{t("contactUs-feedback")}</p>
          {!isHKLandingConsult && (
            <div className={styles.qrGroup}>
              <div className={classnames(styles.qrItem, styles.qrItemLeft)}>
                <img src={WHATSAPPCONTACT} alt="" className={styles.contactImg} />
                <div className={styles.title}>{t("whatsapp-qr")}</div>
                <a className={styles.link} href={QRGroup.whatsapp} target={"_blank"} rel="noreferrer">
                  {t("whatsapp-chat")}
                </a>
              </div>
              <div className={styles.qrItem}>
                <img src={TELEGRAMCONTACT} alt="" className={styles.contactImg} />
                <div className={styles.title}>{t("telegram-qr")}</div>
                <a className={styles.link} href={QRGroup.telegram} target={"_blank"} rel="noreferrer">
                  {t("telegram-chat")}
                </a>
              </div>
              <div></div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default ContactUsForm;
