import React, { createContext, useEffect, useMemo, useState } from 'react'
import { EnvironmentEnum } from 'constants/enum'

interface IUserAgentContextProps {
  userAgent: EnvironmentEnum
  isMobile?: boolean
  isDesktop?: boolean
  isIpad?: boolean
  isNone?: boolean
}

const DEFAULT_STATE: IUserAgentContextProps = {} as IUserAgentContextProps

/**
 * 浏览器标志
 * @deprecated 推荐使用useUserAgentState
 * @type {React.Context<IUserAgentContextProps>}
 */
export const UserAgentContext = createContext<IUserAgentContextProps>(DEFAULT_STATE)

export function useUserAgentState() {
  const context = React.useContext(UserAgentContext)
  if (context === undefined) {
    console.error('useUserAgentState must be used within a UserAgentProvider')
    return DEFAULT_STATE
  }
  return context
}

/**
 * 浏览器信息
 * less1038: window.innerWidth <= 1038,
 * less768: window.innerWidth <= 768,
 * less425: window.innerWidth <= 425,
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
const UserAgentProvider = ({ children }: ProviderProps) => {
  const [userAgent, setUserAgent] = useState<EnvironmentEnum>(EnvironmentEnum.none) // 服务器渲染初始化渲染未必是预期效果，none缓冲切换视觉)

  // 监听本地缓存来同步不同页面间的主题（当前页面无法监听到，直接在顶部栏进行了类的切换)
  useEffect(() => {
    const checkUserAgent = (): void => {
      const width = window.innerWidth

      // 用宽度去判断，是为了适配不改机型，仅拉扯屏幕宽度的情况
      if (width <= 900) {
        // 手机端
        setUserAgent(EnvironmentEnum.mobile)
      }
      else if (width > 900 && width < 1520) {
        // ipad端
        setUserAgent(EnvironmentEnum.ipad)
      }
      else if (width >= 1520) {
        // pc端
        setUserAgent(EnvironmentEnum.desktop)
      }
      else {
        setUserAgent(EnvironmentEnum.none) // 增加none类型来缓冲默认类型样式切换时的视觉突变
      }
    }

    checkUserAgent()
    window.addEventListener('resize', checkUserAgent) // 监听屏幕宽度变化，及时适配当前页面样式
    return (): void => {
      window.removeEventListener('resize', checkUserAgent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof document !== 'undefined' && document.body.offsetWidth])

  const value = useMemo(() => (
    {
      userAgent,
      isMobile: userAgent !== EnvironmentEnum.none ? userAgent === EnvironmentEnum.mobile : undefined,
      isDesktop: userAgent !== EnvironmentEnum.none ? userAgent === EnvironmentEnum.desktop : undefined,
      isIpad: userAgent !== EnvironmentEnum.none ? userAgent === EnvironmentEnum.ipad : undefined,
      isNone: userAgent === EnvironmentEnum.none,
    }
  ), [userAgent])

  return (
    <UserAgentContext.Provider value={value}>
      {children}
    </UserAgentContext.Provider>
  )
}

export default UserAgentProvider
