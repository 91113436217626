import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { getAdsInfo } from "apis/articles";
import { useCommonState } from "context";
import { LanguageEnum } from "constants/enum";

const enum AdCode {
  PC_GLOBAL_TOP_BANNER_HK = "PC_GLOBAL_TOP_BANNER_HK",
  PC_GLOBAL_TOP_BANNER_EN = "PC_GLOBAL_TOP_BANNER_EN",
  H5_GLOBAL_TOP_BANNER_EN = "H5_GLOBAL_TOP_BANNER_EN",
  H5_GLOBAL_TOP_BANNER_HK = "H5_GLOBAL_TOP_BANNER_HK",
}

interface AdContent {
  src: string;
  href: string;
}

const useShowAd = () => {
  const { isGlobal, isMobile } = useCommonState();
  const router = useRouter();
  const { locale, pathname } = router;
  const [visible, setVisible] = useState(true);
  const [adContent, setAdContent] = useState<AdContent>();

  useEffect(() => {
    if (!isGlobal || (pathname !== "/" && pathname !== "/[site]")) return;
    let adCode: null | string = null;
    // 四种情况，分别对应四种code landing的en和hk，非landing的en和hk
    if (isMobile) {
      if (locale === LanguageEnum.en) {
        adCode = AdCode.H5_GLOBAL_TOP_BANNER_EN;
      } else if (locale === LanguageEnum["zh-HK"]) {
        adCode = AdCode.H5_GLOBAL_TOP_BANNER_HK;
      }
    } else {
      if (locale === LanguageEnum.en) {
        adCode = AdCode.PC_GLOBAL_TOP_BANNER_EN;
      } else if (locale === LanguageEnum["zh-HK"]) {
        adCode = AdCode.PC_GLOBAL_TOP_BANNER_HK;
      }
    }
    if (!adCode) return;
    getAdContent(adCode);
  }, [isGlobal, locale, isMobile, pathname]);

  const getAdContent = async (spotCode: string) => {
    const res = await getAdsInfo({ spotCode });
    if (!res?.content || !res.content.length || res.content.length < 1) return;
    const content = res.content[0];
    setAdContent(content);
  };

  const close = () => {
    setVisible(false);
  };

  // *展示广告位的前提是香港站点
  // isGlobal 用来区分站点
  // locale 用来区分是英文 or 繁体
  // isLanding 用来区分是否为落地页

  const showAd = !!(visible && adContent && isGlobal && (locale === LanguageEnum.en || locale === LanguageEnum["zh-HK"]));
  return { showAd, close, adContent };
};

export default useShowAd;
