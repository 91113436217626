import React from "react";
import cls from "classnames";
import styles from "./index.module.scss";

const Main: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <main className={cls(styles.main)} id={"international-main"}>
      {children}
    </main>
  );
};

export default Main;
