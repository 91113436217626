import React, { useEffect, useRef, useState } from "react";
import { Button, Menu } from "xt-design";
import XTLogo from "components/XTLogo";
import CusIcon from "components/CusIcon";
import CusButton from "components/CusButton";
import { useCommonState } from "context";
import classNames from "classnames";
import { HeaderProps } from "../header";
import headerStyles from "../header/index.module.scss";
import styles from "./index.module.scss";
import { isBrowser, setXtLng } from "utils";
import LoginMenu from "components/LoginMenu";
import { OutLink } from "components";
import NewLanguageDrawer from "components/NewLanguageDrawer";

import { useRouter } from "next/router";
import { CusBtnSourceEnum } from "constants/enum";

interface NavProps {
  value?: boolean;
  onChange: () => void;
  beforeClick?: () => void;
}

const Nav = ({ value, onChange, beforeClick }: NavProps) => {
  return (
    <div className={styles.nav}>
      <XTLogo mobile onClick={beforeClick} />
      <Button type={"text"} onClick={onChange} className={styles.button}>
        <CusIcon type={value ? "xtguanbi" : "xtzhankai1"} style={{ fontSize: ".2rem", height: ".2rem" }} />
      </Button>
    </div>
  );
};

const MobileHeader = ({ menus }: HeaderProps) => {
  const { isGlobal, isLanding } = useCommonState();
  const [visible, setVisible] = useState(false);
  const preOverflow = useRef(isBrowser ? window?.document.body.style["overflow"] : "initial");
  const { locale, pathname } = useRouter();

  useEffect(() => {
    let bodyStyle = document.body.style;
    bodyStyle["overflow"] = visible ? "hidden" : preOverflow.current;
  }, [visible]);

  const handleChange = () => {
    setVisible(!visible);
  };

  const handleLinkClick = () => {
    setVisible(false);
  };

  const _renderLinks = () => {
    return (
      <Menu
        mode={"inline"}
        items={menus.map(({ label, value, children }) => {
          if (children?.length) {
            // 有孩子，需要将父级插入到子级菜单的第一个
            return {
              label,
              // antd不允许key重复
              key: `parent-${value}`,
              expandIcon: <CusIcon type={"xtzhankai"} style={{ fontSize: ".2rem" }} />,
              children: [{ label, value }].concat(children).map((i) => ({
                label: (
                  <OutLink href={i.value} className={styles.text} onClick={handleLinkClick}>
                    {i.label}
                  </OutLink>
                ),
                key: i.value,
              })),
            };
          }
          return {
            label: (
              <OutLink href={value} className={styles.title} onClick={handleLinkClick}>
                {label}
              </OutLink>
            ),
            key: value,
          };
        })}
      />
    );
  };

  return (
    <header className={classNames(headerStyles.header, styles.root)} id={"international-mobileHeader"}>
      <div className={styles.inner}>
        <Nav onChange={handleChange} value={visible} />
        <div className={classNames(styles.content, { [`${styles.visible}`]: visible })}>
          <Nav onChange={handleChange} value={visible} beforeClick={handleLinkClick} />
          <section className={styles.menuRoot}>
            {_renderLinks()}
            {!isLanding && (
              <div style={{ paddingLeft: '11px' }}>
                <NewLanguageDrawer beforeClick={handleLinkClick} />
              </div>
            )}
          </section>
          <section className={styles.footer}>
            {!isLanding ? (
              <LoginMenu className={styles.linkButton}></LoginMenu>
            ) : (
              <CusButton
                className={styles.linkButton}
                showIcon={false}
                beforeClick={() => {
                  if (isLanding && isGlobal && locale === "zh") {
                    setXtLng("zh-HK");
                  }
                  handleLinkClick();
                }}
                isMain={!isLanding}
                source={isLanding ? CusBtnSourceEnum.HOME_LANDING : ""}
              />
            )}
          </section>
        </div>
      </div>
    </header>
  );
};

export default MobileHeader;
