import { useRouter } from "next/router";
import { Select } from "xt-design";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getDialingCode } from "apis/user";
import { DialingCode2Label, trackError } from "utils";
import { SitesEnum, Site2AreaCodeOptionsMap } from "constants/regions";
import styles from "./index.module.scss";
import { useCommonState } from "../../context";
import { useTranslation } from "../../hooks";

interface HKAutoCompleteProps {
  value: string | undefined;
  onChange?: (code: string) => void;
  showSearch?: boolean;
}

function HKAutoComplete({ onChange, value }: HKAutoCompleteProps) {
  const { isGlobal, isHK, isLanding } = useCommonState();
  const { t } = useTranslation();
  const { query } = useRouter();
  const { site } = query as { site: SitesEnum };

  // data不可能为null
  const { data } = useQuery(["dialing-code"], () => getDialingCode(), {
    onSettled: (res, error) => {
      if (error) trackError(error);
      else if (!(Array.isArray(res) && res.length > 0)) {
        trackError(new Error("区号数据为空，请检查后端日志和arms日志"));
      }
    },
    enabled: isGlobal || isHK || isLanding,
  });

  const phoneAreaOptions = DialingCode2Label(data) || Site2AreaCodeOptionsMap[site];
  const handlePhoneAreaChange = (value: string) => {
    onChange?.(value);
  };

  return (
    <Select
      size="large"
      value={value}
      placeholder={t("please-select")}
      onChange={handlePhoneAreaChange}
      options={phoneAreaOptions as ISafeAny[]}
      className={styles.phoneArea}
      showSearch={isHK || isGlobal || isLanding}
      optionFilterProp="label"
      filterOption={(input, option) => (option?.label ?? "").includes(input)}
    />
  );
}

export default HKAutoComplete;
