import React, { AnchorHTMLAttributes, forwardRef } from "react";
import Link, { LinkProps } from "next/link";
import { getIsOutLink, getPathWithQuery } from "../utils/route";
import { getPathnameWithLocale } from "../utils/language";
import classNames from "classnames";
import { useRouter } from "next/router";

type CusLinkType = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  Omit<LinkProps, "href"> & { children?: React.ReactNode; href?: string } & React.RefAttributes<HTMLAnchorElement>;

export const LinkInner = forwardRef<HTMLAnchorElement, AnchorHTMLAttributes<HTMLAnchorElement>>((rest, ref) => {
  return <a ref={ref} {...rest} />;
});
LinkInner.displayName = "LinkInner";

const CusLink = forwardRef<HTMLAnchorElement, CusLinkType>(({ href, ...props }, ref) => {
  // 如果是外链，则使用a标签
  if (getIsOutLink(href)) {
    return <LinkInner {...props} ref={ref} />;
  }

  return (
    <Link passHref legacyBehavior href={href || ""}>
      <LinkInner {...props} ref={ref} />
    </Link>
  );
});

CusLink.displayName = "CusLink";

type OutLinkType = CusLinkType & {
  /**
   * 不需要根据landing规则转换
   */
  disabledLanding?: boolean;
  /**
   * 关闭自动拼接语言
   */
  disabledLocaleAdd?: boolean;
};

/**
 * 专门判断是不是外链，是外链则自动拼接路由
 * 配置外链，包含https://并且附加disabledLanding和disabledLocaleAdd两个配置
 */
export const OutLink = forwardRef<HTMLAnchorElement, OutLinkType>(({ href: hrefProp, disabledLanding, disabledLocaleAdd, ...props }, ref) => {
  const { asPath, locale } = useRouter();

  // 转换landing页面的地址
  const href = disabledLanding ? hrefProp || "" : getPathWithQuery({ asPath, toPath: hrefProp || "" });

  // 如果是外链，则使用a标签
  if (getIsOutLink(href)) {
    // 自动拼接语言
    const _href = disabledLocaleAdd ? href : getPathnameWithLocale(href || "", locale);
    const { className, ...rest } = props || {};

    return <a href={_href} className={classNames(className, "html-a")} {...rest} ref={ref} />;
  }

  return <Link href={href || ""} {...props} ref={ref} locale={false} />;
});

OutLink.displayName = "OutLink";

export default CusLink;
