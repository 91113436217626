import React, { ReactElement } from "react";
import { useLoadCustomerService } from "hooks/useLoadCustomerService";
import EntranceCustomer from "components/SvgComponents/entrance_customer.svg";
import { Spin } from "xt-design";

const CustomerService = () => {
  const { loadRef, handleLoadCustomerService } = useLoadCustomerService();

  return (
    <div style={!loadRef.current.loaded ? { position: "fixed", bottom: "38px", right: "20px", zIndex: "50", cursor: "pointer" } : {}}>
      {!loadRef.current.loaded &&
        loadRef.current.hasClick &&
        (loadRef.current.autoOpen ? (
          <Spin shape="circle" style={{ width: 40, height: 40 }} />
        ) : (
          <EntranceCustomer onClick={() => handleLoadCustomerService(true)} />
        ))}
      <div id="customer-service" style={!loadRef.current.loaded && loadRef.current.hasClick ? { width: 0, height: 0 } : {}}>
        <EntranceCustomer
          onClick={() => handleLoadCustomerService(true)}
          style={!loadRef.current.loaded && loadRef.current.hasClick ? { width: 0, height: 0 } : {}}
        />
      </div>
    </div>
  );
};
export default CustomerService;
