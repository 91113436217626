import { useEffect } from "react";
import { useRouter } from "next/router";

const useFillUtmSource = (source: string) => {
  const { pathname: ps } = useRouter();

  useEffect(() => {
    const { protocol, host, pathname, search } = window.location;

    if (search.includes("utm_source=")) return;
    const newURL = `${protocol}//${host}${pathname}${search ? `${search}&utm_source=${source}` : `?utm_source=${source}`}`;

    setTimeout(() => {
      window.history.replaceState("", "", newURL);
    }, 100);
  }, [ps, source]);
};

export default useFillUtmSource;
