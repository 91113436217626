import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { IMilestone } from '../List';
import Milestone from "../MileStone"
import { MilestoneFilledIcon } from 'components/SvgComponents';

interface CardProps {
  data: IMilestone;
}

const SwiperCard = (props: CardProps) => {
  const { data } = props;
  return <div className={classNames(styles.root)}>
    <div style={{textAlign: 'left'}}>
      <MilestoneFilledIcon className={styles.icon}/>
      <Milestone data={data} size='small'></Milestone>
    </div>
  </div>
}

export default SwiperCard
