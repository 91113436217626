import qs from "qs";
import { API_TIMEOUT } from "constants/api";
import { Message } from "xt-design";
import { MutationError, baseErrorMap } from "utils/api";
import { parseUrl } from "query-string";
import dayjs from "dayjs";
import { MessageEnum } from "../constants/enum";

export * from "./format";
export * from "./justify";
export * from "./platform";
export * from "./armsUtil";
export * from "./track";

/**
 * 睡眠
 * @param {number} ms
 * @returns {Promise<number>}
 */
export const sleep = (ms: number) => new Promise<number>((resolve) => setTimeout(resolve, ms, API_TIMEOUT));

/**
 * 点击埋点
 * @param id
 * @param campaign
 */
export const trackClick = (id: string, campaign?: string) => {
  if (!id) return;
  // click埋点仍有散落在业务
  if (window && window.ga) {
    window.ga("send", {
      hitType: "event",
      eventCategory: campaign,
      eventAction: "click",
      eventLabel: id,
    });
  }
};

/**
 * PV 埋点
 */
export const trackPageView = (pageInfo: string) => {
  if (window && window.ga) {
    window.ga("send", {
      hitType: "pageview",
      eventCategory: undefined,
      eventAction: undefined,
      eventLabel: undefined,
      page: pageInfo,
    });
  }
};

/**
 * 在服务端解析路由并正确初始化语言
 */
export const getHtmlLangFromUrl = (url?: string) => {
  if (url) {
    const search = url.substring(url.indexOf("?"));
    if (search && qs.parse(search).lang) return { "data-lang": qs.parse(search).lang };
  }
  return {};
};

/**
 * 复制字符串内容到剪贴板
 */
export const handleCopyText = (text: string, sucStr: string) => {
  try {
    const input = document.createElement("input");
    input.setAttribute("id", "@@input");
    input.value = text;
    document.getElementsByTagName("body")[0].appendChild(input);
    (document.getElementById("@@input") as any)?.select();
    document.execCommand("copy");
    document.getElementById("@@input")?.remove();
    Message.success({
      content: sucStr || "鏈接已復制成功",
      style: {
        marginTop: "141px",
      },
    });
  } catch (e) {
    Message.error("復制失敗");
  }
};

export function mutationMessageError({ data, errMsg }: MutationError) {
  Message.error(
    baseErrorMap[data?.error as keyof typeof baseErrorMap] ||
      baseErrorMap[data?.message as keyof typeof baseErrorMap] ||
      data?.message ||
      data?.error ||
      errMsg
  );
}

export const wordsShortStarFourHalf = (str: string) => {
  if (!str) {
    return "--";
  } else if (!/[\u4E00-\u9FA5]+/g.test(str)) {
    // 英文
    return `${str.slice(0, 1)}****${str.endsWith(".") ? str.slice(-2, -1) : str.slice(-1, str.length)}`;
  } else if (str.length <= 4) {
    // 带中文且名称少于等于4个字
    return `${str.slice(0, 1)}****`;
  } else {
    // 带中文且名称多于4个字
    return `${str.slice(0, 1)}****${str.slice(-2, str.length)}`;
  }
};

export const formatTimeStamp = (time: any) => {
  if (!time) return "--";
  let dayIns = dayjs(time);
  return dayIns.format("YYYY-MM-DD");
};

/**
 * 从url中解析出参数
 */
export function getUrlParams() {
  return parseUrl(location.search).query ?? {};
}

/**
 * 设置xt语言 目前两种语言 en & zh-HK
 * @param lang
 */
export const setXtLng = (lang: string) => {
  if (typeof localStorage !== "undefined") {
    if (lang.toLocaleLowerCase() === "zh-hk") {
      localStorage.setItem("xt_lng", "zh-TW");
      return;
    }
    localStorage.setItem("xt_lng", lang);
  }
};

type MsgType = {
  type: MessageEnum;
  value?: string;
};

/**
 * H5与RN通信
 * @param param
 */
export const sendMsgToRn = (param: MsgType) => {
  //@ts-ignore
  window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(param));
};

export const safeStringify = (obj: any) => {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return "";
  }
};

export const safeParse = (str: string, def: ISafeAny = null) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return def || null;
  }
};
