export enum ThemesEnum {
  light = "light",
  dark = "dark",
}

export enum EnvironmentEnum {
  /**
   * width >= 1038
   * @type {EnvironmentEnum.desktop}
   */
  desktop = "desktop",
  /**
   * width > 768 && width < 1038
   * @type {EnvironmentEnum.ipad}
   */
  ipad = "ipad",
  /**
   * less768
   * <= 768
   * @type {EnvironmentEnum.mobile}
   */
  mobile = "mobile",
  /**
   * width <= 425
   * @deprecated 废弃，暂无使用场景
   * @type {EnvironmentEnum.scss425}
   */
  less425 = "less425",
  none = "none",
}

export enum LanguageEnum {
  zh = "zh",
  ja = "ja",
  "zh-HK" = "zh-HK",
  en = "en",
}

export enum MessageEnum {
  tel = "tel",
  mail = "mail",
  link = "link",
  navBack = "navBack",
  notice = "notice",
}

export enum PageTypeEnum {
  home = "home",
  menu = "menu",
  subMenu = "subMenu",
}

export enum CusBtnSourceEnum {
  HOME_LANDING = "HOME_LANDING",
}
