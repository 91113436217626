import { isError, isObject } from 'lodash-es'
import { isBrowser } from './platform'

/**
 * 定位console.error
 */
const trackError = (error: Error | string | unknown, options?: { filename: string; lineno?: number }) => {
  const _err
    = typeof error === 'string' && !!error
      ? new Error(error)
      : isError(error)
        ? error
      : isObject(error)
        ? new Error(JSON.stringify(error))
        : new Error('trackError')

  if (isBrowser) {
    window.__bl?.error(_err, options);
    ["staging", 'development'].includes(process.env.NODE_ENV) && console.error(_err, options)
  }
  else {
    console.error(error)
  }
}

export { trackError }
