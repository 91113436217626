import { useState, useEffect } from "react";

const usePlatform = () => {
  const [inApp, setInApp] = useState(false);

  useEffect(() => {
    const ua = navigator.userAgent;
    setInApp(ua.includes("XTransfer(XTApp/1.0.0)"));
  }, []);

  return { inApp };
};

export default usePlatform;
