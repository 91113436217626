import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import { MenuItemProps } from "types/language";
import { CusIcon, OutLink } from "components";
import { useCommonState } from "context";
import { FooterProps } from "layout/types";

const Footer = ({ menus, messages, links, copyright }: FooterProps) => {
  const { isGlobal } = useCommonState();
  const _renderRouteLinks = (menu: MenuItemProps, index: number) => {
    const node = (i: MenuItemProps) => (
      <OutLink
        href={i.value}
        className={styles.text}
        rel={i.rel}
        key={i.label}
        onClick={i.onClick}
        disabledLanding={i.disabledLanding}
        disabledLocaleAdd={i.disabledLocaleAdd}
        target={i.target}
      >
        {i.label}
      </OutLink>
    );

    return (
      <ul className={classNames(styles.col, styles["link-col"])} style={index === 0 ? { marginLeft: 0 } : undefined} key={menu.label}>
        <li className={classNames(styles.item, styles.itemTd)} key={menu.label}>
          {node(menu)}
        </li>
        {menu.children?.map((i) => (
          <li className={styles.item} key={i.label}>
            {node(i)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <footer className={styles.footer} id={"international-footer"}>
      <div className={classNames("xt-row", styles.content)}>
        <section className={styles.sec}>
          <div className={styles["link-sec"]}>{menus.map((i, index) => _renderRouteLinks(i, index))}</div>
          <ul className={styles.col}>
            <li className={`${styles.item} ${styles.email}`}>
              <CusIcon type={"xtyouxiang"} className={styles.icon} />
              <span className={styles.text}>{messages[2]}</span>
            </li>
            <li className={`${styles.item} ${styles.email}`}>
              <CusIcon type={"xtmappin"} className={styles.icon} />
              <span className={styles.text}>{messages[3]}</span>
            </li>
            <li style={{ margin: "var(--rem-32) 0", width: "100%", height: 1, background: "var(--text-black-EEE)", listStyle: "none" }} />
            <li className={styles.item} style={{ marginTop: 0 }}>
              <span className={styles.text}>{links[0]}</span>
            </li>
            <li className={styles.item} style={{ marginTop: "var(--rem-12)" }}>
              <OutLink
                href={links[1]}
                title={"FaceBook"}
                className={styles.svg}
                disabledLanding
                disabledLocaleAdd
                target={"_blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <CusIcon type={"xtFacebook"} />
              </OutLink>
              <OutLink
                href={links[2]}
                className={styles.svg}
                title={"LinkedIn"}
                disabledLanding
                disabledLocaleAdd
                target={"_blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <CusIcon type={"xtLinkedin"} />
              </OutLink>
              <OutLink
                href={links[3]}
                className={styles.svg}
                title={"Instagram"}
                disabledLanding
                disabledLocaleAdd
                target={"_blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <CusIcon type={"xtinstagram"} />
              </OutLink>
              <OutLink
                href={links[4]}
                className={styles.svg}
                title={"Youtube"}
                disabledLanding
                disabledLocaleAdd
                target={"_blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <CusIcon type={"xtyoutube"} />
              </OutLink>
              <OutLink
                href={links[5]}
                className={styles.svg}
                title={"Twitter"}
                disabledLanding
                disabledLocaleAdd
                target={"_blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <CusIcon type={"xttuitetwitter"} />
              </OutLink>
            </li>
          </ul>
        </section>
        {!!copyright && (
          <div className={styles.copyright}>
            <p className={styles["copyright-p"]}>{copyright}</p>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
