export const API_TIMEOUT = 600000
export const UNAUTHORIZED_ERROR = ['Unauthorized', 'UNAUTHORIZED']
export const DEFAULT_ID = '11111c607b1a11e89c9c2d42b21b1a3e'
export const COOKIE_FINGERPRINT = 'xt_fingerprint'
export const COOKIE_SERVER_GRANT_ID = 'xt_server_grant_id'
export const XSRF_COOKIE = 'XSRF-TOKEN'
/**
 * 是否接受Cookie政策
 */
export const COOKIE_ACCEPT = 'xt_cookie-accept'
