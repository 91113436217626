/**
 * 用户相关
 */
import { getIsProdOfBrowser } from 'utils/platform'
import { IPartnerInfo, UserProfileProps } from 'types/user.d'
import { Get, Post } from 'utils/api'

export type UserPermissionsProps = string[]

/**
 * example
 * @returns {ApiResponse<T=UserProfileProps>}
 */
export function getUserInfo() {
  return Get<UserProfileProps>('/user-front/user-info')
}

// token转换 partner token转换 https://yapi.xtrfr.cn/project/480/interface/api/57123
export function getToken() {
	return Get<{token:string}>('/promotion/partner/getToken')
}

// https://yapi.xtrfr.cn/project/70/interface/api/55587
export function getPartnerInfoNew(params: { token: string }) {
	return Get<IPartnerInfo>('/promotion/partner/queryPartner', params)
}

/**
 * example
 * @returns {ApiResponse<T=UserPermissionsProps>}
 */
export function getUserPermissions() {
  return Get<UserPermissionsProps>('/user-front/permissions')
}

// region 用户协议
/**
 * 查询最新协议版本
 * @returns {Promise<unknown>}
 */
export const getLaterProtocolVersion = () => Get('/user-front/agreement/versions')

/**
 * 协议种类
 */
export enum ProtocolTypeEnum {
  FOREIGN_CIRCLE = 'FOREIGN_CIRCLE',
}

/**
 * 协议版本
 */
export enum ProtocolVersionEnum {
  FOREIGN_CIRCLE = '1.0.0',
}

export type AgreedProtocolType = { [key in ProtocolTypeEnum]: string[] }

/**
 * 查询已经签约的协议及版本
 * @returns {Promise<unknown>}
 */
export const getSignProtocolVersion = () => Get<undefined | AgreedProtocolType>('/user-front/agreement/agreed')

/**
 * 签约协议
 * @param params
 * @returns {Promise<unknown>}
 */
export const protocolSign = (params: { agreementName: string; agreementVersion: string }) =>
  Post<void>('/user-front/agreement/agree', params)
// endregion
export function logout() {
  return Post<void>('/user-front/sign-out')
}

/**
 * 区号属性
 * @example
 * {
 *     "id": 1,
 *     "enName": "China",
 *     "cnName": "中国大陆",
 *     "cnPinyin": "zhongguodalu",
 *     "hkName": "中國大陸",
 *     "alpha2Code": "CN",
 *     "code": "86"
 * }
 */
export interface DialingCodeProps {
  alpha2Code: string
  cnName: string
  cnPinyin: string
  code: string
  enName: string
  hkName: string
  id: number
}

/**
 * 获取区号
 * yapi:https://yapi.xtrfr.cn/project/480/interface/api/60591
 */
export function getDialingCode(scene = 'signup', url = process.env.NEXT_PUBLIC_API_PREFIX) {
  const isProd = getIsProdOfBrowser()
  if (!isProd) {
    url = `${window.location.origin}/api/v1`
  }
  return Get<DialingCodeProps[] | null>(`${url || ''}/promotion/partner/countryCode/list?scene=${scene}`)
}
