import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { sensorTrack } from "utils/track/sensorUtils";

const useModuleExposure = (moduleName?: string) => {
  const retryCount = useRef(3);

  const report = (moduleName: string) => {
    sensorTrack("international_site_module_show", {
      module_name: moduleName,
    });
  };

  const retry = (cb: () => void) => {
    if (!window.sensorsDataAnalytic201505) {
      if (retryCount.current > 0) {
        setTimeout(() => {
          retryCount.current--;
          retry(cb);
        }, 2000);
      }
    } else {
      cb();
    }
  };

  const { ref } = useInView({
    threshold: 0.6,
    triggerOnce: true,
    onChange(inView) {
      if (inView && moduleName) {
        // 埋点sdk未加载完成，延迟2s再次尝试上报
        retry(() => report(moduleName));
      }
    },
  });
  return ref;
};

export default useModuleExposure;
