import { Popover } from 'xt-design';
import React, { useRef } from 'react';
import { Slider } from '../Slider';
import classNames from 'classnames';
import { VolumeOpenOutlinedIcon, VolumeCloseOutlinedIcon } from 'components/SvgComponents';
import styles from './index.module.scss';

interface IPlayerVolumeProps {
  muted: boolean;
  volume: number;
  onVolumeChange: (rate: number) => void;
  onMutedChange: (mute: boolean) => void;
}

export const Volume = (props: IPlayerVolumeProps) => {
  const { volume, onVolumeChange, muted, onMutedChange } = props;
  const ref = useRef(null);

  const _onMutedChange = () => {
    onMutedChange && onMutedChange(!muted);
  };
  return <>
    <Popover
      showArrow={false}
      overlayClassName='xt-video-volume-popover'
      content={<Slider invert min={0} value={muted ? 0 : volume} max={1} step={0.01} onChange={onVolumeChange} />}
    >
    <span className={classNames(styles.volume, 'hidden-mobile')} ref={ref}  onClick={_onMutedChange}>
      {muted || volume === 0 ?  <VolumeCloseOutlinedIcon /> : <VolumeOpenOutlinedIcon />}
    </span>
  </Popover>
  <span className={classNames(styles.volume, 'hidden-pc')} ref={ref}  onClick={_onMutedChange}>
      {muted || volume === 0 ?  <VolumeCloseOutlinedIcon /> : <VolumeOpenOutlinedIcon />}
    </span>
  </>
};
