import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "hooks";
import classNames from "classnames";
import { useCommonState } from "context";
import CusButton from "components/CusButton";
import { setXtLng } from "utils";
import { sensorTrack } from "utils/track/sensorUtils";
import styles from "./index.module.scss";
import { CusBtnSourceEnum } from "constants/enum";

interface OwnProps {
  title?: string | null;
  buttonText?: string | null;
}

const ContactUS = (props: OwnProps) => {
  const { t } = useTranslation();
  const { isLanding, isGlobal } = useCommonState();
  const { locale } = useRouter();

  const handleBeforeClick = () => {
    sensorTrack("hongkongsite_click", { hk_official_url: window.location.href, hk_official_button_name: "联络我们" });
  };

  return (
    <section className={classNames("xt-row", styles.root)}>
      <h2 className={classNames("xt-h2", styles.title)}>{props?.title || t("common-contactBannerTitle")}</h2>
      <CusButton
        text={props?.buttonText || t("common-contactUs-button")}
        beforeClick={() => {
          if (isLanding && isGlobal && locale === "zh") {
            setXtLng("zh-HK");
          }
          handleBeforeClick();
        }}
        isMain={!isLanding}
        source={isLanding ? CusBtnSourceEnum.HOME_LANDING : ""}
      />
    </section>
  );
};
export default ContactUS;
