import { isBrowser } from "utils/platform";

/**
 * 自定义埋点
 * @param eventName 事件名称
 * @param eventProps 事件属性
 */
export const sensorTrack = (eventName: string, eventProps: {}) => {
  if (!isBrowser) return;
  const sensors = window.sensorsDataAnalytic201505;
  process.env.NODE_ENV === "development" && console.table({ eventName, ...eventProps });
  sensors?.track?.(eventName, eventProps);
};

/**
 * 国际站的模块点击
 */
export const sensorTrackModuleClick = (eventProps: { [key: string]: unknown }) => {
  if (!isBrowser) return;
  const sensors = window.sensorsDataAnalytic201505;
  process.env.NODE_ENV === "development" && console.table({ eventName: 'international_site_module_click', ...eventProps });
  sensors?.track?.('international_site_module_click', eventProps);
}

export enum SensorEventId {
  FUND_pageview = "FUND_pageview",
  FUND_click = "FUND_click",
  FUND_show = "FUND_show",
}
