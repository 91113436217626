import { useEffect, useRef, useState } from 'react'
import { useInViewport } from 'ahooks'

function useFadeIn(num: number, timeout = 300) {
  const ref = useRef(null)
  const hasRef = useRef(false)

  const [inViewport, ratio] = useInViewport(ref, {
    threshold: [0, 0.35, 1],
  })
  const [visible, setVisible] = useState(false)
  const [aniCount, setAniCount] = useState(-1)

  useEffect(() => {
    if (inViewport) setVisible(inViewport)
  }, [inViewport, ratio])

  useEffect(() => {
    let timer = 0
    if (!hasRef.current && visible) {
      hasRef.current = true
      timer = setInterval(() => {
        setAniCount((pre) => {
          if (pre + 1 < num) { return pre + 1 }
          else {
            timer && clearInterval(timer)
            return pre
          }
        })
      }, timeout) as unknown as number
    }
    return () => {
      timer && clearInterval(timer)
    }
  }, [num, visible, timeout])

  return { ref, aniCount } as const
}

export default useFadeIn
