const Logger = require("mfe-i18next-plugin/cjs/logger");

const logger = new Logger({
  log: process.env.NODE_ENV === 'development',
  warn: true,
  error: true,
})

module.exports = {
  // debug: true,
  use: [logger],
  i18n: {
    defaultLocale: 'default', // 设置默认语言r
    locales: ["zh", "en", "zh-HK", "zh-TW", 'default'], // 支持的语言列表、code需合翻译后台保持的一致
    localeDetection: false,
  },
  fallbackLng: {
    "zh-HK": ["zh-TW"],
  },
  ns: ["common"],
  defaultNS: "common",
  serializeConfig: false,
  reloadOnPrerender: true,
};
