import { TOptionsBase } from "i18next";
import { useTranslation } from "next-i18next";
import { convertStr, formatImgSrcByStation } from "../utils";
import { useCommonState } from "../context";

/**
 * 按照自定义的逻辑获取翻译内容
 * 1、支持object、array格式，请参照指定格式
 * 2、英国站和美国站自动替换OSS前缀，cdn-cn3.xtransfer.cn -》cdn-global.xtransfer.com
 */
const useCusTranslation = () => {
  const { t: get } = useTranslation();
  const { isUK, isUS, isGlobal, isLanding } = useCommonState();

  const handleGet = <T>(key: string, options?: TOptionsBase) => {
    const str = get(key, options || {})?.replace(/\\n/g, "\n"); // 替换转义后的换行符
    if (!(!!str && typeof str === "string")) return str as T; // 不是字符串或者空字符串
    if (str.includes("null") || str.includes("undefined") || str === "") return undefined as T; // 标志值无效

    if (key.includes("-ul") || key.includes("-object")) {
      // 值是对象，需要解析
      if (str.includes("[") || str.includes("{")) return convertStr(str) as T;
      if (str.includes("__") || str.includes("_")) {
        const arr = str.split("__");
        const obj: { [key: string]: string } = {};

        arr.forEach((i) => {
          const [key, value] = i.split("_");
          obj[`${key}`] = value;
        });
        return obj as T;
      }
    }

    return formatImgSrcByStation(str, { isUK, isUS, isGlobal, isLanding }) as T;
  };

  return { t: handleGet } as const;
};

export default useCusTranslation;
