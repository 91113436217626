import cls from "classnames";
import React from "react";
import styles from './index.module.scss'
import IfElse from "components/IfElse";

// 四种类型，其中 diff-xxx 主要放置在主题色纸上的按钮
type IButtonType = 'default' | 'primary' | 'diff-default' | 'diff-primary';
interface IButtonProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  type?: IButtonType;
  size?: 'large' | 'middle' | 'small';
  disabled?: boolean;
  onClick?: (e: ISafeAny) => void
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  // shape?: 'circle' | 'round';
}

/**
 * button 组件为满足页面中的样式统一封装工作
 * @param param
 * @returns
 */
export const Button: React.FC<IButtonProps> = (props: React.PropsWithChildren<IButtonProps>) => {
  const { type = 'default', size, prefix, suffix, className, disabled, children, ...rest } = props;
  return (
    <div {...rest} className={cls(styles['xt-btn'], {
      [styles['xt-btn-default']]: type === 'default',
      [styles['xt-btn-primary']]: type === 'primary',
      [styles['xt-btn-diff-default']]: type === 'diff-default',
      [styles['xt-btn-diff-primary']]: type === 'diff-primary',

      [styles['xt-btn-disabled']]: disabled,

      [styles['xt-btn-large']]: size === 'large',
      [styles['xt-btn-small']]: size === 'small',
    }, className)}>
      <IfElse if={!!prefix}>
        <span className={styles.prefix}>
          {prefix}
        </span>
      </IfElse>
      {children}
      <IfElse if={!!suffix}>
        <span className={styles.suffix}>
          {suffix}
        </span>
      </IfElse>
    </div>
  );
};
export default Button;
