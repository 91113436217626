import { AxiosRequestHeaders } from 'axios'
import { getCookie } from 'cookies-next'
import { JsonObject } from 'type-fest'
import xss from 'xss'

import { filterObject } from '../index'
import { isBrowser, isWechat } from '../platform'
import { COOKIE_UUID } from 'constants/cookies'
import { nodeRequest } from 'utils/api'
import { COOKIE_FINGERPRINT, COOKIE_SERVER_GRANT_ID, DEFAULT_ID, XSRF_COOKIE } from 'constants/api'

export type ResultStatusType = 403 | 404 | 500 | '403' | '404' | '500';

/**
 * 浏览器主体
 * @type {{userAgent: () => ({dingtalk: boolean, wxWork: boolean, mobile: boolean, weChat: boolean} | null)}}
 */
export const browser = {
  userAgent() {
    if (isBrowser) {
      const ua = window?.navigator?.userAgent.toLocaleLowerCase()
      return {
        dingtalk: /DingTalk/gi.test(ua), // 钉钉客户端
        wxWork: /MicroMessenger/gi.test(ua) && /wxWork/gi.test(ua), // 企业微信客户端
        weChat: /MicroMessenger/gi.test(ua), // 微信
        mobile: /Mobile/gi.test(ua), // 移动端
      }
    }
    return null
  },
}

interface BaseHeaderParamsProps {
  fingerprint?: string
  serverGrantId?: string
  Authorization?: string
}

/**
 * 获取默认的请求头
 * @param {BaseHeaderParamsProps} option
 * @returns {AxiosRequestHeaders}
 */
export const getBaseHeader = (option?: BaseHeaderParamsProps): AxiosRequestHeaders => {
  const trackId = getCookie(COOKIE_UUID) || DEFAULT_ID
  const xsrfToken
    = option?.Authorization || (!isBrowser ? nodeRequest.token : getCookie(XSRF_COOKIE))

  const fingerprint
    = typeof localStorage === 'object'
      ? localStorage.getItem(COOKIE_FINGERPRINT)
      : option?.fingerprint

  const serverGrantId
    = typeof localStorage === 'object'
      ? localStorage.getItem(COOKIE_SERVER_GRANT_ID)
      : option?.serverGrantId

  const dingtalk = browser?.userAgent()?.dingtalk
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-B3-TraceId': trackId,
    'FP': fingerprint,
    'X-B3-SpanId': !!trackId && typeof trackId === 'string' ? trackId.substring(0, 16) : undefined,
    'X-User-Agent-Context': `${
      isWechat() ? 'Mobile_Wechat' : dingtalk ? 'Applet;appletChannel:DingDing' : 'PC_Browser'
    };${serverGrantId ? `serverGrantId:${serverGrantId};` : ''}${
      fingerprint ? `oriDeviceId:${fingerprint}` : ''
    }`,
    'X-XSRF-TOKEN': xsrfToken,
  }

  return filterObject(headers as unknown as {})
}

export const getNetworkError = (errno: number) => {
  let errMsg
  let errStatus: ResultStatusType = '500'
  if (errno) {
    if (process.env.NODE_ENV === 'production') {
      switch (errno) {
        case 400:
          errMsg = '参数错误'
          break
        case 401:
          errMsg = '未授权，请重新登录'
          break
        case 403:
          errMsg = '您没有此页面/操作的权限~'
          break
        case 404:
          errMsg = '暂无匹配内容呢～'
          break
        case 405:
          errMsg = '参数错误'
          break
        case 408:
          errMsg = '页面超时，请重新提交'
          break
        case 500:
          errMsg = '内部服务错误'
          break
        case 501:
        case 502:
        case 503:
        case 504:
        case 505:
        default:
          errMsg = `网络错误，请检查网络设置或稍后再试 --${errno}`
          break
      }
    }
    else {
      switch (errno) {
        case 400:
          errMsg = '错误的请求'
          break
        case 401:
          errMsg = '未授权，请重新登录'
          break
        case 403:
          errMsg = '拒绝访问'
          errStatus = '403'
          break
        case 404:
          errMsg = '请求错误,未找到该资源'
          errStatus = '404'
          break
        case 405:
          errMsg = '请求方法未允许'
          break
        case 408:
          errMsg = '请求超时'
          break
        case 500:
          errMsg = '服务器端出错'
          errStatus = '500'
          break
        case 501:
          errMsg = '网络未实现'
          break
        case 502:
          errMsg = '网络错误'
          break
        case 503:
          errMsg = '服务不可用'
          break
        case 504:
          errMsg = '网络超时'
          break
        case 505:
          errMsg = 'http版本不支持该请求'
          break
        default:
          errMsg = `其他连接错误 --${errno}`
      }
    }
    // 4开头的错误统一未404状态，5开头的错误吗统一为500状态
    errno.toString().startsWith('4') ? (errStatus = '404') : (errStatus = '500')
  }
  else {
    errMsg = '无法连接到服务器！'
  }
  return { errMsg, errStatus }
}

const handleAuthError = (errno: string) => {
  const authErrMap: { [key: string]: string } = {
    10031: '登录失效，需要重新登录', // token 失效
    10032: '您太久没登录，请重新登录~', // token 过期
    10033: '账户未绑定角色，请联系管理员绑定角色',
    10034: '该用户未注册，请联系管理员注册用户',
    10035: 'code 无法获取对应第三方平台用户',
    10036: '该账户未关联员工，请联系管理员做关联',
    10037: '账号已无效',
    10038: '账号未找到',
  }

  if (authErrMap.hasOwnProperty(errno)) {
    // message.error(authErrMap[errno])
    // 授权错误，登出账户
    // logout()
    return false
  }

  return true
}

export const filterXss = (params: JsonObject) => JSON.parse(xss(JSON.stringify(params)))

export interface MutationError {
  data: {
    error?: string
    message?: string
    timestamp?: string
  }
  errMsg: string
  errStatus: string
  errno: number
}

export const baseErrorMap = {
  'API_TIMEOUT': '网络超时，请重试',
  'FORBIDDEN': '验证码发送次数过多，请稍后再试',
  'VCODE_FREQUENT_1DAY': '获取验证码次数过多，请24小时之后再试',
  'VCODE_FREQUENT_1MIN': '发送过于频繁，请稍后再试',
  'INVALID_FOR_XSS': '包含非法字符',
  'EMOJI_NOT_SUPPORT': '请勿输入表情符号',
  'BAD_REQUEST': '参数错误',
  'PARAMS_ERROR': '参数错误',
  'INTERNAL_SERVER_ERROR': '内部服务错误',
  'Failed to fetch': '网络错误，请检查网络设置或稍后再试',
  'ATTACHMENT_UPLOAD_ERROR': '附件上传失败（服务器原因）',
  'ACCOUNT_FROZEN_ERROR': '您的账号已被冻结，若有疑问，请联系客服',
  'NEED_SLIDER_VERIFY': '请先进行滑块验证',
  'MSG_CODE_RETRY_OVER_SIZE_ERROR': '验证码已失效，请重新发送',
  'MSG_CODE_EXPIRED': '验证码已失效，请重新发送',
  'PERMISSION_LIMIT_NOT_PRIVILEGE': '您没有此页面/操作的权限~',
  'PASSWORD_ERROR': '交易密码错误',
  'PASSWORD_LOCK': '您的交易密码连续输错5次，请找回交易密码',
  'PASSWORD_RESET': '交易密码存在风险，请重置',
  'PASSWORD_ERROR_1': '交易密码错误，还剩1次尝试机会',
  'PASSWORD_ERROR_2': '交易密码错误，还剩2次尝试机会',
  'PASSWORD_ERROR_3': '交易密码错误，还剩3次尝试机会',
  'PASSWORD_ERROR_4': '交易密码错误，还剩4次尝试机会',
  'PASSWORD_NOT_EXIST': '交易密码尚未设置',
  'MFA_TOKEN_EXPIRED': '页面超时，请重新提交',
  'SWIFT_CODE_REGION_ERROR': 'swiftCode有误',
  'UNSUPPORTED_FLOW_ID': '页面超时，请重新提交',
  'FUND_FREEZE_LIMITED_ERR': '账户可用余额不足',
  'PLAY_ID_ABSENT': '网络异常，请稍后重试',
  'REPLAY_ATTACK_ERROR': '网络异常，请稍后重试',
  'NO_DATA': '没有满足查询条件的导出数据',
  'WITHDRAW_CLAIM_AMOUNT_INCORRECT': '抱歉，出现未知错误，请联系客服~',
  'Unauthorized': '您的登录权限已过期，请重新登录',
  'UNAUTHORIZED': '您的登录权限已过期，请重新登录',
  'CANNOT_DELETE': '无法进行删除操作',
  'VCODE_ERROR': '验证码错误',
  'ACQUIRING_PAY_PROCESSING': '支付处理中',
  'ACQUIRING_PAY_BALANCE_NOT_ENOUGH': '账户可用余额不足，订单待支付',
  'DEBT_ORDER_EXIST': '当前有欠费单未缴清，请缴清欠费后重新支付订单',
  'Too Many Requests': '请求过多',
}
