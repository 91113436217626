import React, { Fragment } from "react";
import classNames from "classnames";
import footerStyles from "../footer/index.module.scss";
import styles from "./index.module.scss";
import XTLogo from "components/XTLogo";
import CusIcon from "components/CusIcon";
import { OutLink } from "components";
import { MenuItemProps } from "types/language";
import { useCommonState } from "../../context";
import { FooterProps } from "layout/types";

const MobileFooter = ({ menus, messages, links, copyright }: FooterProps) => {
  const { isGlobal } = useCommonState();

  const _renderRouteLinks = (menu: MenuItemProps) => {
    const node = (i: MenuItemProps) => (
      <OutLink
        href={i.value}
        className={styles.text}
        rel={i.rel}
        disabledLanding={i.disabledLanding}
        disabledLocaleAdd={i.disabledLocaleAdd}
        target={i.target}
      >
        {i.label}
      </OutLink>
    );

    return (
      <Fragment key={menu.label}>
        <li className={classNames(styles.item, styles.itemTd)}>{node(menu)}</li>
        {menu.children?.map((i) => (
          <li className={styles.item} key={i.label}>
            {node(i)}
          </li>
        ))}
      </Fragment>
    );
  };

  return (
    <footer className={classNames(footerStyles.footer, styles.root)} id={"international-mobileFooter"}>
      <div className={classNames("xt-row", styles.inner)}>
        <XTLogo mobile />
        <ul className={styles.ul}>
          {menus.map((i) => _renderRouteLinks(i))}
          <li className={styles.divider} />
          {isGlobal && (
            <li className={`${styles.item} ${styles.phone}`} key={messages[0]}>
              <p className={styles.text}>
                <>
                  <CusIcon type={"xtdianhua"} className={styles.icon} />
                  {messages[0]}
                </>
              </p>
              <p className={styles.text}>{messages[1]}</p>
            </li>
          )}
          <li className={`${styles.item} ${styles.email}`} key={messages[2]}>
            <CusIcon type={"xtyouxiang"} className={styles.icon} />
            <span className={styles.text}>{messages[2]}</span>
          </li>
          {isGlobal && (
            <li className={`${styles.item} ${styles.email}`} key={messages[3]}>
              <CusIcon type={"xtmappin"} className={styles.icon} />
              <span className={styles.text}>{messages[3]}</span>
            </li>
          )}
          <li className={styles.divider} />
          <li className={`${styles.item} ${styles.top}`} key={"links"}>
            <p>{links[0]}</p>
            <section className={styles.linkRow}>
              <OutLink
                href={links[1]}
                title={"FaceBook"}
                className={styles.svg}
                disabledLanding
                disabledLocaleAdd
                target={"_blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <CusIcon type={"xtFacebook"} />
              </OutLink>
              <OutLink
                href={links[2]}
                className={styles.svg}
                title={"Instagram"}
                disabledLanding
                disabledLocaleAdd
                target={"_blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <CusIcon type={"xtLinkedin"} />
              </OutLink>
              <OutLink
                href={links[3]}
                className={styles.svg}
                title={"Linkedin"}
                disabledLanding
                disabledLocaleAdd
                target={"_blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <CusIcon type={"xtinstagram"} />
              </OutLink>
              <OutLink
                href={links[4]}
                className={styles.svg}
                title={"Youtube"}
                disabledLanding
                disabledLocaleAdd
                target={"_blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <CusIcon type={"xtyoutube"} />
              </OutLink>
              <OutLink
                href={links[5]}
                className={styles.svg}
                title={"Twitter"}
                disabledLanding
                disabledLocaleAdd
                target={"_blank"}
                rel={"noopener noreferrer nofollow"}
              >
                <CusIcon type={"xttuitetwitter"} />
              </OutLink>
            </section>
          </li>
        </ul>
      </div>
      {!!copyright && (
        <div className={styles.copyright}>
          <p className={styles["copyright-p"]}>{copyright}</p>
        </div>
      )}
    </footer>
  );
};

export default MobileFooter;
