/* eslint-disable complexity */
import { Button } from "components";
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Checkbox, Form, Input, Tooltip, Message } from "xt-design";
import styles from "./index.module.scss";
import IMSelect from "./components/IMSelect";
import LocationSelect from "./components/LocationSelect";
import PhoneSelect from "./components/PhoneSelect";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { LocationCountryMap, LocationType, MediaType } from "constants/area";
import { SitesEnum, DEFAULT_REG, site2AreaCodeMap, areaCode2RegMap, site2AreaCode } from "constants/regions";
import { useTranslation } from "hooks";
import { useCommonState } from "context";
import { useRouter } from "next/router";
import { useMutation } from "@tanstack/react-query";
import { contactSubmit } from "apis/internation";
import { MutationError, baseErrorMap } from "utils/api";
import { REGISTER } from "constants/router";
import { sensorTrack } from "utils";
import { commonADTrack } from "utils/traceUtil";

const RULES = {
  required: () => {
    return [{ required: true }];
  },
};

const validateMessages = {
  required: "Please enter the required information",
};

interface IProps {
  onSubmitted: () => void;
}

export const sensor = (field_name: string, field_event: "blur" | "change") => {
  sensorTrack("international_site_input", { field_name, field_event });
};

const ContactUsFormV2: React.FC<IProps> = (props: IProps) => {
  const { onSubmitted } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { isGlobal, isLanding } = useCommonState();
  const { query } = useRouter();
  const { site } = query as { site: SitesEnum };
  const phoneRef = React.useRef<{ area: string; phone: string } | null>(null);
  const GTMFromStart = useRef<boolean>(false);

  const showLocation = query?.location === "true";

  const [extraParam, setExtraParam] = useState<string>("");
  const [phoneState, setPhoneState] = useState<string>("success");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const i18n: {
    [key: string]: string;
  } = {
    firstNameLabel: t("support-contact-us--header-form-first-name"),
    lastNameLabel: t("support-contact-us--header-form-last-name"),
    phoneLabel: t("support-contact-us--header-form-phone-number"),
    emailLabel: t("support-contact-us--header-form-email"),
    companyLabel: t("support-contact-us--header-form-company-name"),
    mediaLabel: t("support-contact-us--header-form-im"),
    locationLabel: t("support-contact-us--header-form-location"),
    checkbox: t("support-contact-us--header-form-checkbox"),
    checkboxInfo: t("support-contact-us--header-form-checkbox-info"),
    placeholder: t("support-contact-us--header-form-placeholder"),
    imPlaceholder: t("support-contact-us--header-form-im-placeholder"),
    searchPlaceholder: t("support-contact-us--header-form-search-placeholder"),
  };

  useEffect(() => {
    let timer: any = null;
    if (tooltipOpen) {
      timer = setTimeout(() => {
        setTooltipOpen(false);
        clearTimeout(timer);
      }, 5000); // 自动关闭提示框
    }

    return () => {
      clearTimeout(timer);
    };
  }, [tooltipOpen]);

  const onFormChange = ({}: { layout: any }) => {};

  const goRegister = () => {
    let search = window.location.search;
    const regex = /&?lng=[^&]*(&|$)/;
    if (search) {
      search = `${search}&${extraParam}`;
    } else {
      search = `?${extraParam}`;
    }
    location.href = `${REGISTER}${search?.replace(regex, "$1")}`;
  };

  const contactMutation = useMutation(contactSubmit, {
    onSuccess: async () => {
      commonADTrack({ name: "Lead_submit" });
      //@ts-ignore GTM代码打点
      window?.dataLayer?.push({
        event: "adinquiry",
        lead_id: btoa(`${phoneRef.current?.area || ""}${phoneRef.current?.phone || ""}`),
      });
      window?.gtag_report_conversion?.();
      window?.gtag_report_conversion?.({ isYouTube: true });

      if (isLanding) {
        goRegister();
      } else if (isGlobal) {
        location.href = `${REGISTER}?businessSource=natural`;
      } else {
        onSubmitted();
      }
    },
    onError: ({ data, errMsg }: MutationError) => {
      Message.error(
        baseErrorMap[data?.error as keyof typeof baseErrorMap] ||
          baseErrorMap[data?.message as keyof typeof baseErrorMap] ||
          data?.message ||
          data?.error ||
          errMsg
      );
    },
  });
  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (!values.confirm) {
          setTooltipOpen(true);
        } else {
          const { phone, firstName, lastName, email, company, media, place } = values;
          // handleBeforeClick(BtnTraceEnum.BTN1);
          if (!phone.area) {
            Message.warn(t("please-select"));
            return;
          }
          const params = {
            contactName: `${firstName} ${lastName}`,
            mobileAreaCode: phone.area,
            officePhone: phone.phone,
            email: email,
            companyName: company,
            site: site2AreaCode[site] ?? site,
            utmSource: (query?.utm_source as string) || (isGlobal || isLanding ? "global" : undefined),
            extraInfo: {
              utm_medium: query?.utm_medium as string,
              utm_campaign: query?.utm_campaign as string,
              utm_content: query?.utm_content as string,
              utm_term: query?.utm_term as string,
              businessSource: new URLSearchParams(location.search.split("?")[1])?.get("businessSource") || "",
            },
          };
          phoneRef.current = {
            area: phone.area,
            phone: phone.phone,
          };
          const contactTypeList = [];
          if (media.type && media.number) {
            const contact = media;
            if (media.type === MediaType.WhatsApp) {
              contact.subNumber = media.area;
            }
            contactTypeList.push(contact);
            //@ts-ignore
            params.contactTypeList = contactTypeList;
          }

          //处理地址参数
          if (isLanding) {
            if (!place?.type) {
              params.utmSource = params.utmSource + `_Unknow`;
            }

            if (place?.type === LocationType.Other && !place?.area) {
              Message.warn(t("please-select") + i18n.locationLabel);
              return;
            }

            if (place?.type && place?.type !== LocationType.Other) {
              params.utmSource = params.utmSource + `_${place.type}`;
            }

            if (place?.type === LocationType.Other && place?.area) {
              // @ts-ignore
              params.utmSource = params.utmSource + `_${LocationCountryMap[`${place?.area}`] || "ga"}`;
            }
          }

          const searchParam = `mobileAreaCode=${encodeURIComponent(phone.area)}&officePhone=${encodeURIComponent(
            phone.phone
          )}&email=${encodeURIComponent(email)}`;
          setExtraParam(searchParam);
          setTimeout(() => {
            contactMutation.mutate(params);
          }, 500);
        }
      })
      .catch((v) => {
        const phone = form.getFieldValue("phone");
        if (!phone.phone) {
          setPhoneState("error");
        }
      });
  };

  const PhoneNumberValidation = (
    rule: any,
    value: {
      area: string;
      phone: string;
    },
    callback: any
  ) => {
    const { phone, area } = value;
    if (!area) return Promise.resolve();
    setPhoneState("success");
    const pattern = areaCode2RegMap[value.area] || DEFAULT_REG;
    if (pattern.test(phone)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("contactUs-validate-phone-failed")));
  };

  const onCheckedChange = (e: ISafeAny) => {
    sensor("Contact Us-Form-Checkbox", "change");
    doGTMForm();
    if (e.target.checked) {
      setTooltipOpen(false);
    }
  };

  const doGTMForm = () => {
    if (!GTMFromStart.current) {
      GTMFromStart.current = true;
      // @ts-ignore
      window?.dataLayer?.push({
        event: "adinquiry_form_start",
      });
    }
  };

  return (
    <div className={styles.form}>
      <Form layout="vertical" form={form} validateMessages={validateMessages} onValuesChange={onFormChange}>
        <Row gutter={[0, 0]}>
          <Col span={10}>
            <Form.Item name="firstName" label={i18n.firstNameLabel} rules={RULES.required()}>
              <Input placeholder="Please enter" size="large" onFocus={doGTMForm} onBlur={() => sensor("Contact Us-Form-First Name", "blur")} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item name="lastName" label={i18n.lastNameLabel} rules={RULES.required()}>
              <Input placeholder="Please enter" size="large" onFocus={doGTMForm} onBlur={() => sensor("Contact Us-Form-Last Name", "blur")} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          validateStatus={phoneState as ISafeAny}
          help={phoneState === "error" ? "Please enter the required information" : null}
          name="phone"
          label={i18n.phoneLabel}
          rules={[...RULES.required(), { validator: PhoneNumberValidation }]}
        >
          <PhoneSelect />
        </Form.Item>
        <Form.Item
          name={"email"}
          label={i18n.emailLabel}
          rules={[
            ...RULES.required(),
            {
              pattern: /^\w[-_\.\w]*@([a-zA-Z0-9][-a-zA-Z0-9]*\.)+[A-Za-z]{2,14}$/,
              message: t("contactUs-validate-failed"),
            },
          ]}
        >
          <Input placeholder={i18n.placeholder} size="large" onFocus={doGTMForm} onBlur={() => sensor("Contact Us-Form-Email", "blur")} />
        </Form.Item>
        <Form.Item name="company" label={i18n.companyLabel} rules={RULES.required()}>
          <Input placeholder="Please enter" size="large" onFocus={doGTMForm} onBlur={() => sensor("Contact Us-Form-Company Name", "blur")} />
        </Form.Item>
        <Form.Item name="media" label={i18n.mediaLabel}>
          <IMSelect />
        </Form.Item>
        {showLocation && isLanding && (
          <Form.Item name="place" label={i18n.locationLabel}>
            <LocationSelect />
          </Form.Item>
        )}
        <Form.Item name="confirm" valuePropName="checked">
          <Checkbox onChange={onCheckedChange}>
            <Tooltip
              placement="topLeft"
              overlayClassName={styles.tooltipOverlay}
              visible={tooltipOpen}
              title={
                <div>
                  <ExclamationCircleFilled style={{ color: "var(--xt-text-warn-color)", marginRight: "var(--xt-margin-8)" }} />
                  {i18n.checkboxInfo}
                </div>
              }
            >
              {i18n.checkbox}
            </Tooltip>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button className={styles.submit} onClick={onSubmit} type={"primary"}>
            {t("contactUs-submit")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactUsFormV2;
