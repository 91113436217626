import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCommonState } from "context";
import { INTERNATIONAL_INDEX } from "constants/router";
import { INTERNATIONAL_LOGO } from "constants/images";
import { SitesEnum } from 'constants/regions';
import { getPathWithQuery } from "utils/route";
import { ComponentCommonProps } from "types/reactExtra";
import styles from "./index.module.scss";

const XTLogo = (props: ComponentCommonProps & { mobile?: boolean; onClick?: () => void }) => {
  const { mobile, className, onClick, ...restProps } = props;
  const { isGlobal } = useCommonState();
  const { query, asPath } = useRouter();
  const { site } = query as { site: SitesEnum };
  return (
    <a href={getPathWithQuery({ asPath, toPath: isGlobal ? INTERNATIONAL_INDEX : `${INTERNATIONAL_INDEX}${site}` })} onClick={onClick}>
      <img src={INTERNATIONAL_LOGO} alt={"logo"} {...restProps} className={classNames(className, { [`${styles.mobile}`]: mobile })} />
    </a>
  );
};

export default XTLogo;
