import { useRouter } from "next/router";
import React, { useRef } from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import ChangeLanguage from "components/ChangeLanguage";
import ContactUSButton from "components/CusButton";
import XTLogo from "components/XTLogo";
import LoginMenu from "components/LoginMenu";
import { setXtLng } from "utils";
import { sensorTrack } from "utils/track/sensorUtils";
import { useCommonState } from "context";
import { Dropdown, Menu } from "xt-design";
import { OutLink } from "components";
import { MenuItemProps } from "types/language";
import { CusBtnSourceEnum } from "constants/enum";

export interface HeaderProps {
  menus: MenuItemProps[];
  showAd?: boolean;
  children?: React.ReactNode;
}

const Header = (props: HeaderProps) => {
  const { menus, children } = props;
  const { isGlobal, isLanding, isHK } = useCommonState();
  const { locale } = useRouter();

  const placeRef = useRef<HTMLDivElement>(null);

  const _renderLink = (i: MenuItemProps) => {
    const labelNode = () => (
      <li key={i.label} className={styles["menu-item"]}>
        <OutLink href={i.value} className={styles.link}>
          {i.label}
        </OutLink>
      </li>
    );

    if (i.children?.length) {
      const Menus = (
        <Menu
          selectable={true}
          forceSubMenuRender
          items={i.children.map((i) => ({
            label: (
              <OutLink key={i.value} href={i.value}>
                {i.label}
              </OutLink>
            ),
            key: i.value,
          }))}
        />
      );
      return (
        <Dropdown
          key={i.label}
          getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
          arrow={{ pointAtCenter: true }}
          overlay={Menus}
          placement={"bottom"}
        >
          {labelNode()}
        </Dropdown>
      );
    }
    return labelNode();
  };

  const _renderRight = () => {
    if (isGlobal || isHK) return <LoginMenu />;
    return (
      <ContactUSButton
        className={styles.button}
        showIcon={false}
        style={{ margin: 0 }}
        beforeClick={() => {
          if (isLanding && isGlobal && locale === "zh") {
            setXtLng("zh-HK");
          }
          sensorTrack("hongkongsite_click", { hk_official_url: window.location.href, hk_official_button_name: "联络我们" });
        }}
        isMain={!isLanding}
        source={isLanding ? CusBtnSourceEnum.HOME_LANDING : ""}
      />
    );
  };

  return (
    <header className={classNames(styles.headerWrapper)} id={"international-header"} ref={placeRef}>
      {children}
      <div className={classNames("xt-row", styles.header)}>
        <XTLogo className={styles.logo} />
        <div className={classNames(styles["logo-right"], "xt-dropdown")}>
          <ul className={classNames(styles.menu, "xt-dropdown-header")} id={"international-header-menu"}>
            {menus.map((i) => _renderLink(i))}
          </ul>
          {!isLanding && <ChangeLanguage />}
          {_renderRight()}
        </div>
      </div>
    </header>
  );
};

export default Header;
