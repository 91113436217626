import React, { useRef } from "react";
import { Dropdown, Menu } from "xt-design";
import classnames from "classnames";
import CusIcon from "../CusIcon";
import styles from "./index.module.scss";
import { useChangeLanguage, useCommonState } from "context";
import { getHeaderLangLabel, getHeaderLangLabelNew, getHrefWithSearch } from "utils/language";
import { sites, SitesEnum, SitesWithoutLandingEnum } from "constants/regions";

interface IChangeLanguageProps {
  newHeader?: boolean;
}

const ChangeLanguage: React.FC<IChangeLanguageProps> = ({ newHeader }) => {
  const [curSiteAndLang, setCurSiteAndLang] = useChangeLanguage();
  const { currentSite } = useCommonState();

  const sitesDerived = Object.entries(sites).map(([key, value]) => {
    const { icon, alt, href, label } = value;
    return {
      key,
      icon: <img src={icon} alt={alt} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      label,
      onClick: () => {
        location.href = href;
      },
    };
  });

  const domRef = useRef<HTMLDivElement>(null);

  const Items = (
    <Menu
      getPopupContainer={(triggerNode: HTMLElement) => domRef.current || triggerNode}
      selectable={true}
      subMenuCloseDelay={0.01}
      // 这里只需要默认选中，切换网址生效，其他时候直接使用内置变量
      // TODO 在回退时，选中的语言没有变化
      defaultSelectedKeys={[curSiteAndLang.key]}
      items={sitesDerived}
    />
  );

  const site = sites[currentSite];

  return (
    <div
      className={classnames(styles.root, "xt-dropdown", {
        [styles.newDropdown]: newHeader,
      })}
      ref={domRef}
      id={"language_menu"}
    >
      <Dropdown overlay={Items} placement={"bottom"} getPopupContainer={(triggerNode: HTMLElement) => triggerNode}>
        <div className={styles.dropDown}>
          <img src={site.icon} alt={site.alt} width="20" />
          <span className={styles.label}>{site.label}</span>
          <CusIcon type={"xtzhankai"} />
        </div>
      </Dropdown>
    </div>
  );
};

export default ChangeLanguage;
