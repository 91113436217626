import React, { useState } from "react";
import { Button } from "xt-design";
import { getCookie, setCookie } from "cookies-next";
import Link from "next/link";
import classNames from "classnames";
import styles from "./index.module.scss";
import { INTERNATIONAL_COOKIE_POLICY } from "constants/router";
import { COOKIE_ACCEPT } from "constants/api";
import CusIcon from "components/CusIcon";
import { useTranslation } from "hooks";
import { useCommonState, useUserAgentState } from "context";
import { getPathWithQuery } from "utils/route";
import { useRouter } from "next/router";

enum CookieAcceptEnum {
  REJECT = "reject",
  ACCEPT = "accept",
}

const CookieBanner = () => {
  const { t } = useTranslation();
  const { isMobile } = useUserAgentState();
  const { isGlobal } = useCommonState();
  const router = useRouter();

  const [visible, setVisible] = useState(() => {
    const value = getCookie(COOKIE_ACCEPT);
    return !value;
  });

  // useEffect(() => { // 仅香港站需要
  //   setHK(getIsHK(window.location.host))
  // }, [])

  const handleClick = (accept?: boolean) => {
    setCookie(COOKIE_ACCEPT, accept ? CookieAcceptEnum.ACCEPT : CookieAcceptEnum.REJECT);
    setVisible(false);
  };

  if (visible && isGlobal) {
    return (
      <div className={styles.root} id="cookie_banner">
        {isMobile && <CusIcon className={styles.mobileClose} type={"xtguanbi"} onClick={() => handleClick(false)} />}
        <div className={classNames("xt-row", styles.inner)}>
          <p className={styles.content}>
            {t("common-cookiePolicyConfirm")}
            <Link href={getPathWithQuery({ asPath: router.asPath, toPath: INTERNATIONAL_COOKIE_POLICY })}>{t("common-cookiePolicyLink")}</Link>
            {t("common-cookiePolicyEnd")}
          </p>
          <div className={styles.toolbar}>
            <Button className={`close ${styles.button}`} id="close_banner" onClick={() => handleClick(true)}>
              {t("common-cookiePolicyButton")}
            </Button>
            <CusIcon className={styles.svg} type={"xtguanbi"} onClick={() => handleClick(false)} />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default CookieBanner;
