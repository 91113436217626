const CDN_DOMAIN = process.env.NEXT_PUBLIC_CDN_DOMAIN;
const OSS_CDN_DOMAIN = process.env.NEXT_PUBLIC_OSS_CDN_DOMAIN;
const INTERNATIONAL_OSS_CDN_DOMAIN = process.env.NEXT_PUBLIC_INTERNATIONAL_OSS_CDN_DOMAIN;

type CSPKeyType = "defaultSrc" | "styleSrc" | "imgSrc" | "frameSrc" | "scriptSrc" | "fontSrc" | "workerSrc" | "objectSrc";

const DIRECTIVE_KEY_MAPPING: { [key in CSPKeyType]: string } = {
  defaultSrc: "default-src",
  styleSrc: "style-src",
  imgSrc: "img-src",
  frameSrc: "frame-src",
  scriptSrc: "script-src",
  fontSrc: "font-src",
  workerSrc: "worker-src",
  objectSrc: "object-src",
};

type RuleItemType = { [key in CSPKeyType]?: (string | undefined)[] };

function generateCspRules(params?: { directives: RuleItemType }) {
  const directives = (params?.directives || {}) as RuleItemType;
  const givenDirectives = Object.keys(directives).filter((d) => DIRECTIVE_KEY_MAPPING[d as CSPKeyType]);
  return givenDirectives.map((d) => `${DIRECTIVE_KEY_MAPPING[d as CSPKeyType]} ${(directives[d as CSPKeyType] || []).join(" ")}`).join("; ");
}

const styleSrc = [
  CDN_DOMAIN,
  OSS_CDN_DOMAIN,
  INTERNATIONAL_OSS_CDN_DOMAIN,
  "https://aff-im.cdn.bcebos.com",
  "https://api.mapbox.com/",
  "https://cdn.tiny.cloud/",
  `'self'`,
  `'unsafe-inline'`,
  "http://cdnjs.cloudflare.com",
  "http://sgoutong.baidu.com",
  "https://g.alicdn.com",
  "https://static.xtransfer.com",
  "https://static.xtrfr.com",
  "https://www.googletagmanager.com", // GTM
];

export const scriptSrc = [
  CDN_DOMAIN,
  OSS_CDN_DOMAIN,
  INTERNATIONAL_OSS_CDN_DOMAIN,
  `'unsafe-inline'`,
  `'self'`,
  "https://connect.facebook.net", // facebook外网访问
  "https://fxgate.baidu.com", // 百度回传
  `'unsafe-eval'`, // 百度爱凡凡 start
  "https://affim.baidu.com",
  "https://p.qiao.baidu.com",
  "https://aff-im.cdn.bcebos.com",
  "https://sofire.bdstatic.com",
  "https://aifanfan.baidu.com",
  "https://aiff.cdn.bcebos.com",
  "http://sgoutong.baidu.com",
  "https://fe-resource.cdn.bcebos.com",
  "http://ada.baidu.com",
  "https://goutong.baidu.com",
  "https://ada.baidu.com", // 百度爱凡凡 end
  "https://www.google-analytics.com",
  "https://hm.baidu.com",
  "https://tag.baidu.com",
  "http://g.alicdn.com",
  "https://g.alicdn.com",
  "https://ssl.captcha.qq.com",
  "https://t.captcha.qq.com",
  "https://captcha.gtimg.com",
  "http://aeis.alicdn.com",
  "https://cf.aliyun.com",
  "https://ynuf.aliapp.org",
  "https://*.tdum.alibaba.com",
  "https://diablo.alibaba.com",
  "https://af.alicdn.com",
  "https://aeu.alicdn.com",
  "https://at.alicdn.com",
  "https://img.alicdn.com",
  "https://acjs.aliyun.com",
  "https://oyxdwx.com",
  "https://oyxdwx.com:9443",
  "https://api.mapbox.com/",
  "https://cdn.tiny.cloud/",
  "https://www.googletagmanager.com",
  "https://www.googleadservices.com",
  "https://googleads.g.doubleclick.net",
  "https://arms-retcode.aliyuncs.com",
  "https://static.xtransfer.com",
  "https://static.xtrfr.com",
  "https://cdn-hk.xtransfer.cn",
  "https://*.doubleclick.net", // GTM
  "https://bat.bing.com", // GTM
  "https://analytics.tiktok.com", // Tiktok
];

const frameSrc = [
  `'self'`,
  "https://affim.baidu.com", // 爱番番
  "https://t.captcha.qq.com", //腾讯滑块
  "https://p.qiao.baidu.com/", // 爱番番
  "https://www.facebook.com", // facebook外网访问
  "https://wwo.wps.cn", // wps 文档预览
  "https://*.doubleclick.net", // GTM
  "https://bat.bing.com", // GTM
  "https://www.googletagmanager.com", // GTM
  "https://analytics.tiktok.com", // Tiktok
];

const fontSrc = [
  CDN_DOMAIN,
  OSS_CDN_DOMAIN,
  INTERNATIONAL_OSS_CDN_DOMAIN,
  "https://at.alicdn.com",
  "data:",
  "https://static.xtransfer.com",
  "https://static.xtrfr.com",
];

const objectSrc = [`'none'`];

const workerSrc = [`'self'`, CDN_DOMAIN, "blob:"];

function getCspRulesForRender() {
  return generateCspRules({
    directives: {
      scriptSrc,
      objectSrc,
      styleSrc,
      frameSrc,
      fontSrc,
      workerSrc,
    },
  });
}

export { getCspRulesForRender };
