import React, { useEffect } from "react";
import { Select, Input } from "xt-design";
import { useTranslation } from "hooks";
import styles from "./index.module.scss";
import { MediaType, MediaTypeOptions } from "constants/area";
import { WhatsappDialingCodeLabel, formatImgSrcByStation, trackError } from "utils";
import { IfElse } from "components";
import { useQuery } from "@tanstack/react-query";
import { getDialingCode } from "apis/user";
import { useRouter } from "next/router";
import classNames from "classnames";
import { sensor } from "../..";

const { Option } = Select;

interface IProps {
  onChange?: (val: { type?: string; area?: string; number?: string }) => void;
  value?: string;
}

const IMSelect: React.FC<IProps> = (props) => {
  const { onChange = () => {} } = props;
  const { t } = useTranslation();
  const { locale, query, isReady, asPath } = useRouter();
  const [_value, setValue] = React.useState<{
    type?: string;
    area?: string;
    number?: string;
  }>({});

  const onSelectChange = (val: string) => {
    sensor("Contact Us-Form-IM-App", "change");
    setValue({
      ..._value,
      type: val,
    });
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ..._value,
      number: e.target.value,
    });
  };

  const onAreaChange = (val: string) => {
    setValue({
      ..._value,
      area: val,
    });
  };

  const { data } = useQuery(["dialing-code"], () => getDialingCode(), {
    onSettled: (res, error) => {
      if (error) trackError(error);
      else if (!(Array.isArray(res) && res.length > 0)) {
        trackError(new Error("区号数据为空，请检查后端日志和arms日志"));
      }
    },
  });

  useEffect(() => {
    onChange(_value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value]);
  return (
    <div className={classNames(styles.root, styles.input)}>
      <Select size="large" placeholder={t("please-select")} onChange={onSelectChange} dropdownStyle={{ padding: "var(--xt-padding-8)" }}>
        {MediaTypeOptions.map((item) => {
          return (
            <Select.Option key={item.value} className={styles.options}>
              <img
                src={formatImgSrcByStation(item.icon, { isUS: false, isUK: false })}
                alt={"contact-banner"}
                style={{ width: "var(--xt-margin-24)", height: "var(--xt-margin-24)" }}
              />
              <span style={{ marginLeft: "var(--xt-margin-8)" }}>{item.label}</span>
            </Select.Option>
          );
        })}
      </Select>
      <IfElse if={_value.type === MediaType.WhatsApp}>
        <Select
          size="large"
          value={_value.area}
          placeholder={t("support-contact-us--header-form-im-placeholder")}
          onChange={onAreaChange}
          options={WhatsappDialingCodeLabel(data || [], locale)}
        />
      </IfElse>
      <Input
        size="large"
        onBlur={() => sensor("Contact Us-Form-IM-Account", "blur")}
        onChange={onInputChange}
        placeholder="Please enter"
        className={styles.input}
      />
    </div>
  );
};

export default IMSelect;
