import { useRouter } from "next/router";
import qs from "qs";

const useUtmTracker = () => {
  const router = useRouter();
  const { query } = router;
  const { utm_source = "", utm_medium = "", utm_campaign = "", utm_content = "", utm_term = "" } = query;

  const utmPrams = [utm_source, utm_medium, utm_campaign, utm_content, utm_term];
  const businessSource = utmPrams.join("_");

  if (utmPrams.some(Boolean)) {
    query.businessSource = businessSource;
  }

  const { site, lng, ...queryWithoutSite} = query
  const utmSearchSource = qs.stringify({ ...queryWithoutSite }, { encode: false });

  return {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    utmSearchSource,
  };
};

export default useUtmTracker;
