var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"mfe-user-international-site-cb2539f6fb0d888bed883de7d80ba290354167b4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { SENTRY_IGNORE_ERRORS } from "constants/sentryConfig";
import { isObject } from "lodash-es";
import { parseUrl } from "query-string";
import { scriptSrc } from "utils/csp";

const isProd = process.env.NODE_ENV === "production";

if (process.env.NEXT_PUBLIC_SERVICE_ENV === "prod")
  Sentry.init({
    dsn: "https://3040c3c561c64f0be80acd1b723d203c@femonitorapi.xtransfer.com/76",
    environment: isProd ? "prod" : "staging",
    // 开启必须关闭webpack的Tree Shaking配置
    // 生产必须关闭
    debug: false,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    // Called for transaction events
    // beforeSendTransaction(event, hint) {
    //   console.info(`Sentry Logger [log]: beforeSend-client，event：${hint.event_id}，transaction：${event.transaction}，op：${event.contexts?.trace?.op}，status：${event.contexts?.trace?.status}，url：${event.request?.url}，op：${JSON.stringify(event.tags)}，`)
    //   return event
    // },
    beforeBreadcrumb(breadcrumb) {
      // 过滤nacos的一些debug日志
      // if (breadcrumb?.level === 'debug') return null
      if (
        [
          "[PushReceiver]",
          "[HostReactor]",
          "Critical dependency: require function is used in a way in which dependencies cannot be statically extracted",
          "All reported dependencies need to be absolute paths.",
          "arms-retcode.aliyuncs.com/r.png",
          "/nacos/v1/ns/instance/beat",
        ].some((key) => breadcrumb?.message?.includes(key))
      )
        return null;
      return breadcrumb;
    },
    integrations: [
      // Sentry.replayIntegration({
      //   // Additional Replay configuration goes in here, for example:
      //   maskAllText: true,
      //   blockAllMedia: true,
      // }),
      Sentry.breadcrumbsIntegration({
        // 关闭console的log
        console: false,
        // 适当增大 dom path 字符串的长度，默认值80，防止某些节点的classname过长导致路径不全
        dom: { maxStringLength: 256 },
      }),
    ],
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    beforeSend: (event, hint) => {
      // 过滤csp
      const url = parseUrl(event.request?.url || "");
      if (url?.hostname && !scriptSrc.includes(url.hostname)) {
        return null;
      }
      // 过滤蜘蛛请求
      if (
        isObject(event.contexts?.request?.headers) &&
        typeof (event.contexts?.request?.headers as Record<string, unknown>)?.["User-Agent"] === "string" &&
        (event.contexts?.request?.headers as Record<string, string>)?.["User-Agent"].includes("spider")
      )
        return null;

      return event;
    },
    initialScope: {
      tags: { MY_NS: process.env.NEXT_PUBLIC_MY_NS },
    },
  });
