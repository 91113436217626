import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  src: string;
  /**
   * 为了应对不同场景下的图片显示问题（pc 三倍图，h5 两倍图）
   */
  srcClear?: string;
  width?: string;
  height?: string;
  alt?: string;
}

/**
 * 图片组件，为防止后面懒加载策略变更，这里封装一层
 * @param props
 * @returns
 */
export const XTImage = (props: IProps) => {
  const { className, width, src, srcClear, alt, ...rest } = props;
  return (
    <div className={classNames(styles.img, className)} {...rest}>
      <LazyLoadImage className="hidden-pc" src={src} width="100%" height="100%" alt={alt} />
      <LazyLoadImage className="hidden-mobile" src={srcClear || src} width="100%" height="100%" alt={alt} />
    </div>
  );
};
