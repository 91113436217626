import { Get } from "utils/api";
import { IResPost } from "types/articles";

// 新闻：NEWS; 博客：BLOG; 资讯文章：ARTICLE; 写作：WRITING; 品宣文章（包括新闻和博客）：NEWS_AND_BLOG
export type CategoryType = "NEWS" | "BLOG" | "ARTICLE" | "WRITING" | "NEWS_AND_BLOG";

// 分页查询新闻列表res schema
export interface IResGetArticles {
  data: IResPost[];
  totalCount: number;
}

export interface IGetTopPostParams {
  count: number;
  category: CategoryType;
}

/**
 * 获取置顶文章
 * @returns
 */
export function getTopPost(params: IGetTopPostParams) {
  return Get<IResPost[]>("/news/top-news/query", params);
}

export interface IGetPostsParams {
  category: CategoryType;
  pageNum: number;
  pageSize: number;
  includeTopArticle: boolean;
}
/**
 * 获取新闻列表
 * @returns
 */
export function getPosts(params: IGetPostsParams) {
  return Get<IResGetArticles>("/news/query", params);
}

export interface IGetAdsParams {
  spotCode: string;
}
export interface IAdsRes {
  content: IAdsContent[];
}
export interface IAdsContent {
  src: string;
  href: string;
}
/**
 * 获取资讯列表页广告位内容
 */
// https://www.xtransfer.cn/api/v1/album/detail?spotCode=USER_MARKETING_CIRCLE
export function getAdsInfo(params: IGetAdsParams) {
  return Get<IAdsRes>("/album/detail", params);
}
