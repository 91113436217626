import React, { createContext, useCallback, useState } from 'react'
import { deleteCookie, getCookie } from 'cookies-next'
import { Message } from 'xt-design'
import to from 'await-to-js'
import qs from 'qs'
import { isObject, isString } from 'lodash-es'
import { useUserAgentState } from './userAgent'
import { XSRF_COOKIE } from 'constants/api'
import { UserPermissionsProps, logout } from 'apis/user'
import { ErrorResponseProps } from 'types/api'
import { LOGIN, REGISTER } from 'constants/router'
import { UserProfileProps } from 'types/user.d'

export enum LoginStateEnum {
  /**
   * 未登录
   * @type {LoginStateEnum.NOT_LOGIN}
   */
  NOT_LOGIN = -1,
  /**
   * 登录中
   * @type {LoginStateEnum.LOADING}
   */
  LOADING,
  /**
   * 登录成功
   * @type {LoginStateEnum.SUCCESS}
   */
  SUCCESS,
}

interface AuthDispatcherContextProps {
  /**
   * 默认跳转登录页
   * @param {boolean} modal 是否跳转页面
   */
  goLogin: (options?: UrlFormatOptionProps) => void
  /**
   * 去注册
   */
  goRegister: (options?: UrlFormatOptionProps) => void

  /***
   * 去退出
   * 删除session 清楚登录信息
   */
  goLogout: () => void

  /**
   * 改变登录态
   */
  changeLoginState: (state: LoginStateEnum) => void
  /**
   * 保存用户信息
   */
  saveProfile: (profile: UserProfileProps) => void
  /**
   * 保存用户权限
   */
  savePermission: (profile: UserPermissionsProps) => void
  /**
   * 清除用户信息
   */
  reset: () => void
}

interface AuthStateContextProps {
  /**
   * 用户信息
   */
  profile?: UserProfileProps
  /**
   * 用户权限
   */
  permission?: UserPermissionsProps
  /**
   * 登录态
   */
  loginState: LoginStateEnum
}

const INIT_ERROR_MESSAGE = '未成功初始化,请检查AuthProvider'

const DEFAULT_STATE: AuthStateContextProps = {
  loginState: LoginStateEnum.LOADING,
}

const DEFAULT_ACTIONS: AuthDispatcherContextProps = {
  goLogin: () => { console.error(INIT_ERROR_MESSAGE) },
  goRegister: () => { console.error(INIT_ERROR_MESSAGE) },
  goLogout: () => { console.error(INIT_ERROR_MESSAGE) },
  changeLoginState: (state) => { console.error(INIT_ERROR_MESSAGE) },
  saveProfile: (profile) => { console.error(INIT_ERROR_MESSAGE) },
  savePermission: () => { console.error(INIT_ERROR_MESSAGE) },
  reset: () => { console.error(INIT_ERROR_MESSAGE) },
}

/**
 * context拆分，参考下面的链接
 * @link https://juejin.cn/post/6844904098114830350#heading-3
 * @desc 1、在cookie中存储角色
 */

/**
 * context写入
 * @deprecated 不建议直接导出使用 推荐使用 useAuthDispatch
 */
export const AuthDispatcherContext = createContext<AuthDispatcherContextProps>(DEFAULT_ACTIONS)

/**
 * context读取
 * @deprecated 不建议直接导出使用 推荐使用 useAuthState
 */
export const AuthStateContext = createContext<AuthStateContextProps>(DEFAULT_STATE)

export function useAuthState() {
  const context = React.useContext(AuthStateContext)
  if (context === undefined)
    return DEFAULT_STATE

  return context
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatcherContext)
  if (context === undefined)
    return DEFAULT_ACTIONS

  return context
}

export interface UrlFormatOptionProps {
  isReplace?: boolean
  search?: string | Record<string, string | number | null | undefined>
}

const getAddSearch = (isMobile?: boolean) => {
  const query = qs.parse(window?.location.search, { ignoreQueryPrefix: true }) as any
  const {
    type,
    id,
    searchURL,
  } = query

  const extra = id ? { // 仅有
    source: `sq${type === 'course' ? 'kc' : 'nr'}share${isMobile ? 'app' : 'pc'}`,
    [`${type === 'course' ? 'course' : 'news'}Id`]: id,
  } : undefined

  return {
    ...query,
    ...extra,
    searchURL,
  }
}

/**
 * 格式化本页面跳转的地址
 * @param pathname 要去的地址
 * @param options
 */
export const urlFormat = (pathname: string, options?: UrlFormatOptionProps & { isMobile?: boolean }) => {
  const { search, isMobile } = options || {}
  const url = qs.stringify({
    ...(isString(search) && !!search ? qs.parse(search, { ignoreQueryPrefix: true }) : isObject(search) ? search : {}),
    ...getAddSearch(isMobile),
    campaign: 'foreign',
    site: window?.location?.href,
  },
  {})
  return `${pathname}?${url}`
}

const AuthProvider = ({ children }: ProviderProps) => {
  const { isMobile } = useUserAgentState()
  const [loginState, setLoginState] = useState<LoginStateEnum>(LoginStateEnum.LOADING)

  const [profile, saveProfile] = useState<UserProfileProps | undefined>(() => {
    const StrUserProfile = getCookie('UserProfile') as string
    let initUserProfile
    try {
      if (StrUserProfile)
        initUserProfile = JSON.parse(StrUserProfile)
    }
    catch (error) {
      console.error('getCookie(UserProfile) is null', error)
    }
    return initUserProfile
  })

  const [permission, savePermission] = useState<UserPermissionsProps | undefined>()

  const goLogin = useCallback((options?: UrlFormatOptionProps) => {
    const url = urlFormat(LOGIN, { ...options, isMobile: Boolean(isMobile) })

    options?.isReplace
      ? window.location.replace(url)
      : window.location.href = url
  }, [isMobile])

  const goRegister = useCallback((options?: UrlFormatOptionProps) => {
    const url = urlFormat(REGISTER, { ...options, isMobile: Boolean(isMobile) })

    options?.isReplace
      ? window.location.replace(url)
      : window.location.href = url
  }, [isMobile])

  const changeLoginState = useCallback((state: LoginStateEnum) => {
    setLoginState(state)
  }, [])

  const reset = useCallback(() => {
    deleteCookie('UserProfile')
    setLoginState(LoginStateEnum.NOT_LOGIN)
  }, [])

  const goLogout = useCallback(async (options?: UrlFormatOptionProps) => {
    const url = urlFormat(LOGIN, options)
    const [err] = await to<void, ErrorResponseProps<any>>(logout())
    if (err) {
      // @ts-expect-error
      Message.error(err?.message || err.errMsg || '')
      return
    }
    reset()
    deleteCookie(XSRF_COOKIE, { path: '/' })
    window.location.href = url
  }, [reset])

  return (
    <AuthDispatcherContext.Provider value={{
      goLogin,
      goRegister,
      goLogout,
      changeLoginState,
      saveProfile,
      savePermission,
      reset,
    }}>
      <AuthStateContext.Provider value={{
        loginState,
        profile,
        permission,
      }}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatcherContext.Provider>
  )
}

export default AuthProvider

