import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import { Volume } from "./Volume";
import { IfElse } from "components";
import { PauseOutlinedIcon, PlayingOutlinedIcon } from "components/SvgComponents";
import { set, throttle } from "lodash-es";
import { LoadingOutlined } from '@ant-design/icons';
import { useInView } from "react-intersection-observer";
import dynamic from "next/dynamic";
import ReactPlayer from "react-player";
const VideoPlayer = dynamic(() => import('./VideoPlayer'), { ssr: false });

interface IProps {
  url: string;
  urlH5?: string; // 这个属性主要为了临时解决 H5 视频播放缓慢问题
  className?: string;
  autoPlaying?: boolean;
  style?: React.CSSProperties;
}

export interface IXTVideoRef {
  start: () => void;
  pause: () => void;
  stop: () => void;
  setVolume: (voice: number) => void;
}
/**
 * video 行为策略
 * H5:
  * 1.不要进度条
  * 2.声音icon一直存在，默认为静音
  * 3.当视频播放中：
  *  ꔷ 无任何ICON在播放中的视频上ꔷ 点击行为后叠加一个20%透明度黑色蒙层，并展示（渐变出现不要卡的一下出现）播放按钮。
 *  4.当视频暂停播放状态下：ꔷ 蒙层和播放按钮一直存在，ꔷ 点击行为后暂停按钮以及蒙层消失，视频开始播放；
 * PC:
 *  1.不要进度条
 *  2.默认为静音
 *  3.当视频播放中：
 *    ꔷ 无任何ICON在播放中的视频上；
 *    ꔷ Hover行为后叠加一个20%透明度黑色蒙层，并展示（渐变出现不要卡的一下出现）暂停按钮和“声音关”按钮；
 *    ꔷ 点击行为后20%透明度黑色蒙层保持不变，并一直展示播放按钮和“声音关”按钮；
 *  4.当视频暂停播放状态下：
 *    ꔷ 蒙层和播放按钮一直存在，ꔷ 点击行为后暂停按钮以及蒙层消失，视频开始播放
 */

export const XTVideo: React.FC<IProps & { ref?: React.Ref<IXTVideoRef> }> =  forwardRef((props, ref) => {
  const { url, className, autoPlaying = false, style } = props;
  const { ref: viewRef, inView } = useInView({
    delay: 100,
    triggerOnce: true
  });

  const playerRef = useRef<typeof ReactPlayer>(null);
  const [volume, setVolume] = useState(0.5);
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(false); // 控制播放状态
  const [visible, setVisible] = useState(false); // 控制用户操作触发的遮罩状态
  const visibleTimer = useRef<any>(null);
  const [buffing, setBuffing] = useState(false); // 加载中
  const [ready, setReady] = useState(false); // 视频是否准备好
  useImperativeHandle(
    ref,
    () => ({
      start: () => {
        setPlaying(true);
      },
      pause: () => {},
      stop: () => {},
      setVolume: (volume: number) => {
        setVolume(volume);
      },
    }),
    []
  );

  // 进入可视区域  开始播放
  useEffect(() => {
    if(inView) {
      setPlaying(true);
    }
  }, [inView])

  const onMouseMove = throttle(() => {
    // 鼠标移动，触发 modal 显示
    onVisibleTrue();
  }, 500);

  // 无动作时，3秒后隐藏
  const onVisibleTrue = throttle(() => {
    setVisible(true);
    clearTimeout(visibleTimer.current);
    visibleTimer.current = setTimeout(() => {
      setVisible(false);
      clearTimeout(visibleTimer.current);
    }, 1500);
  }, 100);

  const onClick = () => {
    onVisibleTrue();
  };

  return (
    <div
      className={classNames(styles.video, className)}
      style={style}
      ref={viewRef}
      onMouseMove={onMouseMove}
      onClick={onClick}
    >
      <VideoPlayer
        playerRef={playerRef}
        className={styles["video-player"]}
        url={url}
        width={"100%"}
        height={"100%"}
        muted={muted}
        volume={volume}
        onBuffer={() => setBuffing(true)}
        onBufferEnd={() => setBuffing(false)} // todo add buffing 状态
        onEnded={() => setPlaying(false)}
        onReady={() => setReady(true)}
        playing={playing}
        playsinline={true}
      />
      <IfElse if={!ready || (!(visible || !playing) && buffing)}>
        <div className={styles.buffing}>
          <LoadingOutlined />
        </div>
      </IfElse>
      <IfElse if={ready}>
        <div className={classNames(styles.volume, "hidden-mobile")}>
          <Volume
            volume={volume}
            muted={muted}
            onMutedChange={(val) => {
              setMuted(val);
              onVisibleTrue();
            }}
            onVolumeChange={(val) => {
              onVisibleTrue();
              setVolume(val);
            }}
          />
        </div>
        <IfElse if={visible || !playing}>
          <div className={classNames(styles.volume, "hidden-pc")}>
            <Volume
              volume={volume}
              muted={muted}
              onMutedChange={(val) => {
                setMuted(val);
                onVisibleTrue();
              }}
              onVolumeChange={(val) => {
                onVisibleTrue();
                setVolume(val);
              }}
            />
          </div>
        </IfElse>
        <div
          className={classNames({
            [styles.modal]: true,
            [styles["modal-hidden"]]: !(visible || !playing),
          })}
        >
          <div
            className={classNames({
              [styles.trigger]: true,
            })}
            onClick={(e) => {
              e.stopPropagation();
              setPlaying(!playing);
              setVisible(false);
            }}
          >
            <IfElse if={!playing} else={<PauseOutlinedIcon />}>
              <PlayingOutlinedIcon />
            </IfElse>
          </div>
        </div>
      </IfElse>
    </div>
  );
});
