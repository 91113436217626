import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface ViceTitleProps {
  title: string | React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const ViceTitle = (props: ViceTitleProps) => {
  const { className, style } = props;
  return <div className={classNames(styles.root, className)} style={style}>{props.title}</div>
}

export default ViceTitle;
