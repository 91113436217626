import { AppContext } from 'next/app'
import { LanguageSiteMap } from 'constants/area'
import * as process from 'process';
import { VALID_PRE_HOSTNAME, VALID_PROD_HOSTNAME } from 'constants/common';

export const isBrowser = !!(typeof window !== 'undefined' && window.document && window.document.createElement)
export const isServer = typeof process !== 'undefined' && !!process.release && process.release.name === 'node'

/**
 * 判断是否是微信浏览器
 * @returns {boolean}
 */
export const isWechat = () => {
  if (typeof window === 'object') {
    const ua = window.navigator.userAgent.toLowerCase()
    return !!ua.match(/MicroMessenger/i)
  }
  return true
}

export const getIsMobile = (context: AppContext) => {
  const { headers = {} } = context.ctx.req || {}
  return /mobile|android|iphone|ipad|phone/i.test(
    (headers['user-agent'] || ''),
  )
}

/**
 * 是否是美国站
 * @param host 主域名
 * @returns 是否是美国站
 */
export const getIsUS = (host: string = '') => {
  return (/^(us|pre-us)/).test(host);
}

/**
 *
 * @param pathName 路由路径
 * @returns 是否是落地页
 */
export const getIsLanding = (pathName: string = "") => {
  const pathNameArr = pathName.split("?")[0].split("/");
  const landing = "landing";
  const landing2 = "landing2";
  const isLanding = pathNameArr.includes(landing) && (pathNameArr[1] === landing || pathNameArr[2] === landing);
  const isLanding2 = pathNameArr.includes(landing2) && (pathNameArr[1] === landing2 || pathNameArr[2] === landing2);
  return isLanding || isLanding2;
};

/**
 * 站点_语言编码 例如：hk_zh-hk
 * 站点编码：香港：hk   美国：us
 * 语言编码：繁体中文：zh-hk  英文:en
 * 只需要判断是否是香港 美国 后续新增站点需要完善
 * @param {Object} params
 * @param {string} [params.host] 域名
 * @param {string} [params.language] 语言
 * @returns 站点_语言编码
 */
export const getSiteCode = ({ host, language }: {host?: string, language?: string}) => {
  const site = []
  const languageKey = (language || '').toLowerCase()
  if (getIsUS(host)) {
    site.push('us')
    site.push(LanguageSiteMap[languageKey] ?? 'en')
  } else {
    site.push('hk')
    site.push(LanguageSiteMap[languageKey] ?? 'zh-hk')
  }

  return site.join('_')
}

/**
 * 根据host获取对应的站点hosts
 * host 可以在服务端获取或者在客户端获取
 */
export const getHostsByHost = (host = 'www.xtransfer.com') => {
  if (host.includes('.xtrfr.cn')) {// 测试环境

    // 匹配 pre-xxx.xtrfr.cn
    const [, , ns] = host.match(/(-=?)(\S*)(?=.xtrfr.cn)/) || []
    // 匹配 xxx.xtrfr.cn
    const [_ns] = host.match(/(\S*)(?=.xtrfr.cn)/) || []

    // 优先取当前host的域名
    const hotsName = ns || _ns
    return {
      hk: `hk-${hotsName}.xtrfr.cn`,
      uk: `uk-${hotsName}.xtrfr.cn`,
      us: `us-${hotsName}.xtrfr.cn`,
      official: `${hotsName}.xtrfr.cn`,
    }
  } else if (host.startsWith('pre')) {// 预发
    return {
      hk: `www.xtransfer.com`,
      uk: `pre-uk.xtransfer.com`,
      us: `pre-us.xtransfer.com`,
      official: `www.xtransfer.cn`,
    }
  } else {// 线上
    return {
      hk: `www.xtransfer.com`,
      uk: `uk.xtransfer.com`,
      us: `us.xtransfer.com`,
      official: `www.xtransfer.cn`,
    }
  }
}

/**
 * 浏览器环境下生产环境
 */
export const getIsProdOfBrowser = () => {
  if (!isBrowser) return false

  return VALID_PROD_HOSTNAME?.includes(window?.location?.hostname)
}

/**
 * 浏览器环境下预发环境
 */
export const getIsPreOfBrowser = () => {
  if (!isBrowser) return false

  return VALID_PRE_HOSTNAME?.includes(window?.location?.hostname)
}

// 获取对应的大陆域名
export const getZhHost = (host = 'www.xtransfer.com') => {
  if (host.includes('.xtrfr.cn')) {// 测试环境
    return host.split('-').pop()
  } else if (host.startsWith('pre')) {// 预发
    return 'pre.xtransfer.cn'
  } else {// 线上
    return 'www.xtransfer.cn'
  }
}
