import { SitesEnum } from "constants/regions";

/**
 * @param route 当前路由情况 {asPath:当前路径, toPath:跳转的路径}
 * asPath: 当前路径
 * toPath: 目标路径
 * @returns
 */
export const getPathWithQuery = (route: { asPath: string; toPath: string }) => {
  const search = route.asPath.split("?")[1] || "";
  const pathName = route.toPath;
  const toPath = search ? (/\?/.test(pathName) ? `${pathName}&${search}` : `${pathName}?${search}`) : pathName;
  return toPath;
};

/**
 * 是不是外站点链接
 */
export const getIsOutLink = (pathname?: string) => {
  return pathname?.includes("https://");
};

/**
 * 获取带站点的路径
 */
export const getPathWithSite = (site: SitesEnum, path: string) => {
  const pathSegment = site === SitesEnum.GLOBAL ? "" : (site as string);
  return pathSegment ? `/${pathSegment}${path}` : path;
};
