import React, { useEffect } from "react";
import { Select } from "xt-design";
import { useTranslation } from "hooks";
import styles from "./index.module.scss";
import { LocationType, LocationTypeOptions } from "constants/area";
import { LocationFilterLabel, trackError } from "utils";
import { IfElse } from "components";
import { useQuery } from "@tanstack/react-query";
import { getDialingCode } from "apis/user";
import { useRouter } from "next/router";
import classNames from "classnames";
import { sensor } from "../..";

const { Option } = Select;

interface IProps {
  onChange?: (val: { type?: string; area?: string; number?: string }) => void;
  value?: string;
}

const LocationSelect: React.FC<IProps> = (props) => {
  const { onChange = () => {} } = props;
  const { t } = useTranslation();
  const { locale } = useRouter();
  const isLangHk = locale === "zh-HK" || locale === "zh-TW" || locale === "zh";
  const isLangZh = locale === "zh";
  const [_value, setValue] = React.useState<{
    type?: string;
    area?: string;
    number?: string;
  }>({});

  const onSelectChange = (val: string) => {
    sensor("Contact Location", "change");
    setValue({
      ..._value,
      type: val,
      area: "",
    });
  };

  const onAreaChange = (val: string) => {
    setValue({
      ..._value,
      area: val,
    });
  };

  const { data } = useQuery(["dialing-code"], () => getDialingCode(), {
    onSettled: (res, error) => {
      if (error) trackError(error);
      else if (!(Array.isArray(res) && res.length > 0)) {
        trackError(new Error("区号数据为空，请检查后端日志和arms日志"));
      }
    },
  });

  useEffect(() => {
    onChange(_value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value]);
  return (
    <div className={classNames(styles.root, styles.input)}>
      <Select size="large" placeholder={t("please-select")} onChange={onSelectChange} dropdownStyle={{ padding: "var(--xt-padding-8)" }}>
        {LocationTypeOptions.map((item) => {
          return (
            <Select.Option key={item.value} value={item.value} className={styles.options}>
              <span style={{ marginLeft: "var(--xt-margin-8)" }}>{isLangZh ? item.zhLabel : isLangHk ? item.hkLabel : item.label}</span>
            </Select.Option>
          );
        })}
      </Select>
      <IfElse if={_value.type === LocationType.Other}>
        <Select
          size="large"
          value={_value.area}
          placeholder={t("please-select")}
          onChange={onAreaChange}
          options={LocationFilterLabel(data || [], locale)}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        />
      </IfElse>
    </div>
  );
};

export default LocationSelect;
