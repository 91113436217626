import React, { ComponentProps } from 'react';
import Icon from '@ant-design/icons';

import EmailOutlinedSvg from './email-outlined.svg';
import MediaOutlinedSvg from './media-outlined.svg';
import PhoneOutlinedSvg from './phone-outlined.svg';
import DirectionOutlinedSvg from './direction-outlined.svg';
import LearnMoreOutlinedSvg from './learn-more-outlined.svg';
import EmptyOutlinedSvg from './empty-outlined.svg';
import VolumeOpenOutlinedSvg from './volume-open-outlined.svg';
import VolumeCloseOutlinedSvg from './volume-close-outlined.svg';
import PauseOutlinedSvg from './pause-outlined.svg';
import PlayingOutlinedSvg from './playing-outlined.svg';

import MilestoneFilled from './milestone-filled.svg';
import ChannelPartnerFilled from './channel-partner-filled.svg';
import LeadsAffiliateFilled from './leads-affiliate-filled.svg';
import MoreModelFilled from './more-model-filled.svg';
import ReceiveMoneyFilled from './receive-money-filled.svg';
import SendMoneyFilled from './send-money-filled.svg';
import AccountFilled from './account-filled.svg';
import CourageToPioneerFilled from './courage-to-pioneer-filled.svg';
import CustomerFirstFilled from './customer-first-filled.svg';
import StriveForPerfectionFilled from './strive-for-perfection-filled.svg';
import TeamworkFilled from './team-work-filled.svg';
import RightFilled from './rightFilled.svg';
import LeftFilled from './leftFilled.svg';
import CheckFilled from './check-filled.svg';
import CheckoutBlueFilled from './check-filled-blue.svg';

const EmailOutlinedIcon = (props: ComponentProps<typeof Icon>) => <Icon component={EmailOutlinedSvg} {...props} />;
const MediaOutlinedIcon = (props: ComponentProps<typeof Icon>) => <Icon component={MediaOutlinedSvg} {...props} />;
const PhoneOutlinedIcon = (props: ComponentProps<typeof Icon>) => <Icon component={PhoneOutlinedSvg} {...props} />;
const DirectionOutlinedIcon = (props: ComponentProps<typeof Icon>) => <Icon component={DirectionOutlinedSvg} {...props} />;
const LearnMoreOutlinedIcon = (props: ComponentProps<typeof Icon>) => <Icon component={LearnMoreOutlinedSvg} {...props} />;
const EmptyOutlinedIcon = (props: ComponentProps<typeof Icon>) => <Icon component={EmptyOutlinedSvg} {...props} />;
const VolumeOpenOutlinedIcon = (props: ComponentProps<typeof Icon>) => <Icon component={VolumeOpenOutlinedSvg} {...props} />;
const VolumeCloseOutlinedIcon = (props: ComponentProps<typeof Icon>) => <Icon component={VolumeCloseOutlinedSvg} {...props} />;
const PauseOutlinedIcon = (props: ComponentProps<typeof Icon>) => <Icon component={PauseOutlinedSvg} {...props} />;
const PlayingOutlinedIcon = (props: ComponentProps<typeof Icon>) => <Icon component={PlayingOutlinedSvg} {...props} />;

const MilestoneFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={MilestoneFilled} {...props} />;
const ChannelPartnerFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={ChannelPartnerFilled} {...props} />;
const LeadsAffiliateFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={LeadsAffiliateFilled} {...props} />;
const MoreModelFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={MoreModelFilled} {...props} />;
const ReceiveMoneyFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={ReceiveMoneyFilled} {...props} />;
const SendMoneyFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={SendMoneyFilled} {...props} />;
const AccountFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={AccountFilled} {...props} />;
const CourageToPioneerFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={CourageToPioneerFilled} {...props} />;
const CustomerFirstFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={CustomerFirstFilled} {...props} />;
const StriveForPerfectionFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={StriveForPerfectionFilled} {...props} />;
const TeamworkFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={TeamworkFilled} {...props} />;
const RightFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={RightFilled} {...props} />;
const LeftFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={LeftFilled} {...props} />;
const CheckFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={CheckFilled} {...props} />;
const CheckoutBlueFilledIcon = (props: ComponentProps<typeof Icon>) => <Icon component={CheckoutBlueFilled} {...props} />;

export {
  EmailOutlinedIcon,
  MediaOutlinedIcon,
  PhoneOutlinedIcon,
  DirectionOutlinedIcon,
  LearnMoreOutlinedIcon,
  EmptyOutlinedIcon,
  VolumeOpenOutlinedIcon,
  VolumeCloseOutlinedIcon,
  PauseOutlinedIcon,
  PlayingOutlinedIcon,

  MilestoneFilledIcon,
  ChannelPartnerFilledIcon,
  LeadsAffiliateFilledIcon,
  MoreModelFilledIcon,
  ReceiveMoneyFilledIcon,
  SendMoneyFilledIcon,
  AccountFilledIcon,
  CourageToPioneerFilledIcon,
  CustomerFirstFilledIcon,
  StriveForPerfectionFilledIcon,
  TeamworkFilledIcon,
  RightFilledIcon,
  LeftFilledIcon,
  CheckFilledIcon,
  CheckoutBlueFilledIcon
}