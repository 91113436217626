import React, { ComponentProps } from 'react';
import Icon from '@ant-design/icons';

import ChinaSvg from './china.svg';
import HongkongSvg from './hongkong.svg';
import UsSvg from './us.svg';
import UkSvg from './uk.svg';
import SingaporeSvg from './singapore.svg';
import JapanSvg from './japan.svg';
import CanadaSvg from './canada.svg';
import AustraliaSvg from './australia.svg';
import Netherlands from './netherlands.svg';

const ChinaIcon = (props: ComponentProps<typeof Icon>) => <Icon component={ChinaSvg} {...props} />;
const HongkongIcon = (props: ComponentProps<typeof Icon>) => <Icon component={HongkongSvg} {...props} />;
const UsIcon = (props: ComponentProps<typeof Icon>) => <Icon component={UsSvg} {...props} />;
const UkIcon = (props: ComponentProps<typeof Icon>) => <Icon component={UkSvg} {...props} />;
const SingaporeIcon = (props: ComponentProps<typeof Icon>) => <Icon component={SingaporeSvg} {...props} />;
const JapanIcon = (props: ComponentProps<typeof Icon>) => <Icon component={JapanSvg} {...props} />;
const CanadaIcon = (props: ComponentProps<typeof Icon>) => <Icon component={CanadaSvg} {...props} />;
const AustraliaIcon = (props: ComponentProps<typeof Icon>) => <Icon component={AustraliaSvg} {...props} />;
const NetherlandsIcon = (props: ComponentProps<typeof Icon>) => <Icon component={Netherlands} {...props} />;


export {
  ChinaIcon,
  HongkongIcon,
  UsIcon,
  UkIcon,
  SingaporeIcon,
  JapanIcon,
  CanadaIcon,
  AustraliaIcon,
  NetherlandsIcon
}