import React, { createContext, useEffect, useState } from 'react'
import { ThemesEnum } from 'constants/enum'

interface IThemeContextProps {
  theme: ThemesEnum
  setTheme: (theme: ThemesEnum) => void
}

export const ThemeContext = createContext<IThemeContextProps>(
  {} as IThemeContextProps,
)

const ThemeContextProvider = ({ children }: ProviderProps) => {
  const [theme, setTheme] = useState<ThemesEnum>(ThemesEnum.light)

  // 监听本地缓存来同步不同页面间的主题
  useEffect(() => {
    const checkTheme = (): void => {
      const item = (localStorage.getItem('theme') as ThemesEnum) || ThemesEnum.light
      setTheme(item)
      const nodes = document.getElementsByTagName('html')
      if (Array.isArray(nodes) && nodes.length > 1 && nodes[0]?.dataset?.theme)
        nodes[0].dataset.theme = item
    }
    checkTheme()
    window.addEventListener('storage', checkTheme)
    return (): void => {
      window.removeEventListener('storage', checkTheme)
    }
  }, [])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme: (currentTheme) => {
          setTheme(currentTheme)
          localStorage.setItem('theme', currentTheme)
          document.getElementsByTagName('html')[0].dataset.theme = currentTheme
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
export default ThemeContextProvider
