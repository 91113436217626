import { isError } from "lodash-es";
import * as Sentry from "@sentry/nextjs";
import { isBrowser } from "./platform";
import { captureException } from "@sentry/nextjs";

interface ErrorOptionProps {
  filename: string;
  params?: { [key: string]: any };
}

function getError(error: unknown, options: ErrorOptionProps) {
  if (error) {
    // 不建议这种方式，错误栈不正确
    if (typeof error === "string") {
      return new Error(error);
    }

    // nacos请求场景
    if (typeof error === "object" && "status" in error && "message" in error) {
      return new Error(
        `api or function：${options.filename}，params：${JSON.stringify(options.params || {})}，message：${JSON.stringify(
          (error as any).message
        )}，status：${(error as any).status}`
      );
    }

    // 标准Error场景
    if (isError(error)) {
      return error;
    }
  }

  // 兜底
  return new Error(`trackError：api or function：${options.filename}，params：${JSON.stringify(options.params || {})}`);
}

export enum ErrorLevel {
  p1 = "p1",
  p2 = "p2",
}
/**
 * 定位console.error
 */
const trackError = (error: Error | string | unknown, options: ErrorOptionProps, level?: ErrorLevel) => {
  const _err = getError(error, options);

  // sentry上报
  const { filename, params } = options || {};
  Sentry.captureException(_err, (scope) => {
    scope.setExtra("params", params); // 上报额外参数
    scope.setTags({
      feature: filename,
      errorLevel: level || ErrorLevel.p2, // 必需，标记错误等级，可选值 p1、p2
    });
    return scope;
  });

  // arms上报
  if (isBrowser) {
    process.env.NODE_ENV === "production" && window.__bl?.error(_err, options);
  }
};

// 错误分类
export const enum errorType {
  LOGIN_ERROR = "LOGIN_ERROR",
  BUSINESS_ERROR = "BUSINESS_ERROR",
  NOTFOUND_ERROR = "NOTFOUND_ERROR",
  RENDER_ERROR = "RENDER_ERROR",
  NOTFUND_ERROR = "NOTFUND_ERROR",
}

// 业务类型
export const enum businessType {
  FRONTEND_ERROR = "FRONTEND_ERROR",
  BACKEND_ERROR = "BACKEND_ERROR",
  NETWORK_ERROR = "NETWORK_ERROR",
  THIRD_PARTY_SERVICE_ERROR = "THIRD_PARTY_SERVICE_ERROR",
}

// 错误等级
export const enum errorLevel {
  P1 = "P1",
  P2 = "P2",
  P3 = "P3",
}

export interface ErrorParams {
  errorType: string;
  errorLevel: string;
  businessType: string;
  extraTags?: Record<string, string>;
  extras?: Record<string, any>;
}

/**
 *
 * @param errorMsg 错误信息，必须是Error对象
 * @param errorType 错误类别
 * @param errorLevel 错误等级
 * @param businessType 业务类别
 * @param extraTags 额外标签
 * @param extras 额外信息
 */
export const reportError = (errorMsg: Error, { errorType, errorLevel, businessType, extraTags = {}, extras = {} }: ErrorParams) => {
  captureException(errorMsg, (scope) => {
    scope.setTags({
      errorType,
      businessType,
      errorLevel,
      ...extraTags,
    });
    scope.setExtras(extras);
    return scope;
  });
};

/**
 *
 * @param name 打点的名称
 */

export const commonADTrack = ({ name }: { name: string }) => {
  window?.fbq?.("track", name);
  window?.dataLayer?.push({
    event: name,
  });
};

export { trackError };
