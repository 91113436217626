import React from 'react';

export type IfElseProps = React.PropsWithChildren<{
  /**
   *       显示条件
   * @default
   */
  if: boolean;
  /**
   *       当显示条件不满足时显示
   * @default
   */
  else?: JSX.Element;
  /**
   *       当显示条件不满足时显示
   * @default
   */
  children?: any;
}>;

const IfElse: React.FC<IfElseProps> = (props: IfElseProps) => {
  const { children } = props;
  const ifCondition = props.if;
  const elseElement = props.else;
  return ifCondition ? <>{children}</> : elseElement || null;
};

export default IfElse;
