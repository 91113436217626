import { getIsPreOfBrowser, getIsProdOfBrowser, isBrowser } from "utils/platform";

// 不添加函数名会导致eslint自动格式化在前面添加分号
export const sensorsUtil = async () => {
  if (!isBrowser) return;

  const isProd = getIsProdOfBrowser();
  const isPre = getIsPreOfBrowser();
  const sensors = (await import("sa-sdk-javascript")) || {};
  const serve_node = isProd || isPre ? "production" : "default";

  sensors.init({
    // 项目数据接收地址, 目前是测试环境
    server_url: `https://sensorsdata.xtadmins.com/sa?project=${serve_node}`,
    is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: true,
    // 非生产环境不打印log
    // show_log: process.env.NODE_ENV !== 'production',
    send_type: "beacon",
    heatmap: {
      // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: "default",
      // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: "default",
    },
  });
  sensors.registerPage({
    platform_code: "PC",
    page_path: () => {
      return isBrowser ? window?.location?.pathname : "";
    },
    customer_id: "",
    firm_id: "",
    customer_type: "XT对客业务线",
    page_name: () => {
      return isBrowser ? window.location?.pathname : "";
    },
    page_url: () => {
      return isBrowser ? window.location?.href : "";
    },
    signup_number: "",
    account_id: "",
    // 神策自动解析路由中的参数
    source_channel: ["businessSource", "introduceSource", "serviceCode", "campaign", "source", "code"],
  });
  sensors.quick("autoTrack"); // 用于采集 $pageview 事件。
};

export * from "./sensorUtils";
