import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { useUpdate } from "ahooks";
import { FreeMode, Autoplay, EffectFade } from "swiper/modules";
import SwiperCard from "../AboutUsComponents/CompanyComponents/Milestones/SwiperCard";
import { get } from "lodash-es";
import classNames from "classnames";
import styles from "./index.module.scss";
import { SwiperPagination } from "components/SwiperPagination";
import { IfElse } from "components";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { LeftFilledIcon, RightFilledIcon } from "components/SvgComponents";

interface Props {
  items: any[];
  children?: React.ReactNode;
  centered?: boolean;
  previewCount?: number;
  style?: React.CSSProperties;
  className?: string;
  delay?: number;
  spaceBetween?: number | string;
  /**
   * 暂时为了区分不同的分页器的模式
   */
  mode?: "mobile" | "pc";
  fade?: boolean;
  /**
   * 是否显示分页
   */
  showPagination?: boolean;
  auto?: boolean;
  slidesPerGroup?: number;
}

const PREVIEW_COUNT = 2;

// mode 为 mobile 时，不可 loop
export const XTSwiper = (props: Props) => {
  const {
    children,
    spaceBetween = 30,
    fade = false,
    delay = 2500,
    showPagination = true,
    centered = true,
    auto = true,
    mode,
    className,
    style,
    previewCount = PREVIEW_COUNT,
    items = [],
    slidesPerGroup = 1,
  } = props;
  const swiperRef = useRef<any>(null);
  const [slider, setSlider] = useState({
    prev: false,
    next: true,
  });
  const update = useUpdate();

  const onSlideChange = () => {
    const swiper = swiperRef.current;
    const prev = !!swiper.realIndex;
    const next = !!(swiper.realIndex < items.length - previewCount);
    setSlider({ prev, next });
  };

  const modules = auto ? [FreeMode, Autoplay, EffectFade] : [FreeMode, EffectFade];
  return (
    <div className={classNames(styles.root, className)} style={style}>
      <Swiper
        ref={swiperRef}
        slidesPerView={previewCount}
        spaceBetween={spaceBetween}
        onSlideChange={() => {
          update();
          onSlideChange();
        }}
        effect={fade ? "fade" : "slide"}
        loop={mode === "mobile" ? true : false}
        slidesPerGroup={slidesPerGroup}
        autoplay={{
          delay: delay,
          disableOnInteraction: false,
        }}
        // 只有在 mobile 模式下才需要居中
        centeredSlides={centered && mode === "mobile" ? true : false}
        onSwiper={(swiper: SwiperClass) => {
          swiperRef.current = swiper; // 因为 useSwiper 使用时，无法正确执行方法，所以这里使用 ref
          onSlideChange();
        }}
        className={styles.swiper}
        modules={modules}
      >
        {children
          ? children
          : items.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <SwiperCard data={item} />
                </SwiperSlide>
              );
            })}
      </Swiper>
      <IfElse if={showPagination}>
        <IfElse
          if={mode === "mobile"}
          else={
            <>
              <div
                onClick={() => swiperRef.current.slidePrev()}
                className={classNames(styles.navigation, styles["left-navigation"], {
                  [styles["navigation-disabled"]]: !slider.prev,
                })}
              >
                <LeftFilledIcon />
              </div>
              <div
                onClick={() => swiperRef.current.slideNext()}
                className={classNames(styles.navigation, styles["right-navigation"], {
                  [styles["navigation-disabled"]]: !slider.next,
                })}
              >
                <RightFilledIcon />
              </div>
            </>
          }
        >
          <SwiperPagination
            length={items.length + (centered ? 2 - previewCount : 0)}
            activeIndex={get(swiperRef, "current.realIndex", 0)}
            onClick={(index) => {
              swiperRef.current?.slideTo(index);
              update();
            }}
          />
        </IfElse>
      </IfElse>
    </div>
  );
};
