import { Get } from "utils/api";

export interface TotalRewardAmountRes {
  groupRewardVOList: Array<{
    currency: string;
    rewardAmount: number;
  }>;
}

export interface TotalRewardAmountReq {
  activityId: string;
}
/**
 * 获取活动状态（首页banner）
 * @returns
 */
export function getTotalRewardAmount(params: TotalRewardAmountReq) {
  return Get<TotalRewardAmountRes>("/marketing/activity/query/activity/total-reward-amount", params);
}

export function getBankList() {
  return Get<any>("/module-content/official?moduleName=PC_TRANSFER_BOOST_INTRODUCE");
}
