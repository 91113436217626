import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  activeIndex: number;
  length: number;
  onClick?: (index: number) => void;
}

/**
 * 根据设计稿实现一个 swiper 分页器
 * @param props
 * @returns
 */
export const SwiperPagination = (props: Props) => {
  const { activeIndex, length, onClick = () => {} } = props;
  const pages = Array.from({ length }, (_, index) => index + 1);
  return <div className={styles.root}>
    {
      pages.map((i, index) => {
        return <div
          key={i}
          className={classNames(styles.dot, {
            [styles['active-dot']]: index === activeIndex
          })}
          onClick={() => onClick(index)}
        >
        </div>
      })
    }
  </div>
}
