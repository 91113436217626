import React from "react";
import { DefaultSeo } from "next-seo";
import { useTranslation } from "hooks";
import { getCspRulesForRender } from "../utils/csp";

const IndexSEO = () => {
  const { t } = useTranslation();

  const title: string = t("common-document-title") !== "common-document-title" ? t("common-document-title") : "貿易匯款至内地，必備XTransfer";
  const description: string =
    t("common-document-description") !== "common-document-description"
      ? t("common-document-description")
      : "XTransfer作为一站式外贸企业跨境金融和风控服务公司，为从事跨境电商B2B的中小微出口企业提供跨境收款平台服务。XTransfer提供的外贸收款方式，让中小微企业享受到和大型跨国集团企业同等水平的跨境金融服务！";
  const keywords: string =
    t("common-document-keywords") !== "common-document-keywords" ? t("common-document-keywords") : "跨境电商B2B收款平台,企业外贸收款方式,XTransfer";

  return (
    <DefaultSeo
      title={title}
      description={description}
      openGraph={{
        type: "website",
        locale: "zh-HK",
        url: "https://www.xtransfer.com/",
        siteName: "XTransfer",
      }}
      additionalMetaTags={[
        { name: "renderer", content: "webkit" },
        { property: "article:author", content: "XTransfer" },
        { property: "article:published_first", content: "XTransfer" },
        { httpEquiv: "x-ua-compatible", content: "edge;IE=edge" },
        {
          name: "viewport",
          // user-scalable=0 禁止用户缩放
          content: "width=device-width,user-scalable=no, initial-scale=1, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover",
        },
        {
          httpEquiv: "content-security-policy",
          content: getCspRulesForRender(),
        },
        {
          name: "keywords",
          content: keywords,
        },
        {
          name: "google-site-verification",
          content: "NO1ncSJhZ7vwIe9A7Oi7X8aqZuRHd2I0YkhJUdt6b4E",
        },
        {
          name: "facebook-domain-verification",
          content: "mr2npyx074fw46i8g476vxl90eozf2",
        },
      ]}
    />
  );
};
export default IndexSEO;
