import React, { useState } from "react";
import classNames from "classnames";
import { useCommonState } from "context";
import { sites } from "constants/regions";
import styles from "./index.module.scss";
import IconLocation from "components/SvgComponents/location.svg";
import IconCn from "components/SvgComponents/cn.svg";
import IconUs from "components/SvgComponents/us.svg";
import IconJp from "components/SvgComponents/jp.svg";
import IconUk from "components/SvgComponents/uk.svg";
import IconHk from "components/SvgComponents/hk.svg";

interface IRegionSelectorProps {
  open: boolean;
  close: () => void;
  beforeClick: () => void;
}
const RegionSelector: React.FC<IRegionSelectorProps> = ({ open, close, beforeClick }) => {
  const { hosts } = useCommonState();
  const { official, hk, us, uk } = hosts;

  const regions = Object.entries(sites).map(([key, value]) => {
    const { icon, alt, href, label } = value;
    return {
      key,
      Icon: (props: any) => <img {...props} src={icon} alt={alt} />,
      text: label,
      link: href,
    };
  });

  // const regions = [
  //   {
  //     text: "Global  (English)",
  //     link: `https://${hk}`,
  //     Icon: IconLocation,
  //   },
  //   {
  //     text: "中国",
  //     link: `https://${official}?lang=zh`,
  //     Icon: IconCn,
  //   },
  //   {
  //     text: "中国香港",
  //     link: `https://${hk}/zh-HK`,
  //     Icon: IconHk,
  //   },
  //   {
  //     text: "日本",
  //     link: `https://${official}?lang=ja`,
  //     Icon: IconJp,
  //   },
  //   {
  //     text: "United States",
  //     link: `https://${us}`,
  //     Icon: IconUs,
  //   },
  //   {
  //     text: "United Kingdom",
  //     link: `https://${uk}`,
  //     Icon: IconUk,
  //   },
  // ];

  const changeLanguage = (link: string) => () => {
    beforeClick();
    window.location.href = link;
  };

  if (!open) return null;
  return (
    <div className={styles.regionSelectorMask}>
      <div className={styles.regionSelectorWrapper}>
        <div className={styles.header}>
          <span className={styles.title} onClick={close}>
            Cancel
          </span>
        </div>
        <div className={styles.main}>
          <ul className={styles.regions}>
            {regions.map((item, index) => {
              const { text, link, Icon } = item;
              return (
                <li key={index} className={styles.regionItemWrapper} onClick={changeLanguage(link)}>
                  <div className={styles.regionContent}>
                    <span className={styles.iconWrapper}>
                      <Icon className={styles.icon} />
                    </span>
                    <span>{text}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.footer}>
          <div className={styles.line} />
        </div>
      </div>
    </div>
  );
};

interface LanguageDrawerProps {
  beforeClick: () => void;
}
const NewLanguageDrawer: React.FC<LanguageDrawerProps> = ({ beforeClick }) => {
  const [open, setOpen] = useState(false);

  const showRegionSelector = () => {
    setOpen(true);
  };

  const hideRegionSelector = () => {
    // 关闭
    setOpen(false);
  };

  return (
    <div className={styles.areaSelectorWrapper}>
      <div className={classNames(styles.header)} onClick={showRegionSelector}>
        <span>Please select</span>
        <span className={styles.locationIconWrapper}>
          <IconLocation className={styles.icon} />
        </span>
      </div>
      <RegionSelector open={open} close={hideRegionSelector} beforeClick={beforeClick} />
    </div>
  );
};

export default NewLanguageDrawer;
