import classNames from 'classnames';
import React from 'react';
import ReactSlider, { ReactSliderProps } from 'react-slider';
import styles from './index.module.scss';

export const Slider = (props: ReactSliderProps) => {
  return <ReactSlider
    className={classNames(styles.slider, styles['slider-vertical'], props.className)}
    thumbClassName={styles['slider-thumb']}
    trackClassName={styles['slider-trunk']}
    orientation="vertical"
    {...props}
  />
};
