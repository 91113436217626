import { useRouter } from "next/router";
import React, { Fragment, ReactElement, useCallback, useEffect } from "react";
import { useScroll } from "ahooks";
import { useTranslation } from "hooks";
import { useCommonState, useUserAgentState } from "context";
import Header, { HeaderProps } from "./header";
import Main from "./main";
import Footer from "./footer";
import MobileHeader from "./mobileHeader";
import MobileFooter from "./mobileFooter";
import CookieBanner from "components/CookieBanner";
import AdBanner from "components/AdBanner";
import { IfElse } from "components";
import {
  ACCOUNT_TERMS_OF_USE_POLICY,
  COOKIE_POLICY,
  EXCHANGE_SERVICE_AGREEMENT_POLICY,
  INTERNATIONAL_ABOUT_US,
  INTERNATIONAL_CALL_US,
  INTERNATIONAL_PRODUCTS,
  PRIVATE_POLICY,
  USER_AGREEMENT_POLICY,
} from "constants/router";
import { isBrowser } from "utils";
import { getPathWithSite } from "utils/route";
import { FooterProps } from "./types";
import { MenuItemProps } from "types/language";
import { ComponentCommonProps } from "types/reactExtra";
import { SitesEnum } from "constants/regions";

interface LayoutProps extends Omit<ComponentCommonProps, "style" | "className"> {
  title?: string;
}

const Layout = ({ children }: LayoutProps) => {
  const { t } = useTranslation();
  const { isMobile } = useUserAgentState();
  const { isZHLang, isLanding } = useCommonState();
  const { query } = useRouter();
  const { site } = query as { site: SitesEnum };

  // TODO 这个在滚动时会导致整个header一直刷新，需要抽离到单独的组件，缩小刷新范围
  const scroll = useScroll(isBrowser ? document : undefined);

  const RouteLinksHeader: MenuItemProps[] = [
    { label: t("common-products"), value: getPathWithSite(site, INTERNATIONAL_PRODUCTS) },
    { label: t("common-aboutAs"), value: getPathWithSite(site, INTERNATIONAL_ABOUT_US) },
  ];

  const copyright = (t("common-copyright") || "") as string;
  const headerData: Omit<HeaderProps, "showAd"> = {
    menus: isLanding ? [] : RouteLinksHeader,
  };

  const policies: MenuItemProps = {
    label: t("XTS-31051.pages.common-user-agreement"),
    value: getPathWithSite(site, USER_AGREEMENT_POLICY),
    rel: "noopener noreferrer nofollow",
    target: "_blank",

    children: [
      {
        label: t("XTS-31051.pages.common-private-policy"),
        value: getPathWithSite(site, PRIVATE_POLICY),
        rel: "noopener noreferrer nofollow",
        target: "_blank",
      },
      {
        label: t("XTS-31051.pages.common-cookie-policy"),
        value: getPathWithSite(site, COOKIE_POLICY),
        rel: "noopener noreferrer nofollow",
        target: "_blank",
      },
      {
        label: t("XTS-31051.pages.common-exchange-service-agreement"),
        value: getPathWithSite(site, EXCHANGE_SERVICE_AGREEMENT_POLICY),
        rel: "noopener noreferrer nofollow",
        target: "_blank",
      },
      {
        label: t("XTS-31051.pages.common-account-terms-of-use"),
        value: getPathWithSite(site, ACCOUNT_TERMS_OF_USE_POLICY),
        rel: "noopener noreferrer nofollow",
        target: "_blank",
      },
    ],
  };

  const footerData: FooterProps = {
    menus: [
      ...RouteLinksHeader,
      policies,
      {
        label: t("call-us"),
        value: getPathWithSite(site, INTERNATIONAL_CALL_US),
        rel: "noopener noreferrer nofollow",
      },
    ],
    messages: [t("common-servicePhone"), t("common-serviceTime"), t("common-email"), t("common-address")],
    links: [t("common-followUs"), t("common-FaceBook"), t("common-Linkedin"), t("common-Instagram"), t("common-Youtube"), t("common-Twitter")],
    copyright,
  };

  const changeHeaderShadow = useCallback((add?: boolean) => {
    const header = document.getElementById("international-header");
    if (header) add ? header.classList.add("xt-shadow") : header.classList.remove("xt-shadow");
  }, []);

  useEffect(() => {
    if (scroll?.top === 0) {
    }
    changeHeaderShadow((scroll?.top || 0) > 0);
  }, [changeHeaderShadow, scroll?.top]);

  return (
    <Fragment>
      {!isMobile ? (
        <Header {...headerData}>
          <IfElse if={!isZHLang}>
            <AdBanner />
          </IfElse>
        </Header>
      ) : (
        <>
          <IfElse if={!isZHLang}>
            <AdBanner />
          </IfElse>
          <MobileHeader {...headerData} />
        </>
      )}
      <Main>{children}</Main>
      {!isMobile ? <Footer {...footerData} /> : <MobileFooter {...footerData} />}
      {scroll?.top === 0 && <CookieBanner />}
    </Fragment>
  );
};

export function getNoLayout(page: ReactElement) {
  return <>{page}</>;
}

export default Layout;
