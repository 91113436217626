import { EmptyObject } from 'type-fest'
import { flatten, isEmpty, isObject } from 'lodash-es'

/**
 * 判断对象是否是 {}
 * @param obj
 * @returns {obj is EmptyObject}
 */
export function objectIsEmpty(obj: unknown): obj is EmptyObject {
  return isObject(obj) && !isEmpty(obj)
}

export function isValidURL(url: string) {
  const urlRegExp = /^((https|http|ftp|rtsp|mms)?:\/\/)+[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/
  return urlRegExp.test(url)
}

export type ListItemType<T = unknown> = {
  list?: T[]
  total?: number
} | undefined

export function getNextPageParam<T>(lastPage?: ListItemType<T>, allPages?: ListItemType<T>[]) {
  if (!allPages) return undefined

  let len = 0// 总长度
  allPages.forEach((i) => { len += (i && Array.isArray(i?.list) ? i.list.length : 0) })

  if (allPages.length > 0) {
    let total = 0
    allPages.some((i) => {
      if (i?.total && i.total > 0) {
        total = i.total
        return true
      }
      return false
    })
    return total > len ? allPages.length + 1 : undefined
  }
  return undefined
}

export function getValidData<T>(allPages?: ListItemType<T>[]) {
  return flatten(allPages?.map(i => (i && Array.isArray(i.list)) ? i.list : (Array.isArray(i) ? i : []))).filter(Boolean) as T[]
}
