import React, { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

interface TitleProps {
  style?: CSSProperties
  className?: string
  children?: ReactNode
  onlyOneRow?: boolean
}

const Desc = (props: TitleProps) => {
  const { children, className, onlyOneRow, ...rest } = props
  if (React.isValidElement(children)) return null

  if (onlyOneRow && Array.isArray(children))
    return (
      <p className={classNames(styles.desc, className)} {...rest} >
        {(children as string[]).join('')}
      </p>)

  if (React.Children.count(children) > 1) {
    return <>{
      React.Children.map(children, (child, index) => {
        return (
          <p
            className={classNames(styles.desc, className)}
            {...rest} style={index !== 0 ? { marginTop: 0 } : undefined}>{child}
          </p>)
      })
    }</>
  }
  return (<p className={classNames(styles.desc, className)} {...rest}>{children}</p>)
}

export default Desc
