import React from 'react'
import classNames from 'classnames'
import Title from '../Title'
import RowLeft, { RowLeftProps } from './RowLeft'
import styles from './index.module.scss'
import { ComponentCommonProps } from 'types/reactExtra'

interface LeftAndRightRowProps extends ComponentCommonProps {
  id: string
  left?: RowLeftProps
  title?: string | null
}

const LeftAndRightRow = (props: LeftAndRightRowProps) => {
  const { className, title, left, children, id, ...rest } = props

  const renderChild = () => {
    if (React.isValidElement<ComponentCommonProps>(children)) {
      const elementChild: React.ReactElement<ComponentCommonProps> = children
      return React.cloneElement(elementChild, { className: classNames(styles.rightInner, elementChild.props.className) })
    }
    return children
  }

  return <section className={classNames(styles.root, className)} id={`${id}`} {...rest}>
    {!!title && <Title className={styles.header}>{title}</Title>}
    <div className={styles.content}>
      {left && <RowLeft {...left} />}
      <div className={styles.right}>{renderChild()}</div>
    </div>
  </section>
}

export default LeftAndRightRow
