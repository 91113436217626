import React, { ComponentType } from 'react'
import classNames from 'classnames'
import CusIcon from '../CusIcon'
import styles from './index.module.scss'
import { ComponentCommonProps } from 'types/reactExtra'

interface IconCardProps extends Omit<ComponentCommonProps, 'children'> {
  Comp?: ComponentType
  icon: string
  title: string
  unit?: string | null
  subTitle: string
}

const IconCard = (props: IconCardProps) => {
  const { icon, title, unit, subTitle, className, ...rest } = props

  return (
    <li className={classNames(styles.root, className)} {...rest}>
      <CusIcon type={icon} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.num}>{title}</span>
          {!!unit && <span className={styles.unit}>{unit}</span>}
        </div>
        <div className={styles.subTitle}>{subTitle}</div>
      </div>
    </li>)
}

export default IconCard
