import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { IfElse } from 'components';
import { get } from 'lodash-es';
import { CheckFilledIcon } from 'components/SvgComponents';

interface IMilestone {
  year: string;
  milestone: {
    month: string;
    desc: string[];
  }[];
}

interface MilestonesProps {
  data: IMilestone;
  size?: 'small' | 'default';
}

/**
 * 主要展示公司的里程碑（包括的年份和月份成就
 * @param props MilestonesProps
 * @returns
 */
const Milestone = (props: MilestonesProps) => {
  const { data, size = 'default' } = props;
  const { year, milestone } = data;
  return <>
    <div  className={classNames(styles.root)}>
      <div className={classNames(styles['year'], 'font-weight-700', {
        [styles['year-small']]: size === 'small'
      }) }>{year}</div>
      <div>
        {milestone.map((month, index) => {
          return <div key={index} className={classNames(styles['milestone-container'], {
            [styles['milestone-container-small']]: size === 'small'
          })}>
            <IfElse if={size === 'default'}>
              <CheckFilledIcon className={styles.icons} />
            </IfElse>
            <div className={styles['milestone']}>
              <div className={classNames(styles['month'], 'font-weight-700')}>{month.month}</div>
              :&nbsp;
              <div className={classNames(styles['desc'], 'font-weight-400')}>
                {
                  get(month, 'desc', []).map((desc, index) => {
                    return <div className={styles['desc-item']} key={index}>{desc}</div>
                  })
                }
              </div>
            </div>
          </div>
        })}
      </div>
    </div>
  </>
}

export default Milestone
