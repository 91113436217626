import qs from "qs";
import { useEffect, useState } from "react";

const useSearch = () => {
  const [query, setQuery] = useState<{ [key: string]: string | number|undefined,search?:string }>({});

  useEffect(() => {
    setQuery(getNewSearch(window.location.search));
  }, []);

  return query;
};

export function getNewSearch(search?: string) {
  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    ...rest
  } = qs.parse(search || '', { ignoreQueryPrefix: true });

  const utmPrams = [utm_source, utm_medium, utm_campaign, utm_content, utm_term];

  const businessSource = utmPrams.join("_");

  const _query: any = {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
  };

  if (utmPrams.some(Boolean) && businessSource) {
    _query.businessSource = businessSource;
  }

  _query.utmSearchSource = qs.stringify({ ..._query, ...rest }, { encode: true });

  _query.search = ''

  if (_query.utmSearchSource) {
    _query.search = `?${_query.utmSearchSource}`;
  }

  return _query;
}

export default useSearch
