import React from "react";
import { Button, ButtonProps } from "xt-design";
import classNames from "classnames";
import { useTranslation, useUtmTracker, useTotalRewardAmount } from "hooks";
import styles from "./index.module.scss";
import { LOGIN, REGISTER } from "constants/router";
import { setXtLng } from "utils";
import { useRouter } from "next/router";
import { commonADTrack } from "utils/traceUtil";

interface IProps extends ButtonProps {
  loginText?: string | null;
  registerText?: string | null;
  beforeClickLogin?: () => void;
  beforeClickRegister?: () => void;
  mobile?: boolean;
}

const LoginMenu = (props: IProps) => {
  const { className, type = "primary", loginText, registerText, beforeClickLogin, beforeClickRegister, mobile, ...rest } = props;
  const { locale } = useRouter();
  const { t } = useTranslation();
  const { utmSearchSource } = useUtmTracker();

  const handleClickLogin = () => {
    beforeClickLogin?.();
    if (window?.location) {
      setXtLng(locale || "zh-HK");
      location.href = `${LOGIN}?${utmSearchSource}`;
    }
  };

  const handleClickRegister = () => {
    beforeClickRegister?.();
    if (window?.location) {
      setXtLng(locale || "zh-HK");
      // tiktok 数据回传
      window?.ttq?.track?.("CreateClick", { value: "0.5", currency: "USD" });
      commonADTrack({ name: "Create_Account" });
      location.href = `${REGISTER}?${utmSearchSource}`;
    }
  };

  return (
    <div className={classNames(styles.menu, className)}>
      <Button type={type} onClick={handleClickLogin} className={classNames("xt-text", "xt-button", styles.button, styles.login)} {...rest}>
        {loginText || t("common-login")}
      </Button>
      <Button {...rest} type={type} className={classNames("xt-text", "xt-button", styles.button, styles.register)} onClick={handleClickRegister}>
        {registerText || t("common-register")}
      </Button>
    </div>
  );
};

export default LoginMenu;
