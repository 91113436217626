import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { isString } from 'lodash-es';
import { trackError } from '../utils';

function hasSearch(url?: unknown) {
  // 是有效的url
  if (isString(url)) {

    const strArr = url.split('?')

    // 解析出的数组超过一个
    return strArr.length > 1
  }
  return false
}

export function useSearchChange() {
  const router = useRouter()
  const preUrl = useRef<string>(router.asPath)

  useEffect(() => {
    const handleRouteChangeComplete = (url: string) => {

      const preHasSearch = hasSearch(preUrl.current)
      const nowHasSearch = hasSearch(url)

      const searchChanged = preHasSearch !== nowHasSearch
      const info = `路径从 ${preUrl.current} 跳转到 ${url} ，${
        preHasSearch !== nowHasSearch ? '有' : '没有'
      }search参数变化`

      // 开发环境始终输出
      if (process.env.NODE_ENV === 'development')
        console.info(info)
      // 非生产环境则不输出
      searchChanged && trackError(new Error(info))

      // @ts-ignore
      preUrl.current = url;
    }

    // router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      // router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router])
}
