/* eslint-disable complexity */
import React, { useMemo } from "react";
import { Button, ButtonProps } from "xt-design";
import classNames from "classnames";
import { useTranslation } from "hooks";
import { useRouter } from "next/router";
import CusIcon from "../CusIcon";
import styles from "./index.module.scss";
import { INTERNATIONAL_CONTACT_US, INTERNATIONAL_LANDING_INDEX_2, INTERNATIONAL_SUPPORT_CONTACT_US, REGISTER } from "constants/router";
import { getPathWithQuery, getPathWithSite } from "utils/route";
import { useCommonState } from "context";
import { CusBtnSourceEnum } from "constants/enum";
import { SitesEnum } from "constants/regions";
import { commonADTrack } from "utils/traceUtil";

interface CusButtonProps extends ButtonProps {
  showIcon?: boolean;
  text?: string | null;
  beforeClick?: () => void;
  mobile?: boolean;
  isMain?: boolean;
  source?: string;
  isFulfilled?: boolean;
}

const CusButton = (props: CusButtonProps) => {
  const { className, type = "primary", showIcon = true, text, source, isMain, beforeClick, mobile, ...rest } = props;
  const { isGlobal, isUK, isUS, isSG, isHKLandingConsult, isHKLanding } = useCommonState();
  const { t } = useTranslation();
  const router = useRouter();
  const { query } = router;
  const { site } = query as { site: SitesEnum };

  const isLandingContactUs = router?.query?.contact === "true";

  const btnText: string = useMemo(() => {
    if (isHKLandingConsult) {
      return text || t("common-contactUs");
    }

    return isMain
      ? t("contact-register")
      : source === CusBtnSourceEnum.HOME_LANDING && !isLandingContactUs && !isUK && !isUS
      ? t("create-account")
      : text || t("common-contactUs");
  }, [isMain, t, source, isLandingContactUs, isUK, isUS, text, isHKLandingConsult]);

  const handleClick = () => {
    beforeClick?.();
    // tiktok 数据回传
    window?.ttq?.track?.("CreateClick", { value: "0.5", currency: "USD" });
    // 移除语言参数
    const regex = /&?lng=[^&]*(&|$)/;
    const search = window.location.search;
    // 当前页面是主页,跳转到注册页面
    if (isMain && !isHKLandingConsult) {
      commonADTrack({ name: "Create_Account" });
      location.href = `${REGISTER}${search?.replace(regex, "$1")}`;
      return;
    }

    // 当前页面是landing2,跳转到留资表单
    if (isHKLandingConsult) {
      commonADTrack({ name: "Contact_Us" });
      location.href = `#__next`;
      return;
    }

    // 当前页面是landing,需要根据contact来确认
    if (source === CusBtnSourceEnum.HOME_LANDING) {
      const businessSource = router?.query?.businessSource || "";
      if (isLandingContactUs || isUK || isUS || isSG) {
        commonADTrack({ name: "Contact_Us" });
        return router.push(
          getPathWithQuery({
            asPath: router.asPath,
            toPath: getPathWithSite(site, isHKLandingConsult || isHKLanding ? INTERNATIONAL_CONTACT_US : INTERNATIONAL_SUPPORT_CONTACT_US),
          })
        );
      } else {
        commonADTrack({ name: "Create_Account" });
        let url = REGISTER;
        if (search.includes("?")) {
          url = `${url}${search?.replace(regex, "$1")}&businessSource=${businessSource}`;
        } else {
          url = `${url}?businessSource=${businessSource}`;
        }
        location.href = url;
      }
      return;
    }

    // 默认逻辑,跳转
    commonADTrack({ name: "Contact_Us" });
    router.push(
      getPathWithQuery({
        asPath: router.asPath,
        toPath: getPathWithSite(
          site,
          isGlobal && ["zh-HK", "zh-TW", "zh"].includes(router.locale || "") ? INTERNATIONAL_CONTACT_US : INTERNATIONAL_SUPPORT_CONTACT_US
        ),
      })
    );
  };

  return (
    <Button
      type={type}
      onClick={handleClick}
      className={classNames("xt-text", "xt-button", styles.button, { "mobile-button": mobile }, className)}
      {...rest}
    >
      {btnText}
      {showIcon && (
        <div className={`${styles.icon} xt-button-icon`}>
          <CusIcon type={"xtanniuzhiyin"} />
        </div>
      )}
    </Button>
  );
};

export default CusButton;
