import { global_png, cn_png, hk_png, jp_png, us_png, uk_png, sg_png } from "constants/images";

// 所有站点（不包含landing）
export enum SitesWithoutLandingEnum {
  HK = "hk",
  US = "us",
  UK = "uk",
  SG = "sg",
  GLOBAL = "global",
}

// 所有站点（包含landing）
export enum SitesEnum {
  HK = "hk",
  US = "us",
  UK = "uk",
  SG = "sg",
  GLOBAL = "global",
  HK_LANDING = "hk-landing",
  HK_LANDING_CONSULT = "landing2",
  GLOBAL_LANDING = "landing",
}
export const DEFAULT_LOCATION = SitesEnum.GLOBAL;

// 所有站点合并成数组
export const LOCATIONS = [
  SitesEnum.HK,
  SitesEnum.US,
  SitesEnum.UK,
  SitesEnum.SG,
  SitesEnum.GLOBAL,
  SitesEnum.HK_LANDING,
  SitesEnum.GLOBAL_LANDING,
  SitesEnum.HK_LANDING_CONSULT,
];

// 语言
export const LNG_EN = "en";
export const LNG_ZH = "zh";
export const LNG_TC = "zh-TW";
export const LANGUAGES = [LNG_EN, LNG_ZH, LNG_TC];
export const DEFAULT_LNG = LNG_EN;

// 语言切换
export const sites = {
  [SitesWithoutLandingEnum.GLOBAL]: {
    label: "Global  (English)",
    icon: global_png,
    alt: "global english",
    href: `/`,
  },
  [SitesWithoutLandingEnum.HK]: {
    label: "中國香港",
    icon: hk_png,
    alt: "中國香港",
    href: `/hk?lng=zh-HK`,
  },
  [SitesWithoutLandingEnum.US]: {
    label: "United States",
    icon: us_png,
    alt: "United States",
    href: `/us`,
  },
  [SitesWithoutLandingEnum.UK]: {
    label: "United Kingdom",
    icon: uk_png,
    alt: "United Kingdom",
    href: `/uk`,
  },
  [SitesWithoutLandingEnum.SG]: {
    label: "Singapore",
    icon: sg_png,
    alt: "Singapore",
    href: `/sg`,
  },
  // cn和jp是特例
  cn: {
    label: "中国",
    icon: cn_png,
    alt: "中国",
    href: `https://www.xtransfer.cn?lang=zh`,
  },
  jp: {
    label: "日本",
    icon: jp_png,
    alt: "日语",
    href: `https://www.xtransfer.cn?lang=ja`,
  },
};

// site ——> areaCode 的映射
export const site2AreaCodeMap = {
  [SitesEnum.GLOBAL]: "852",
  [SitesEnum.HK]: "852",
  [SitesEnum.US]: "1",
  [SitesEnum.UK]: "44",
  [SitesEnum.SG]: "65",
  [SitesEnum.HK_LANDING]: "852",
  [SitesEnum.HK_LANDING_CONSULT]: "852",
  [SitesEnum.GLOBAL_LANDING]: "852",
  "cn": "86",
};

// 用于留资时电话号码的默认校验规则
export const DEFAULT_REG = /^\d{4,}$/;

// 用于留资时电话号码的校验规则
export const areaCode2RegMap: Record<string, RegExp> = {
  "852": /^\d{8}$/,
  "86": /^1[0-9]\d{9}$/,
  "1": /^[0-9]{10}$/,
  "44": /^(?:\d{7}|\d{9}|\d{10})$/,
  "65": /^\d{8}$/,
};

// 用于留资时的site字段
export enum AreaCode {
  HK = "HK",
  US = "US",
  UK = "UK",
  SG = "SG",
}

// 用于留资时的site字段
// global、hk、所有种类landing传"HK", us传"US", uk传"UK", sg传"SG"
export const site2AreaCode: { [key: string]: string } = {
  [SitesEnum.HK]: AreaCode.HK,
  [SitesEnum.US]: AreaCode.US,
  [SitesEnum.UK]: AreaCode.UK,
  [SitesEnum.GLOBAL]: AreaCode.HK,
  [SitesEnum.SG]: AreaCode.SG,
  [SitesEnum.GLOBAL_LANDING]: AreaCode.HK,
  [SitesEnum.HK_LANDING]: AreaCode.HK,
  [SitesEnum.HK_LANDING_CONSULT]: AreaCode.HK,
};

// HK站点区号列表
export const PhoneAreaOptionsHK: { value: string; region?: string; label: string; key?: number }[] = [
  {
    value: site2AreaCodeMap[SitesEnum.HK],
    region: "Hong Kong",
    label: `+${site2AreaCodeMap[SitesEnum.HK]}`,
  },
  {
    value: site2AreaCodeMap["cn"],
    region: "China",
    label: `+${site2AreaCodeMap["cn"]}`,
  },
];

// US站点区号列表
export const PhoneAreaOptionsUS: { value: string; region?: string; label: string; key?: number }[] = [
  {
    value: site2AreaCodeMap[SitesEnum.US],
    region: "United States",
    label: `+${site2AreaCodeMap[SitesEnum.US]}`,
  },
];

// UK站点区号列表
export const PhoneAreaOptionsUK: { value: string; region?: string; label: string; key?: number }[] = [
  {
    value: site2AreaCodeMap[SitesEnum.UK],
    region: "United Kingdom",
    label: `+${site2AreaCodeMap[SitesEnum.UK]}`,
  },
];

// SG站点区号列表
export const PhoneAreaOptionsSG: { value: string; region?: string; label: string; key?: number }[] = [
  {
    value: site2AreaCodeMap[SitesEnum.SG],
    region: "Singapore",
    label: `+${site2AreaCodeMap[SitesEnum.SG]}`,
  },
];

// 站点区号列表映射
export const Site2AreaCodeOptionsMap = {
  [SitesEnum.HK]: PhoneAreaOptionsHK,
  [SitesEnum.US]: PhoneAreaOptionsUS,
  [SitesEnum.UK]: PhoneAreaOptionsUK,
  [SitesEnum.SG]: PhoneAreaOptionsSG,
  [SitesEnum.GLOBAL]: PhoneAreaOptionsHK,
  [SitesEnum.GLOBAL_LANDING]: PhoneAreaOptionsHK,
  [SitesEnum.HK_LANDING]: PhoneAreaOptionsHK,
  [SitesEnum.HK_LANDING_CONSULT]: PhoneAreaOptionsHK,
};

// 官网跳转到登录注册时的语言映射
export const locale2LoginLngMap:Record<string, string> = {
  [LNG_EN]: "en",
  [LNG_ZH]: "zh-TW",
  [LNG_TC]: "zh-TW",
}
