import { DEFAULT_CURRENCY } from "./exchangeRate";

export const DASHBOARD_HOME = "/dashboard";

export const PROTOCOL_USER_AGREEMENT = "/protocol/user-agreement";
export const PROTOCOL_PRIVATE_POLICY = "/protocol/private-policy";

// 登陆注册
export const LOGIN = "/base/login";
export const LOGOUT = "/base/logout";
export const REGISTER = "/base/login/register";

//官网
export const INTERNATIONAL_INDEX = "/";
export const INTERNATIONAL_PRODUCTS = "/products";
export const INTERNATIONAL_ABOUT_US = "/about-us";

// 官网—改版
export const INTERNATIONAL_BUSINESS_WALLET = "/services/wallet";
export const INTERNATIONAL_SERVICE_COLLECTION = "/services/collection";
export const INTERNATIONAL_SERVICE_PAYMENT = "/services/payment";
export const INTERNATIONAL_SERVICE_EXCHANGE = "/services/exchange";
export const INTERNATIONAL_SERVICE_PRICING = "/services/pricing";

export const INTERNATIONAL_ABOUT_US_COMPANY = "/about-us/company";
export const INTERNATIONAL_ABOUT_US_NEWS_AND_BLOG = "/news-and-blog";
export const INTERNATIONAL_ABOUT_US_CAREER = "/about-us/career";
export const INTERNATIONAL_ABOUT_US_PARTNER = "/about-us/partner";

export const INTERNATIONAL_SUPPORT_FAQS = "/support/faqs";
export const INTERNATIONAL_SUPPORT_CONTACT_US = "/support/contact-us";
export const INTERNATIONAL_SUPPORT_CALLUS = "/support/call-us";

// 国际站留资页
export const INTERNATIONAL_CONTACT_US = "/contact-us";
export const INTERNATIONAL_CALL_US = "/call-us";

// 文章页
export const INTERNATIONAL_ARTICLES = "/knowledge-hub";
export const INTERNATIONAL_ARCHIVES = "/archives";

//协议
export const INTERNATIONAL_COOKIE_POLICY = "/protocol/cookie";
export const INTERNATIONAL_PRIVACY_POLICY = "/protocol/privacy";
export const INTERNATIONAL_WEBSITE_TERMS = "/protocol/website";

// XT豆
export const XT_BEAN = "/dashboard/xt-bean";

//帮助中心
export const HOME = "/help";
export const MENU = "/help-center/menu";
export const QUESTION = "/help-center/question";
export const SEARCH = "/help-center/search";

//帮助中心h5
export const HOMEH5 = "/help-center-h5";
export const MENUH5 = "/help-center-h5/menu";
export const SUBMENUH5 = "/help-center-h5/subMenu";
export const ANSWERH5 = "/help-center-h5/answer";
export const SEARCHH5 = "/help-center-h5/searchResult";

//landing 落地页
export const INTERNATIONAL_LANDING_INDEX = "/landing";
export const INTERNATIONAL_LANDING_INDEX_2 = "/landing2";
export const INTERNATIONAL_LANDING_PRODUCTS = "/landing/products";
export const INTERNATIONAL_LANDING_ABOUT_US = "/landing/about-us";
export const INTERNATIONAL_LANDING_CALL_US = "/landing/call-us";
export const INTERNATIONAL_LANDING_CONTACT_US = "/landing/contact-us";
export const INTERNATIONAL_LANDING_COOKIE_POLICY = "/landing/protocol/cookie";
export const INTERNATIONAL_LANDING_PRIVACY_POLICY = "/landing/protocol/privacy";
export const INTERNATIONAL_LANDING_WEBSITE_TERMS = "/landing/protocol/website";

export const INTERNATIONAL_LANDING_BUSINESS_WALLET = "/landing/services/wallet";
export const INTERNATIONAL_LANDING_SERVICES_PAYMENT = "/landing/services/payment";
export const INTERNATIONAL_LANDING_SERVICES_PRICING = "/landing/services/pricing";
export const INTERNATIONAL_LANDING_SERVICES_EXCHANGE = "/landing/services/exchange";
export const INTERNATIONAL_LANDING_SERVICES_COLLECTION = "/landing/services/collection";
export const INTERNATIONAL_LANDING_ABOUT_US_COMPANY = "/landing/about-us/company";
export const INTERNATIONAL_LANDING_ABOUT_US_NEWS_AND_BLOG = "/landing/news-and-blog";
export const INTERNATIONAL_LANDING_ABOUT_US_CAREER = "/landing/about-us/career";
export const INTERNATIONAL_LANDING_ABOUT_US_PARTNER = "/landing/about-us/partner";
export const INTERNATIONAL_LANDING_SUPPORT_CONTACT_US = "/landing/support/contact-us";
export const INTERNATIONAL_LANDING_SUPPORT_CALLUS = "/landing/support/call-us";

//推荐有礼
export const REFERRAL = "/referral";

// 微信端新合伙人详情
export const NEW_PROMOTION_PARTNER_DETAILS = "/h5/new/promotion/details";

/**
 * mfe-user-international-exchange应用的baseUrl
 */
export const TOOLS_BASEURL = "tools";

// Resource外站链接
export const RESOURCE_HS_CODE = "/hscode";
export const RESOURCE_EXCHANGE_RATE = `/${TOOLS_BASEURL}/en/exchange-rate/${DEFAULT_CURRENCY[0].toLowerCase()}-to-${DEFAULT_CURRENCY[1].toLowerCase()}`;
export const RESOURCE_SWIFT_CODE = "/swiftcode";

// 官网
export const WEBSITE = "https://www.xtransfer.com";

export const RESOURCE_COMMUNITY = "/community";

// 国际站、香港站、landing使用，跳转到收款平台
export const PRIVATE_POLICY = `/protocol/private-policy`;
export const COOKIE_POLICY = `/protocol/cookie-policy`;
export const USER_AGREEMENT_POLICY = `/protocol/user-agreement`;
export const EXCHANGE_SERVICE_AGREEMENT_POLICY = `/protocol/exchange-service-agreement`;
export const ACCOUNT_TERMS_OF_USE_POLICY = `/protocol/account-terms-of-use`;
