import React, { CSSProperties, ReactNode } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import CusIcon from "../CusIcon";
import styles from "./index.module.scss";
import CusButton from "components/CusButton";
import { setXtLng } from "utils";
import { sensorTrack } from "utils/track/sensorUtils";
import { useCommonState } from "context";
import { CusBtnSourceEnum } from "constants/enum";

interface BannerProps {
  id?: string;
  title: string;
  titleStyle?: CSSProperties;
  desc: string[];
  descStyle?: CSSProperties;
  /**
   * 行间距
   */
  rowMarginTop?: number | string;
  bgSrc?: string;
  leftStyle?: CSSProperties;
  rightStyle?: CSSProperties;
  liStyle?: CSSProperties;
  bgContainerStyle?: CSSProperties;
  bgClassName?: string;
  cusImg?: ReactNode;
  disableLineIcon?: boolean;
  options?: boolean | React.ReactNode;
}

const TopBanner = (props: BannerProps) => {
  const {
    id = "topBanner",
    title,
    titleStyle,
    desc,
    descStyle,
    bgSrc,
    rowMarginTop,
    leftStyle,
    liStyle,
    bgContainerStyle,
    bgClassName,
    cusImg,
    disableLineIcon,
  } = props;

  const { isLanding, isGlobal } = useCommonState();
  const { locale } = useRouter();

  return (
    <section className={classNames("xt-row", styles.root)} id={id}>
      <div className={styles.left} style={leftStyle}>
        <h1 className={classNames("xt-h1", styles.title)} style={titleStyle}>
          {title}
        </h1>
        <ul className={styles.subTitle} style={descStyle}>
          {Array.isArray(desc) &&
            desc.map((i, index) => (
              <li key={i} className={styles.item} style={{ marginTop: index !== 0 ? rowMarginTop : 0, ...liStyle }}>
                {!disableLineIcon && (
                  <div className={styles.icon}>
                    <CusIcon type={"xtduigou"} style={{ fontSize: 16, color: "var(--text-black-999)" }} />
                  </div>
                )}
                <span className={styles.text}>{i}</span>
              </li>
            ))}
        </ul>
        <CusButton
          style={{ marginTop: "var(--rem-40)" }}
          beforeClick={() => {
            if (isLanding && locale === "zh") {
              setXtLng("zh-HK");
            }
            sensorTrack("hongkongsite_click", { hk_official_url: window.location.href, hk_official_button_name: "联络我们" });
          }}
          isMain={!isLanding}
          source={isLanding ? CusBtnSourceEnum.HOME_LANDING : ""}
        />
      </div>
      <div className={styles.right}>
        <div className={classNames(styles.imgContainer, bgClassName)} style={bgContainerStyle}>
          {!cusImg ? <img src={bgSrc} alt={"Our story"} className={styles.img} /> : cusImg}
        </div>
      </div>
      <div className={styles.mobile}>
        <h1 className={classNames("xt-h1", styles.title)} style={titleStyle}>
          {title}
        </h1>
        <div className={styles.mobileImgContainer}>
          <div className={styles.imgInner}>{!cusImg ? <img src={bgSrc} alt={"Our story"} className={styles.img} /> : cusImg}</div>
        </div>
        <ul className={styles.subTitle} style={descStyle}>
          {Array.isArray(desc) &&
            desc.map((i, index) => (
              <li key={i} className={styles.item} style={{ marginTop: index !== 0 ? rowMarginTop : 0, ...liStyle }}>
                {!disableLineIcon && (
                  <div className={styles.icon}>
                    <CusIcon type={"xtduigou"} style={{ fontSize: 16, color: "var(--text-black-999)" }} />
                  </div>
                )}
                <span className={styles.text}>{i}</span>
              </li>
            ))}
        </ul>
        <CusButton
          className={styles.button}
          mobile
          showIcon={false}
          isMain={!isLanding}
          source={isLanding ? CusBtnSourceEnum.HOME_LANDING : ""}
          beforeClick={() => {
            setXtLng("zh-HK");
          }}
        />
      </div>
    </section>
  );
};

export default TopBanner;
