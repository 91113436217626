import React from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'
import { ComponentCommonProps } from 'types/reactExtra'

/**
 * 包含底部阴影的大段
 */
const TitleBanner = (props: ComponentCommonProps & { noExtraClass?: boolean }) => {
  const { children, className, style, noExtraClass } = props

  return <section className={classNames(styles.root, className)} style={style}>
    {
      React.Children.map(children, (child) => {
        if (!React.isValidElement<ComponentCommonProps>(child))
          return child

        const elementChild: React.ReactElement<ComponentCommonProps> = child

        return React.cloneElement(elementChild, {
          className: classNames(noExtraClass ? '' : 'xt-row', elementChild.props.className),
        })
      })
    }
  </section>
}

export default TitleBanner
