import { IMO, KAKAOTALK, LINE, MESSANGER, SNAPCHAT, TELEGRAM, VIBER, WECHATICON, WHATSAPP, ZALO } from "./images";


export const QRGroup = {
  whatsapp: "https://wa.me/8615618321591",
  telegram: "https://t.me/xtransfer_global",
};

export enum MediaType {
  WhatsApp = "WhatsApp",
  Messenger = "Messenger",
  Line = "Line",
  Telegram = "Telegram",
  Viber = "Viber",
  Snapchat = "Snapchat",
  KakaoTalk = "Kakao Talk",
  Zalo = "Zalo",
  Imo = "Imo",
  Wechat = "Wechat",
}

export const MediaTypeOptions: { value: string; label: string; icon?: string; key?: number }[] = [
  {
    value: MediaType.Messenger,
    label: MediaType.Messenger,
    icon: MESSANGER,
  },
  {
    value: MediaType.WhatsApp,
    label: MediaType.WhatsApp,
    icon: WHATSAPP,
  },
  {
    value: MediaType.Wechat,
    label: MediaType.Wechat,
    icon: WECHATICON,
  },
  {
    value: MediaType.Line,
    label: MediaType.Line,
    icon: LINE,
  },
  {
    value: MediaType.Telegram,
    label: MediaType.Telegram,
    icon: TELEGRAM,
  },
  {
    value: MediaType.Viber,
    label: MediaType.Viber,
    icon: VIBER,
  },
  {
    value: MediaType.Snapchat,
    label: MediaType.Snapchat,
    icon: SNAPCHAT,
  },
  {
    value: "KakaoTalk",
    label: MediaType.KakaoTalk,
    icon: KAKAOTALK,
  },
  {
    value: MediaType.Zalo,
    label: MediaType.Zalo,
    icon: ZALO,
  },
  {
    value: MediaType.Imo,
    label: MediaType.Imo,
    icon: IMO,
  },
];

export enum LocationType {
  None = "None",
  China = "Mainland China / Hong Kong",
  Other = "Other Countries",
}

export const LocationCountryMap = {
  "52": "mx",
  "84": "vn",
  "1": "us",
  "971": "mena",
  "966": "mena",
  "964": "mena",
  "234": "ng",
};

export const LocationTypeOptions: { value: string; label: string; hkLabel: string; zhLabel: string }[] = [
  {
    value: LocationType.None,
    label: LocationType.None,
    hkLabel: "無",
    zhLabel: "无",
  },
  {
    value: "China",
    label: LocationType.China,
    hkLabel: "中國大陸/香港",
    zhLabel: "中国大陆/香港",
  },
  {
    value: LocationType.Other,
    label: LocationType.Other,
    hkLabel: "其他國家或地區",
    zhLabel: "其他国家或地区",
  },
];

export const LanguageSiteMap: { [key: string]: string } = {
  "zh-tw": "zh-hk",
  "zh-hk": "zh-hk",
  en: "en",
};

export const HKCallList = [
  {
    area: "香港",
    icon: "https://static.xtransfer.com/boss/static/x-hongkong-1_c3c3a93352d81e54.png",
    telephone: "+852 38533886",
    time: "9:00 - 21:00 GMT+8<br/>星期一至星期日",
  },
  {
    area: "越南",
    icon: "https://static.xtransfer.com/boss/static/x-vietnam-1_0d6abb978bf29bac.png",
    telephone: "+84 2471097248",
    time: "9:00 - 21:00 GMT+8<br/>星期一至星期日",
  },
  {
    area: "墨西哥",
    icon: "https://static.xtransfer.com/boss/static/x-mexico-1_c9b3f501b42691b0.png",
    telephone: "+52 5588973118",
    time: "9:00 - 21:00 GMT+8<br/>星期一至星期日",
  },
  {
    area: "泰國",
    icon: "https://static.xtransfer.com/boss/static/x-thailand-1_ebf23aa55c8b9135.png",
    telephone: "+66 977831739",
    time: "9:00 - 21:00 GMT+8<br/>星期一至星期日",
  },
  {
    area: "巴西",
    icon: "https://static.xtransfer.com/boss/static/x-brazil-1_ab1f07035cfbd329.png",
    telephone: "+55 1133517088",
    time: "9:00 - 21:00 GMT+8<br/>星期一至星期日",
  },
  {
    area: "哥倫比亞",
    icon: "https://static.xtransfer.com/boss/static/x-columbia-1_23ddf512238e7e18.png",
    telephone: "+57 6075895050",
    time: "9:00 - 21:00 GMT+8<br/>星期一至星期日",
  },
  {
    area: "以色列",
    icon: "https://static.xtransfer.com/boss/static/x-israel-1_9bf477b51e4e0790.png",
    telephone: "+972 33760505",
    time: "9:00 - 21:00 GMT+8<br/>星期一至星期日",
  },
  {
    area: "奈及利亞",
    icon: "https://static.xtransfer.com/boss/static/x-nigeria-1_a855703722465508.png",
    telephone: "+234 7001238640",
    time: "9:00 - 21:00 GMT+8<br/>星期一至星期日",
  },
  {
    area: "南非",
    icon: "https://static.xtransfer.com/boss/static/x-southafrica-1_617d60199c891d53.png",
    telephone: "+27 125344247",
    time: "9:00 - 21:00 GMT+8<br/>星期一至星期日",
  },
];

export const enCallList = [
  {
    area: "Hong Kong",
    icon: "https://static.xtransfer.com/boss/static/x-hongkong-1_c3c3a93352d81e54.png",
    telephone: "+852 38533886",
    time: "9:00 - 21:00 GMT+8<br/>Monday to Sunday",
  },
  {
    area: "United States",
    icon: "https://static.xtransfer.com/boss/static/x-america-1_017a8d00b3d72248.svg",
    telephone: "+1 8886085155",
    time: "7:00am to 9:00pm GMT+8<br/>Monday to Friday",
  },
  {
    area: "Vietnam",
    icon: "https://static.xtransfer.com/boss/static/x-vietnam-1_0d6abb978bf29bac.png",
    telephone: "+84 2471097248",
    time: "9:00 - 21:00 GMT+8<br/>Monday to Sunday",
  },
  {
    area: "Mexico",
    icon: "https://static.xtransfer.com/boss/static/x-mexico-1_c9b3f501b42691b0.png",
    telephone: "+52 5588973118",
    time: "9:00 - 21:00 GMT+8<br/>Monday to Sunday",
  },
  {
    area: "Thailand",
    icon: "https://static.xtransfer.com/boss/static/x-thailand-1_ebf23aa55c8b9135.png",
    telephone: "+66 977831739",
    time: "9:00 - 21:00 GMT+8<br/>Monday to Sunday",
  },
  {
    area: "Brazil",
    icon: "https://static.xtransfer.com/boss/static/x-brazil-1_ab1f07035cfbd329.png",
    telephone: "+55 1133517088",
    time: "9:00 - 21:00 GMT+8<br/>Monday to Sunday",
  },
  {
    area: "Columbia",
    icon: "https://static.xtransfer.com/boss/static/x-columbia-1_23ddf512238e7e18.png",
    telephone: "+57 6075895050",
    time: "9:00 - 21:00 GMT+8<br/>Monday to Sunday",
  },
  {
    area: "Israel",
    icon: "https://static.xtransfer.com/boss/static/x-israel-1_9bf477b51e4e0790.png",
    telephone: "+972 33760505",
    time: "9:00 - 21:00 GMT+8<br/>Monday to Sunday",
  },
  {
    area: "Nigeria",
    icon: "https://static.xtransfer.com/boss/static/x-nigeria-1_a855703722465508.png",
    telephone: "+234 7001238640",
    time: "9:00 - 21:00 GMT+8<br/>Monday to Sunday",
  },
  {
    area: "South Africa",
    icon: "https://static.xtransfer.com/boss/static/x-southafrica-1_617d60199c891d53.png",
    telephone: "+27 125344247",
    time: "9:00 - 21:00 GMT+8<br/>Monday to Sunday",
  },
];

export const callList: any = {
  "zh-HK": HKCallList,
  en: enCallList,
  zh: HKCallList,
};
