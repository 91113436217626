import { useMount, useUnmount } from "ahooks";
import { useChangeLanguage, useCommonState } from "context";
import { MicroApp } from "qiankun";
import { useCallback, useEffect, useRef, useState } from "react";
import { onLCP } from "utils/lcp";

interface LoadCustomerServiceProps {
  defaultLoadTime?: number; // 是否需要延迟加载
  disableAutoLoad?: boolean; // 是否禁止自动加载
}

export const useLoadCustomerService = (props?: LoadCustomerServiceProps) => {
  const { defaultLoadTime = 0, disableAutoLoad = false } = props || {};
  const { isGlobal, isLanding } = useCommonState();
  const [curSiteAndLang] = useChangeLanguage();
  const [, setRefresh] = useState(0); //是否有被点击过
  const loadRef = useRef({
    hasClick: false, //是否有被点击过
    loaded: false, //是否加载过
    autoOpen: false, //是否自动加载
  });

  const qiankunRef = useRef<MicroApp>();
  const countCustomerServiceTime = useRef(0);
  const loadNum = useRef(0);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_DISABLED_CUSTOMER_SERVICE) return;
    // url 中含有 gtm_debug代码 不加载客服
    if (window && window.location.href.indexOf("gtm_debug") > -1) return;
    qiankunRef.current?.unmount().then(() => {
      loadNum.current++;
      loadCustomerService(loadNum.current);
    });
  }, [curSiteAndLang]);

  useMount(() => {
    if (process.env.NEXT_PUBLIC_DISABLED_CUSTOMER_SERVICE) return;
    // url 中含有 gtm_debug代码 不加载客服
    if (window && window.location.href.indexOf("gtm_debug") > -1) return;

    if (defaultLoadTime > 0) {
      setTimeout(() => {
        handleLoadCustomerService();
      }, defaultLoadTime);
    } else {
      // 默认lcp完成后加载
      !disableAutoLoad && onLCP(() => handleLoadCustomerService());
    }
  });

  const handleLoaded = () => {
    loadRef.current.loaded = true;
    setRefresh((prev) => prev + 1);
    try {
      if (countCustomerServiceTime.current > 0) {
        const diff = Date.now() - countCustomerServiceTime?.current;
        // @ts-ignore
        window.__bl?.avg("customer_service_load_time", diff);
        countCustomerServiceTime.current = 0;
      }
    } catch (error) {
      console.log("error", error);
    }
    if (loadRef.current.autoOpen) {
      // 自动打开客服弹框
      window.dispatchEvent(new CustomEvent("onOpenImModal", { detail: { autoPlay: true, type: "customerService", scene: "internationalSite" } }));
      loadRef.current.autoOpen = false;
    }
  };

  const handleLoadCustomerService = (openIm?: boolean) => {
    // 当前客服已经加载的时候、同时防止多次点击，解决临界点问题1
    loadRef.current.autoOpen = !!openIm;
    setRefresh((prev) => prev + 1);
    // @ts-ignore 计算客服加载时间
    countCustomerServiceTime.current = Date.now();
    if (!loadRef.current.loaded && !loadRef.current.hasClick) {
      loadRef.current.hasClick = true;
      // 增加监听事件
      window.addEventListener("IM_MODAL_READY", handleLoaded);
      loadNum.current++;
      loadCustomerService(loadNum.current, !!openIm);
      setRefresh((prev) => prev + 1);
    }
  };

  const loadCustomerService = (num?: number, autoplay?: boolean) => {
    if (isGlobal && !isLanding) {
      import("qiankun").then((qiankun) => {
        qiankunRef.current = qiankun.loadMicroApp(
          {
            name: "mfe-user-customer-service-hk",
            entry: "/mfe/mfe-user-customer-service-hk/",
            container: "#customer-service",
            props: {
              showEntrance: true,
              dropdownType: "directCustomerService",
              autoplay: !!autoplay,
            },
          },
          {
            sandbox: { experimentalStyleIsolation: true },
            excludeAssetFilter: (assetUrl: string) => {
              const { pathname } = new URL(assetUrl);
              return !/\/mfe-user-customer-service/.test(pathname);
            },
          }
        );
      });
      loadRef.current.hasClick = true;
      setRefresh((prev) => prev + 1);
    }
  };

  useUnmount(() => {
    // 移除监听事件
    window.removeEventListener("IM_MODAL_READY", handleLoaded);
    if (qiankunRef.current) {
      qiankunRef.current.unmount();
    }
  });

  return { loadRef, handleLoadCustomerService };
};
