import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { getLanguageSiteAndLangMap } from "./language";
import { useRouter } from "next/router";
import { LanguageMenuType } from "types/language";
import { getHostsByHost, getIsLanding } from "utils";
import { HONG_KONG_HOST, UK_HOST, US_HOST } from "constants/env";
import { SitesWithoutLandingEnum, SitesEnum } from "constants/regions";

interface CommonStateType extends PageProps, AllLocateProps {
  siteMap: LanguageMenuType;
  hosts: AllHostsProps;
  currentSite: SitesWithoutLandingEnum;
}

interface CommonDispatchType {}

const DEFAULT_HOSTS = getHostsByHost();

const DEFAULT_STATE: CommonStateType = {
  siteMap: getLanguageSiteAndLangMap(DEFAULT_HOSTS),
  hosts: DEFAULT_HOSTS,
  currentSite: SitesWithoutLandingEnum.GLOBAL,
};
const DEFAULT_ACTIONS = {};

/**
 * @deprecated 推荐useCommonState
 * @type {React.Context<CommonStateType | undefined>}
 */
const CommonStateContext = React.createContext<CommonStateType>(DEFAULT_STATE);
const CommonDispatchContext = React.createContext<CommonDispatchType>(DEFAULT_ACTIONS);

export function useCommonState() {
  const context = React.useContext(CommonStateContext);
  if (context === undefined) return DEFAULT_STATE;

  return context;
}

/**
 * @deprecated 目前没有
 */
export function useCommonDispatch() {
  const context = React.useContext(CommonDispatchContext);
  if (context === undefined) return DEFAULT_ACTIONS;

  return context;
}

/**
 * 确保SSR和CSR阶段都有准确的站点信息
 * 如果SSR，有host，则返回ssr的host
 * 如果SSG，则使用站点信息返回线上对应站点的地址
 * 在前者基础上，测试环境会在CSR阶段更新最新的地址
 * @param station 环境变量，站点
 * @param ssrHost SSR环境中的host信息
 */
function getHost(ssrHost?: string, station = process.env.NEXT_PUBLIC_STATION) {
  // 如果是SSR中的地址
  if (ssrHost) {
    return ssrHost;
  }
  switch (station) {
    case "us":
      return US_HOST;
    case "uk":
      return UK_HOST;
    case "hk":
    default:
      return HONG_KONG_HOST;
  }
}

interface OwnProps extends ProviderProps, PageProps {}

/**
 * 一些全局共享的对象
 * 1、Common监控变量 - CommonMonitor1001
 * @param {JSX.Element} children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const CommonProvider = ({ children, ...rest }: OwnProps): JSX.Element => {
  const router = useRouter();
  const { locale, query } = router;
  const { site } = query as { site: SitesEnum };
  const restRef = useRef(rest);

  // SSR阶段 页面第一次打开接收的数值用来初始化，后续的不再接收，因为会存在host丢失的情况
  const [host, setHost] = useState(getHost(rest.host));

  // 测试环境 CSR阶段 使用window保证正确
  useEffect(() => {
    // console.info('****************** CommonProvider-useEffect *******************', window.location.host)
    setHost(window.location.host);
  }, []);

  const values = useMemo(() => {
    const isGlobal = site === SitesEnum.GLOBAL;
    const isHK = site === SitesEnum.HK;
    const isUS = site === SitesEnum.US;
    const isUK = site === SitesEnum.UK;
    const isSG = site === SitesEnum.SG;

    const isHKLanding = site === SitesEnum.HK_LANDING;
    const isHKLandingConsult = site === SitesEnum.HK_LANDING_CONSULT;
    const isGlobalLanding = site === SitesEnum.GLOBAL_LANDING;

    const currentSite = isGlobal
      ? SitesWithoutLandingEnum.GLOBAL
      : isUS
      ? SitesWithoutLandingEnum.US
      : isUK
      ? SitesWithoutLandingEnum.UK
      : isSG
      ? SitesWithoutLandingEnum.SG
      : SitesWithoutLandingEnum.HK;

    const isLanding = isHKLanding || isGlobalLanding || isHKLandingConsult;

    const hosts = getHostsByHost(host);

    // 因为是对象所有要缓存方式多次刷新
    const memoSiteMap = getLanguageSiteAndLangMap(hosts, { isUK, isLanding });

    return {
      ...restRef.current,
      host,
      siteMap: memoSiteMap,
      hosts,
      currentSite,
      isGlobal,
      isHK,
      isUK,
      isUS,
      isSG,
      isHKLanding,
      isGlobalLanding,
      isHKLandingConsult,
      isLanding,
      isZHLang: locale === "zh",
    };
  }, [host, locale, site]);

  return <CommonStateContext.Provider value={values}>{children}</CommonStateContext.Provider>;
};

export default CommonProvider;
