import axios, { AxiosRequestHeaders } from "axios";
import { filterObject } from "../index";
import { filterXss, getBaseHeader, getNetworkError } from "./helper";
import { API_TIMEOUT, UNAUTHORIZED_ERROR } from "constants/api";
import { ApiResponse, ErrorResponseProps, FcResponseProps } from "types/api";

export var nodeRequest: { token?: string } = Object.create(null);

const request = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CLIENT_API_URL,
  withCredentials: true,
  timeout: process.env.NEXT_PUBLIC_API_TIMEOUT || API_TIMEOUT,
});

request.interceptors.request.use((config) => {
  config.headers = { ...config.headers, ...getBaseHeader() };
  // 过滤XSS攻击
  if (config.headers["Content-Type"] !== "multipart/form-data") config.params = filterXss(config.params);

  return config;
});

/**
 * 添加响应拦截器
 * 1、处理登录
 */
request.interceptors.response.use(
  (response) => {
    // console.log('response=', response)
    return response;
  },
  (error) => {
    const { errMsg, errStatus } = getNetworkError(error.response.status);

    // 登录无效操作
    if (error.response.status === 401 || UNAUTHORIZED_ERROR.includes(error.response.data.error)) {
    }

    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if ([403, 405, 500].includes(error.response.status)) {
      // location.reload()
    }

    const err: ErrorResponseProps<any> = {
      errno: error.response.status,
      errMsg,
      errStatus,
      data: error.response.data,
    };

    error.errno = err.errno;
    error.errMsg = err.errMsg;
    error.errStatus = err.errStatus;
    error.data = err.data;
    return Promise.reject(error);
  }
);

// region 调用方法
type Fn = (data: FcResponseProps<any>) => unknown;

interface GetOptionProps {
  /**
   * 清洗函数
   */
  clearFn?: Fn;
  /**
   * 自定义请求头
   */
  headers?: AxiosRequestHeaders;
  baseURL?: string;
}

/**
 * Get 方法
 * @example
 * const [e, r] = await api.getUserInfo(userid)
 * if (!e && r) this.userInfo = r.data.userinfo
 * @param {string} url
 * @param {Record<string, string|number>} params
 * @param options
 * @returns {Promise<[any, (ApiResponse | undefined)]>}
 * @constructor
 */
export const ToGet = <T>(url: string, params: object = {}, options?: GetOptionProps): ApiResponse<T> =>
  new Promise((resolve) => {
    const { clearFn, headers } = options || {};
    request
      .get(url, { params: filterObject(params), headers })
      .then((result) => {
        let res: FcResponseProps<T>;
        if (clearFn !== undefined) res = clearFn(result.data) as unknown as FcResponseProps<T>;
        else res = result.data as FcResponseProps<T>;

        resolve([null, res as FcResponseProps<T>]);
      })
      .catch((err) => {
        resolve([err, undefined]);
      });
  });

/**
 * 没有包裹信息结构的Get方法
 * @param {string} url
 * @param {Record<string, string|number>} params
 * @param {GetOptionProps} options
 * @returns {Promise<T | undefined>}
 * @constructor
 */
export const Get = <T>(url: string, params: object = {}, options?: GetOptionProps): Promise<T | undefined> =>
  new Promise((resolve, reject) => {
    const { clearFn, headers, baseURL } = options || {};
    request
      .get(url, { params: filterObject(params || {}), headers, baseURL })
      .then((res) => {
        return res;
      })
      .then((result) => {
        let res: T;
        if (clearFn !== undefined) res = clearFn(result.data) as unknown as T;
        else res = result.data as T;

        resolve(res);
      })
      .catch((err) => {
        if ("errno" in err) {
          reject(err);
        } else {
          reject({
            errno: "503",
            data: err,
            ...getNetworkError(503),
          });
        }
      });
  });

interface PostOptionProps extends GetOptionProps {
  params?: object;
}

/**
 * Post 方法
 * @example
 * const [e, r] = await api.getUserInfo(userid)
 * if (!e && r) this.userInfo = r.data.userinfo
 * @param {string} url
 * @param {Record<string, string|number>} data
 * @param options
 * @returns {Promise<[any, (T | undefined)]>}
 * @constructor
 */
export const Post = <T>(url: string, data: object = {}, options?: PostOptionProps): Promise<T | undefined> => {
  return new Promise((resolve, reject) => {
    const { params = {}, headers, clearFn } = options || {};
    request
      .post(url, data, { params, headers })
      .then((result) => {
        let res: T;
        if (clearFn !== undefined) res = clearFn(result.data) as unknown as T;
        else res = result.data as T;

        resolve(res);
      })
      .catch((err) => {
        if ("errno" in err) {
          reject(err);
        } else {
          reject({
            errno: "503",
            data: err,
            ...getNetworkError(503),
          });
        }
      });
  });
};
// endregion

/**
 * @deprecated 不推荐直接调用，请使用Get、Post方法
 */
export default request;
