import qs from "qs";
import { forOwn, isObject, isString } from "lodash-es";
import { DialingCodeProps } from "../apis/user";

/**
 * 将字符串转换为对象
 * @param str
 * @param {{}} defaultValue
 * @returns {T}
 */
export function convertStrToJSON<T = object>(str?: unknown, defaultValue = {}): T {
  let val: T = defaultValue as T;

  if (typeof str === "string" && !!str) {
    try {
      val = JSON.parse(str);
    } catch (e) {
      console.error(e);
    }
  }
  return val;
}

/**
 * 解析字符串
 */
export function convertStr<T = object>(str?: unknown): T {
  let val: T = str as T;

  if (typeof str === "string" && !!str) {
    try {
      const _str = str.replace(/\n/g, "n_warp_n");
      val = JSON.parse(_str);
      return replaceInner(val) as T;
    } catch (e) {
      console.error(e);
    }
  }
  return str as T;
}

type Entry<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T];

/**
 * 将自身有效的属性过滤
 * @deprecated 建议使用filterObject
 * @param {T} obj
 * @param {(entry: Entry<T>, i: number, arr: Entry<T>[]) => boolean} fn
 * @returns {Partial<T>}
 */
export function filterObjectOwnProps<T extends object>(
  obj: T,
  fn = (entry: Entry<T>, i: number, arr: Entry<T>[]) => {
    return entry[i] === null || entry[i] === undefined;
  }
) {
  return Object.fromEntries((Object.entries(obj) as Entry<T>[]).filter(fn)) as Partial<T>;
}

/**
 * 过滤无效值
 * @default 默认过滤 null undefined
 * @param o
 * @param {(i) => boolean} filter
 * @returns {{}}
 */
export const filterObject = <T extends object>(o: T, filter = (value: unknown, key: string) => value !== null && value !== undefined) => {
  const r = {} as Partial<T>;
  Object.keys(o).forEach((k) => {
    if (o.hasOwnProperty(k)) {
      if (filter(o[k as keyof T], k)) r[k as keyof T] = o[k as keyof T];
    }
  });
  return r;
};

/**
 * 组合href，向href添加参数
 * @param {string} origin
 * @param {string} utm
 * @returns {`${string}?${string}` | `${string}&${string}`}
 */
export const combineHref = (origin: string, utm: string) => (!origin.includes("?") ? `${origin}?${utm}` : `${origin}&${utm}`);

export const formatUtm = ({ campaign, term, source, medium, content }: any) =>
  `utm_campaign=${campaign}&utm_content=${content}&utm_source=${source}&utm_medium=${medium}&utm_term=${term}`;

export interface UrlFormatOptionProps {
  search?: string | Record<string, string | number | null | undefined>;
}

/**
 *
 * @param pathname 要去的地址
 * @param options
 */
export const urlFormat = (pathname: string, options?: UrlFormatOptionProps) => {
  const { search } = options || {};

  const url = qs.stringify(
    {
      campaign: "foreign",
      ...(isString(search) && !!search ? qs.parse(search, { ignoreQueryPrefix: true }) : isObject(search) ? search : {}),
      site: window?.location?.href,
    },
    {}
  );
  return `${pathname}?${url}`;
};

/**
 * 解析数组和对象中的字符串 将 n_warp_n 替换为 \n
 */
const replaceInner = <T>(val: Array<T> | T) => {
  if (Array.isArray(val) && val.length > 0) {
    // 检查数组
    return val.map((i) => {
      if (typeof i === "string" && !!i) {
        return i.replace(/n_warp_n/g, "\n");
      }
      return i;
    });
  }

  if (isObject(val)) {
    let res: T = {} as T;
    forOwn(val, function (value, key) {
      // @ts-ignore 对象的key定义
      res[`${key}`] = typeof value === "string" && !!value ? value.replace(/n_warp_n/g, "\n") : value;
    });
    return res;
  }
};

/**
 * 根据站点替换图片的前缀
 * 仅限Boss静态资源上传
 */
export const formatImgSrcByStation = (url?: string, locates?: AllLocateProps) => {
  if (isString(url) && !!url) {
    if (locates?.isUK || locates?.isUS)
      return url.replace(process.env.NEXT_PUBLIC_OSS_CDN_DOMAIN, process.env.NEXT_PUBLIC_INTERNATIONAL_OSS_CDN_DOMAIN);
  }
  return url;
};

/**
 * 将区号数组转换为标准结构
 */
export const DialingCode2Label = (arr: DialingCodeProps[] | null | undefined) => {
  if (Array.isArray(arr)) {
    return arr.map(({ hkName, code, id }) =>
      // code会重复，需要使用key
      ({ label: `+${code}`, value: code, key: id })
    );
    // ({ label: `+${code} ${hkName}`, value: `+${code}` }))
  }
  return undefined;
};

/**
 * 将区号数组转换为标准结构
 */
export const DialingCode2LabelName = (arr: DialingCodeProps[] | null | undefined) => {
  if (Array.isArray(arr)) {
    return arr.map(({ hkName, code, enName, id }) =>
      // code会重复，需要使用key
      ({ region: enName, label: `+${code}`, value: code, key: id })
    );
  }
  return undefined;
};

/**
 * whatsapp 区号选项
 */
export const WhatsappDialingCodeLabel = (arr: DialingCodeProps[] | null | undefined, language = "en") => {
  if (Array.isArray(arr)) {
    return arr.map(({ hkName, enName, code, id }) =>
      // code会重复，需要使用key
      ({ label: `+${code} ${language === "en" ? enName : hkName}`, value: code, key: id })
    );
  }
  return undefined;
};

/**
 * location 国家选项
 */
export const LocationFilterLabel = (arr: DialingCodeProps[] | null | undefined, language = "en") => {
  if (Array.isArray(arr)) {
    return arr.filter(item =>item.code !== '86' && item.code !== '852').map(({ hkName, enName, code, id }) =>
      // code会重复，需要使用key
      ({ label: `${language === "en" ? enName : hkName}`, value: code, key: id })
    );
  }
  return undefined;
};

/**
 * 移除html
 */
export function clearHTML(value: string) {
  if (!value) return ''
  return value
    .replace(/<\/?.+?\/?>/gi, '') // 去除html 符号
    .replace(/&nbsp;/g, ' ') // 空格替换
    .replace(/&ldquo;/g, '“') // 引号替换
    .replace(/&rdquo;/g, '”') // 引号替换
    .replace(/&hellip;/g, '...') // 省略号替换
    .replace(/&mdash;/g, '——') // 长破折号替换
    .replace(/&lt;/g, '<') // 小于替换
    .replace(/&gt;/g, '>') // 大于替换
    .replace(/&amp;/g, '&') // &替换
}

/**
 * 返回长度大于0的数据
 */
export function getValidArray<T>(data: unknown, defaultValue: T[] = []) {
  if (Array.isArray(data) && data.length) {
    return data as T[]
  }
  return defaultValue
}
