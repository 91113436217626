import { getIsProdOfBrowser } from 'utils/platform'
import { Post } from "../utils/api";
import { MediaType } from "../constants/area";

export interface contactType {
  type: MediaType;
  number: string;
  subNumber?: string;
}

interface ContactProps {
  contactName: string;
  mobileAreaCode: string;
  officePhone: string;
  email: string;
  companyName: string;
  site?: string;
  utmSource?: string;
  contactTypeList?: contactType[];
  extraInfo?: {
    utm_medium?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_term?: string;
    businessSource?: string;
  };
}

/**
 * 提交信息
 */
export function contactSubmit(params: ContactProps, url = process.env.NEXT_PUBLIC_API_PREFIX) {
  const isProd = getIsProdOfBrowser()
  if (!isProd) {
    url = `${window.location.origin}/api/v1`
  }
  return Post<void>(`${url || ""}/promotion/internation/contact/submit`, params);
}
