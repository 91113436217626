import React, { useCallback, useMemo } from "react";
import { setCookie } from "cookies-next";
import { useRouter } from "next/router";
import { COOKIE_LANG } from "constants/cookies";
import { LanguageEnum } from "constants/enum";
import { enPng, hkPng, ukPng, zhPng, global_png, cn_png, hk_png, jp_png, us_png, uk_png } from "constants/images";
import { getIsLanding } from "../utils";
import { INTERNATIONAL_LANDING_INDEX } from "constants/router";
import { useCommonState } from "./common";
import { getCusLang, handleLangChange } from "utils/language";
import { LangItemType, LanguageMenuType } from "types/language.d";

// landing页面
export const getLandingLanguageSiteAndLangMap = (hosts: AllHostsProps): LanguageMenuType => {
  const { hk, uk, us } = hosts;
  return {
    hk: {
      key: "hk-default",
      label: "中国香港",
      icon: <img src={hkPng} alt={"中国香港"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      children: [
        { key: "hk-tw", label: "繁體", host: hk, href: `https://${hk}/zh-HK/landing`, locale: LanguageEnum["zh-HK"], parent: "hk-default" },
        { key: "hk-en", label: "English", host: hk, href: `https://${hk}/landing`, locale: LanguageEnum["en"], parent: "hk-default" },
      ],
    },
    us: {
      key: "us-en",
      icon: <img src={enPng} alt={"United States"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      label: "United States",
      host: us,
      href: `https://${us}/landing`,
      locale: LanguageEnum["en"],
    },
    // 'uk': {
    //   key: 'uk-en', icon: <img src={ukPng} alt={'United Kingdom'} />, label: 'United Kingdom',
    //   host: uk, href: `https://${uk}/en/landing`, locale: LanguageEnum['en'],
    // },
  };
};

/**
 * 依赖host和pathname判断国际化官网的语言切换选项
 * @param hosts
 * @param options
 */
export const getLanguageSiteAndLangMap = (hosts: AllHostsProps, options?: Omit<AllLocateProps, "currentSite">): LanguageMenuType => {
  if (options?.isLanding) return getLandingLanguageSiteAndLangMap(hosts);

  const { official, hk, us, uk } = hosts;

  return {
    cn: {
      key: "cn-default",
      label: "中国",
      icon: <img src={zhPng} alt={"中国"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      children: [
        { key: "cn-zh", label: "简体", host: official, href: `https://${official}?lang=zh`, locale: LanguageEnum["zh"], parent: "cn-default" },
        { key: "cn-ja", label: "日本语", host: official, href: `https://${official}?lang=ja`, locale: LanguageEnum["ja"], parent: "cn-default" },
        { key: "cn-en", label: "English", host: official, href: `https://${official}?lang=en`, locale: LanguageEnum["en"], parent: "cn-default" },
      ],
    },
    hk: {
      key: "hk-default",
      label: "中國香港",
      icon: <img src={hkPng} alt={"中國香港"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      children: [
        { key: "hk-tw", label: "繁體", host: hk, href: `https://${hk}/zh-HK`, locale: LanguageEnum["zh-HK"], parent: "hk-default" },
        { key: "hk-en", label: "English", host: hk, href: `https://${hk}`, locale: LanguageEnum["en"], parent: "hk-default" },
      ],
    },
    us: {
      key: "us-en",
      label: "United States",
      icon: <img src={enPng} alt={"United States"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      host: us,
      href: `https://${us}`,
      locale: LanguageEnum["en"],
    },
    // 只有英国站才会有
    uk: {
      key: "uk-en",
      label: "United Kingdom",
      icon: <img src={ukPng} alt={"United Kingdom"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      host: uk,
      href: `https://${uk}`,
      locale: LanguageEnum["en"],
    },
  };
};

export const getLanguageSiteAndLangMapNew = (hosts: AllHostsProps): LanguageMenuType => {
  const { official, hk, us, uk } = hosts;

  return {
    global: {
      key: "hk-en",
      label: "Global  (English)",
      icon: <img src={global_png} alt={"global english"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      host: hk,
      href: `https://${hk}`,
      locale: LanguageEnum["en"],
    },
    cn: {
      key: "cn-default",
      label: "中国",
      icon: <img src={cn_png} alt={"中国"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      host: official,
      href: `https://${official}?lang=zh`,
      locale: LanguageEnum["zh"],
    },
    hk: {
      key: "hk-default",
      label: "中國香港",
      icon: <img src={hk_png} alt={"中國香港"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      host: hk,
      href: `https://${hk}/zh-HK`,
      locale: LanguageEnum["zh-HK"],
    },
    jp: {
      key: "cn-ja",
      label: "日本",
      icon: <img src={jp_png} alt={"中國香港"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      host: official,
      href: `https://${official}?lang=ja`,
      locale: LanguageEnum["ja"],
    },
    us: {
      key: "us-en",
      label: "United States",
      icon: <img src={us_png} alt={"United States"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      host: us,
      href: `https://${us}`,
      locale: LanguageEnum["en"],
    },
    uk: {
      key: "uk-en",
      label: "United Kingdom",
      icon: <img src={uk_png} alt={"United Kingdom"} style={{ width: "var(--rem-24)", height: "var(--rem-24)" }} />,
      host: uk,
      href: `https://${uk}`,
      locale: LanguageEnum["en"],
    },
  };
};

interface LanguageStateType {
  language?: LanguageEnum;
}

interface LanguageDispatchType {
  setLanguage: (language: LanguageEnum) => void;
}

const INIT_ERROR_MESSAGE = "未成功初始化,请检查LanguageProvider";

const DEFAULT_STATE: LanguageStateType = { language: LanguageEnum.zh };
const DEFAULT_ACTIONS = {
  setLanguage: () => {
    console.error(INIT_ERROR_MESSAGE);
  },
};

/**
 * @deprecated 推荐useCommonState
 * @type {React.Context<CommonStateType | undefined>}
 */
const LanguageStateContext = React.createContext<LanguageStateType>(DEFAULT_STATE);

const LanguageDispatchContext = React.createContext<LanguageDispatchType>(DEFAULT_ACTIONS);

export function useLanguageState() {
  const context = React.useContext(LanguageStateContext);
  if (context === undefined) {
    console.error("useLanguageState must be used within a LanguageContextProvider");
    return DEFAULT_STATE;
  }
  return context;
}

export function useLanguageDispatch() {
  const context = React.useContext(LanguageDispatchContext);
  if (context === undefined) return DEFAULT_ACTIONS;

  return context;
}

export const useChangeLanguage = () => {
  const { locale } = useRouter();
  const { siteMap, host } = useCommonState();
  const { setLanguage } = useLanguageDispatch();

  const curSiteAndLang = useMemo(() => getCusLang(siteMap, host, locale), [host, locale, siteMap]);

  const handleChange = (key: string, item: LangItemType) => {
    const lang = handleLangChange(key, item);
    if (lang) setLanguage(lang);
  };

  return [curSiteAndLang, handleChange] as const;
};

const LanguageContextProvider = ({ children }: ProviderProps) => {
  const router = useRouter();
  const { asPath, query: next_query, isReady } = router;

  // console.info('****************** LanguageContextProvider *******************', next_query, isReady)
  const setLanguage = useCallback(
    (currentLanguage: LanguageEnum) => {
      // 前往landing首页
      let pathname = getIsLanding(asPath) ? INTERNATIONAL_LANDING_INDEX : "/";

      // 此处依旧跳转首页，并且携带参数
      // TODO 首次用href打开页面，从next中获取的query是空，参考https://dpnkr.in/blog/nextjs-router-query-undefined
      router.push({ pathname, search: window.location.search }, undefined, { locale: currentLanguage }).then();

      setCookie(COOKIE_LANG, currentLanguage, { path: "/" }); // 修改xt_lang
      // TODO 用于覆盖accept-language header指定的语言行为，但是会记住当前语言，下一次打开时，自动跳转到指定的语言
      setCookie("NEXT_LOCALE", currentLanguage, { path: "/" }); // 修改i18的默认行为
    },
    [asPath, router]
  );

  return <LanguageDispatchContext.Provider value={{ setLanguage }}>{children}</LanguageDispatchContext.Provider>;
};

export default LanguageContextProvider;
