import React, { useEffect, useState } from 'react'
import { ARMSEnv } from '@xt/arms-sdk/dist/types'
import { getIsProdOfBrowser } from '../utils';

interface ArmsMonitorProps {
  avg: (key: string, value: number | string) => void
}

interface CommonStateType { armsMonitor?: ArmsMonitorProps }

interface CommonDispatchType {
  /**
   * 记录TTI时间
   * @param {string} key
   * @param {number} time
   */
  avgTTI: (key: 'home' | 'home-banner' | 'detail', time: number) => void
}

const INIT_ERROR_MESSAGE = '未成功初始化,请检查CommonProvider'

const DEFAULT_STATE: CommonStateType = {}
const DEFAULT_ACTIONS = {
  avgTTI: () => { console.error(INIT_ERROR_MESSAGE) },
}

/**
 * @deprecated 推荐useCommonState
 * @type {React.Context<CommonStateType | undefined>}
 */
const ArmsStateContext = React.createContext<CommonStateType>(DEFAULT_STATE)

const ArmsDispatchContext = React.createContext<CommonDispatchType>(DEFAULT_ACTIONS)

export function useArmsState() {
  const context = React.useContext(ArmsStateContext)
  if (context === undefined)
    return {}

  return context
}

export function useArmsDispatch() {
  const context = React.useContext(ArmsDispatchContext)
  if (context === undefined)
    return DEFAULT_ACTIONS

  return context
}

/**
 * 一些全局共享的对象
 * 1、arms监控变量 - armsMonitor1001
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
const ArmsProvider = ({ children }: ProviderProps): JSX.Element => {
  const [armsMonitor, setArmsMonitor] = useState<ArmsMonitorProps>()

  useEffect(() => {
    if (getIsProdOfBrowser()) {
      import('@xt/arms-sdk').then((module) => {
        module.default({
          pid: "ix2simms9o@625b9ed5100498f",
          appType: "web",
          imgUrl: "https://arms-retcode-sg.aliyuncs.com/r.png?",
          release: "0.0.3",
          environment: (process?.env?.NEXT_PUBLIC_ARMS_ENV || "prod") as ARMSEnv,
        }).then((__bl) => {
          module.getPipe(true)
          module.reportLog(__bl)
          setArmsMonitor(__bl)
        })
      })
    }
  }, [])

  const handleLog = (key: string, time: number) => {
    const navigationStart = performance?.timing?.navigationStart
    const diff = (time - navigationStart) / 1000
    // console.info('arms', `AvgTTI-${key}`, {
    //   now: time,
    //   navigationStart,
    //   diff,
    // })
    if (armsMonitor) { // 对象存在直接上报
      armsMonitor.avg(`AvgTTI-${key}`, diff)
    }
    else { // 使用预上报方式
      window.avgTTI(key, time)
    }
  }

  const handleAvgTTI = (key: 'home' | 'home-banner' | 'detail', time: number) => {
    if (['home', 'detail'].includes(key)) {
      if (!(!!window.ARMS_FIRST_PATHNAME && window.ARMS_FIRST_PATHNAME === window.location.pathname)) return
      // 仅记录第一次
      window.ARMS_FIRST_PATHNAME = undefined
      handleLog(key, time)
    }
    else if (key === 'home-banner') {
      if (!(!!window.ARMS_FIRST_PATHNAME_BANNER && window.ARMS_FIRST_PATHNAME_BANNER === window.location.pathname)) return
      // 仅记录第一次
      window.ARMS_FIRST_PATHNAME_BANNER = undefined
      handleLog(key, time)
    }
  }

  return (
    <ArmsDispatchContext.Provider
      value={{
        ...DEFAULT_ACTIONS,
        ...{
          updateArmsMonitor: setArmsMonitor,
          avgTTI: handleAvgTTI,
        },
      }}>
      <ArmsStateContext.Provider value={{ armsMonitor }}>
        {children}
      </ArmsStateContext.Provider>
    </ArmsDispatchContext.Provider>)
}

export default ArmsProvider
