import "normalize.css/normalize.css";
import "xt-design/dist/index.css";
import "styles/index.scss";
import "styles/markdown.css";

import React, { Fragment, useEffect, useState } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import { ConfigProvider } from "xt-design";
import { appWithTranslation, UserConfig } from "next-i18next";
import Layout from "layout";
import StateProviders, { CommonProvider, useCommonState } from "context";
import { getIsMobile, setXtLng } from "utils";
import { NextPageWithLayout } from "types/reactExtra";
import nextI18nConfig from "next-i18next.config.js";
import { IndexSEO } from "components";
import { sensorsUtil } from "utils/track";
import { useRouter } from "next/router";
import { useSearchChange } from "../hooks";
import CustomerService from "components/CustomerService";
import { locale2LoginLngMap } from 'constants/regions';

type AppPropsWithLayout = AppProps<{ dehydratedState: unknown }> & PageProps & { Component: NextPageWithLayout };
interface IComponentCommonProps {
  isMobile?: boolean;
  isSupportWebp?: boolean;
  host?: string;
}

function MyApp({ Component, pageProps, isMobile, isSupportWebp, host }: AppPropsWithLayout) {
  const { locale } = useRouter();

  const componentCommonProps: IComponentCommonProps = { isMobile, isSupportWebp, host };

  useEffect(() => {
    setXtLng(locale2LoginLngMap[locale as string] || "zh-TW");
  }, [locale]);

  useEffect(() => {
    sensorsUtil();
  }, []);

  useSearchChange();

  // 支持react-query
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            cacheTime: 5 * 60,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <ConfigProvider>
          <CommonProvider {...componentCommonProps}>
            <StateProviders>
              <Inner Component={Component} componentCommonProps={componentCommonProps} pageProps={pageProps} />
            </StateProviders>
          </CommonProvider>
        </ConfigProvider>
      </Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

const Inner = ({
  Component,
  componentCommonProps,
  pageProps,
}: Pick<AppPropsWithLayout, "Component" | "pageProps"> & { componentCommonProps: IComponentCommonProps }) => {
  const { isGlobal, isMobile, isHK } = useCommonState();
  const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>);
  return (
    <Fragment>
      <IndexSEO />
      {getLayout(<Component {...componentCommonProps} {...pageProps} />)}
      {!isMobile && (isGlobal || isHK) && <CustomerService />}
    </Fragment>
  );
};

MyApp.getInitialProps = async (context: AppContext) => {
  const pageProps = await App.getInitialProps(context);

  return {
    ...pageProps,
    isMobile: getIsMobile(context),
    // TODO 页面如果使用SSG，host为空
    host: context?.ctx?.req?.headers?.host,
  };
};

export default appWithTranslation(MyApp, nextI18nConfig as UserConfig);
