import React, { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'
import Desc from './Desc'

interface TitleProps {
  style?: CSSProperties
  className?: string
  children?: ReactNode

  onlyOneRow?: boolean
}

const Title = (props: TitleProps) => {
  const { className, children, onlyOneRow, style = {},  ...rest } = props

  if (React.isValidElement(children)) return null

  if (Array.isArray(children) && onlyOneRow)
    return (
      <h2 style={style} className={classNames('xt-h2', 'xt-Title', styles.root, className)}
          {...rest} >{(children as string[]).join('')}
      </h2>
    )
  return <>{
    React.Children.map(children, (child, index) => {
      return (
        <h2
          className={classNames('xt-h2', 'xt-Title', styles.root, className)}
            {...rest} style={index !== 0 ? { ...style, marginTop: 0 } : style}>{child}</h2>)
      })
    }</>
}

export { Desc }
export default Title
