import React from "react";
import useShowAd from "./useShowAd";
import styles from "./index.module.scss";

interface AdBannerProps {}
const AdBannerProps: React.FC<AdBannerProps> = ({}) => {
  const { close, showAd, adContent } = useShowAd();

  if (!showAd || !adContent) {
    return null;
  }
  return (
    <div className={styles.bannerWrapper}>
      <img
        alt=""
        className={styles.banner}
        src={adContent.src}
        onClick={() => {
          location.href = adContent.href;
        }}
      />
      <img className={styles.close} src="https://static.xtransfer.com/boss/static/x-closesmall-1_a42bec03b90470de.svg" alt="close" onClick={close} />
    </div>
  );
};

export default AdBannerProps;
