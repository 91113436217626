import { useEffect } from "react";
import Router, { NextRouter } from "next/router";
import { MessageEnum } from "constants/enum";
import { sendMsgToRn } from "utils";

interface IMsgProps {
  router: NextRouter;
}

// rn和h5事件通信
const useMessage = ({ router }: IMsgProps) => {
  const { asPath, query } = router;

  const goBack = () => {
    const param = {
      type: MessageEnum.navBack,
    };
    sendMsgToRn(param);
    sendNotice();
  };

  const sendNotice = () => {
    const param = {
      type: MessageEnum.notice,
    };
    sendMsgToRn(param);
  };

  const handleBack = (event: MessageEvent) => {
    switch (event.data) {
      case MessageEnum.navBack:
        if (["/help-center-h5"].includes(asPath) || location?.search?.includes("from")) {
          goBack();
        } else {
          try {
            Router.back();
            sendNotice();
          } catch (e) {
            goBack();
          }
        }
        break;
      default:
        break;
    }
  };

  const onMessage = () => {
    window.addEventListener("message", handleBack, true);
  };

  const removeMessage = () => {
    window.removeEventListener("message", handleBack, true);
  };

  useEffect(() => {
    onMessage();
    return () => {
      removeMessage();
    };
  }, []);
};

export default useMessage;
