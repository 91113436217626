/**
 * @description 在lcp计算完成之后再回调方法
 * @params callback 回调函数
 */
export const onLCP = (callback: () => void) => {
  const fn = typeof callback === "function" ? callback : function () {};
  let hasInvoke = false;
  if (window?.PerformanceObserver?.supportedEntryTypes?.includes("largest-contentful-paint")) {
    new PerformanceObserver(() => {
      if (!hasInvoke) {
        //onLCP invoke
        hasInvoke = true;
        fn();
      }
    }).observe({ type: "largest-contentful-paint", buffered: true });
  } else {
    //onLCP unSupport
    fn();
  }
};
