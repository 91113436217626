import React from 'react'
import { LoginStateEnum, useAuthDispatch, useAuthState } from './appAuth'
import ThemeContextProvider from './theme'
import UserAgentProvider, { useUserAgentState } from './userAgent'
import ArmsProvider, { useArmsDispatch, useArmsState } from './arms'
import CommonProvider, { useCommonState, useCommonDispatch } from './common'
import LanguageProvider, {
  useChangeLanguage,
  useLanguageDispatch,
  useLanguageState,
  getLanguageSiteAndLangMap
} from './language'

type ProviderNodeType = typeof UserAgentProvider

export function composeProviders(...providers: ProviderNodeType[]) {
  return ({ children }: {children: JSX.Element}) =>
    providers.reduce(
      (prev: JSX.Element, Provider: ProviderNodeType) => <Provider>{prev}</Provider>,
      children,
    )
}

/**
 * 由下至上包裹
 */
const StateProviders = composeProviders(
  ThemeContextProvider,
  UserAgentProvider,
  ArmsProvider,
  LanguageProvider,
)

export {
  useAuthState,
  useAuthDispatch,
  LoginStateEnum,
  useUserAgentState,
  useArmsDispatch,
  useArmsState,
  LanguageProvider,
  useLanguageDispatch,
  useLanguageState,
  useChangeLanguage,
  getLanguageSiteAndLangMap,
  CommonProvider,
  useCommonDispatch,
  useCommonState
}

export default StateProviders
