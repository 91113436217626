import { useRouter } from "next/router";
import { goLogin } from "utils/jump";
import { INTERNATIONAL_SUPPORT_CONTACT_US, REGISTER } from "constants/router";
import { commonADTrack } from "utils/traceUtil";

const useGoLogin = () => {
  const router = useRouter();
  const { locale } = router;

  const handleGoLogin = () => {
    goLogin(locale || "");
  };

  return handleGoLogin;
};

export const goRegister = () => {
  let search = window.location.search;
  const regex = /&?lng=[^&]*(&|$)/;

  // tiktok 数据回传
  window?.ttq?.track?.("CreateClick", { value: "0.5", currency: "USD" });
  commonADTrack({ name: "Create_Account" });
  location.href = `${REGISTER}${search?.replace(regex, "$1")}`;
};

export const goContact = () => {
  let search = window.location.search;
  commonADTrack({ name: "Contact_Us" });
  location.href = `${INTERNATIONAL_SUPPORT_CONTACT_US}${search}`;
};

export default useGoLogin;
