import React, { CSSProperties } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames';

export interface RowLeftProps {
  title: string
  subTitle?: string | null
  style?: CSSProperties
}

const RowLeft = (props: RowLeftProps) => {
  const { title, subTitle, style } = props

  return <div className={styles.left} style={style}>
    <div>
      <h3 className={classNames('xt-h3', styles.title)}>{title}</h3>
      <p className={styles.subTitle}>{subTitle}</p>
    </div>
  </div>
}

export default RowLeft
